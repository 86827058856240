import {
  USER_PROFILE_FETCH_REQUESTED,
  USER_SUBSCRITION_START_UPDATING,
  USER_SUBSCRITION_UPDATE_COMPLETE,
  USER_SUBSCRITION_UPDATE,
  USER_PROFILE_UPDATE,
  USER_PROFILE_START_UPDATING,
  USER_PROFILE_UPDATE_COMPLETE,
  START_EDITING_PROFILE,
  COMPLETE_EDITING_PROFILE,
} from './actionTypes';

export const fetchUserProfile = () => ({
  type: USER_PROFILE_FETCH_REQUESTED,
});

export const startUpdatingMember = (id) => ({
  type: USER_SUBSCRITION_START_UPDATING,
  id,
});

export const completeUpdatingMember = (id, model) => ({
  type: USER_SUBSCRITION_UPDATE_COMPLETE,
  payload: { id, model },
});

export const updateUserSubscriptions = (id, model) => ({
  type: USER_SUBSCRITION_UPDATE,
  id,
  model,
});

export const startUpdatingUserProfile = (id) => ({
  type: USER_PROFILE_START_UPDATING,
  id,
});

export const completeUpdatingUserProfile = (id, model) => ({
  type: USER_PROFILE_UPDATE_COMPLETE,
  payload: { id, model },
});

export const updateUserProfile = (id, model) => ({
  type: USER_PROFILE_UPDATE,
  id,
  model,
});

export const startEditingProfile = (id) => ({
  type: START_EDITING_PROFILE,
  payload: { id },
});

export const completeEditingProfile = (id) => ({
  type: COMPLETE_EDITING_PROFILE,
  payload: { id },
});
