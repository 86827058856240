import { withVariants, PicturePanel } from '@hvccc/component-library';
import FeaturePicturePanel from './FeaturePicturePanel';
import StandardContentPicturePanel from './StandardContentPicturePanel';

const styles = {
  feature: FeaturePicturePanel,
  standard: StandardContentPicturePanel,
};

export default withVariants(styles)(PicturePanel);
