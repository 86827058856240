import React from 'react';

const fillColor = 'currentColor';

export function HomeIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 32 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.2578 13.0781C31.8047 13.5703 31.8594 14.3906 31.4219 14.9375C30.9297 15.4844 30.1094 15.5391 29.5625 15.1016L28.25 13.9531V24.125C28.25 26.5859 26.2812 28.5 23.875 28.5H8.125C5.66406 28.5 3.75 26.5859 3.75 24.125V13.9531L2.38281 15.1016C1.83594 15.5391 1.01562 15.4844 0.523438 14.9375C0.0859375 14.3906 0.140625 13.5703 0.6875 13.0781L15.125 0.828125C15.6172 0.445312 16.3281 0.445312 16.8203 0.828125L31.2578 13.0781ZM8.125 25.875H10.75V17.5625C10.75 16.3594 11.6797 15.375 12.9375 15.375H19.0625C20.2656 15.375 21.25 16.3594 21.25 17.5625V25.875H23.875C24.8047 25.875 25.625 25.1094 25.625 24.125V11.7109L16 3.5625L6.375 11.7109V24.125C6.375 25.1094 7.14062 25.875 8.125 25.875ZM13.375 25.875H18.625V18H13.375V25.875Z"
        fill={fillColor}
      />
    </svg>
  );
}

export function ReportIcon() {
  return (
    <svg
      width="22"
      height="28"
      viewBox="0 0 22 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.4609 5.64062L16.3594 1.53906C15.7031 0.882812 14.8281 0.5 13.8984 0.5H4C2.03125 0.5 0.5 2.08594 0.5 4V25C0.5 26.9688 2.03125 28.5 4 28.5H18C19.9141 28.5 21.5 26.9688 21.5 25V8.10156C21.5 7.17188 21.1172 6.29688 20.4609 5.64062ZM18.875 25C18.875 25.4922 18.4375 25.875 18 25.875H4C3.50781 25.875 3.125 25.4922 3.125 25V4.05469C3.125 3.5625 3.50781 3.17969 4 3.17969H12.75V7.5C12.75 8.48438 13.5156 9.25 14.5 9.25H18.8203V25H18.875ZM5.75 15.8125C5.75 16.5781 6.29688 17.125 7.0625 17.125H14.9375C15.6484 17.125 16.25 16.5781 16.25 15.8125C16.25 15.1016 15.6484 14.5 14.9375 14.5H7.0625C6.29688 14.5 5.75 15.1016 5.75 15.8125ZM14.9375 19.75H7.0625C6.29688 19.75 5.75 20.3516 5.75 21.0625C5.75 21.8281 6.29688 22.375 7.0625 22.375H14.9375C15.6484 22.375 16.25 21.8281 16.25 21.0625C16.25 20.3516 15.6484 19.75 14.9375 19.75Z"
        fill={fillColor}
      />
    </svg>
  );
}

export function EyeIcon() {
  return (
    <svg
      width="28"
      height="25"
      viewBox="0 0 32 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 12.5C9 8.67188 12.1172 5.5 16 5.5C19.8281 5.5 23 8.67188 23 12.5C23 16.3828 19.8281 19.5 16 19.5C12.1172 19.5 9 16.3828 9 12.5ZM16 16.875C18.4062 16.875 20.375 14.9609 20.375 12.5C20.375 10.0938 18.4062 8.125 16 8.125C15.9453 8.125 15.8906 8.125 15.8359 8.125C15.9453 8.45312 16 8.72656 16 9C16 10.9688 14.4141 12.5 12.5 12.5C12.1719 12.5 11.8984 12.5 11.625 12.3906C11.625 12.4453 11.625 12.5 11.625 12.5C11.625 14.9609 13.5391 16.875 16 16.875ZM5.44531 4.67969C8.01562 2.27344 11.5703 0.25 16 0.25C20.375 0.25 23.9297 2.27344 26.5 4.67969C29.0703 7.03125 30.7656 9.875 31.5859 11.8438C31.75 12.2812 31.75 12.7734 31.5859 13.2109C30.7656 15.125 29.0703 17.9688 26.5 20.375C23.9297 22.7812 20.375 24.75 16 24.75C11.5703 24.75 8.01562 22.7812 5.44531 20.375C2.875 17.9688 1.17969 15.125 0.359375 13.2109C0.195312 12.7734 0.195312 12.2812 0.359375 11.8438C1.17969 9.875 2.875 7.03125 5.44531 4.67969ZM16 2.875C12.3906 2.875 9.49219 4.51562 7.25 6.59375C5.11719 8.5625 3.69531 10.8594 2.92969 12.5C3.69531 14.1406 5.11719 16.4922 7.25 18.4609C9.49219 20.5391 12.3906 22.125 16 22.125C19.5547 22.125 22.4531 20.5391 24.6953 18.4609C26.8281 16.4922 28.25 14.1406 29.0156 12.5C28.25 10.8594 26.8281 8.5625 24.6953 6.59375C22.4531 4.51562 19.5547 2.875 16 2.875Z"
        fill={fillColor}
      />
    </svg>
  );
}

export function CTSIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.1562 14.4141C23.5 14.1406 22.7344 14.3047 22.2422 14.7969L19.6719 17.3672L14.8047 12.5L19.6719 7.6875L22.2422 10.2578C22.5703 10.5859 23.0078 10.75 23.4453 10.75C23.7188 10.75 23.9375 10.75 24.1562 10.6406C24.8125 10.3672 25.1953 9.71094 25.1953 9V1.5625C25.1953 0.851562 24.6484 0.304688 23.9375 0.304688H16.5547C15.8438 0.304688 15.2422 0.742188 14.9688 1.39844C14.6953 2.05469 14.8047 2.82031 15.3516 3.3125L17.9219 5.88281L13 10.6953L8.13281 5.82812L10.7031 3.25781C11.0312 2.92969 11.1953 2.49219 11.1953 2.05469C11.1953 1.78125 11.1953 1.5625 11.0859 1.34375C10.8125 0.6875 10.1562 0.304688 9.5 0.304688H2.0625C1.29688 0.304688 0.75 0.851562 0.75 1.5625V8.94531C0.75 9.65625 1.1875 10.2578 1.84375 10.5312C2.5 10.8047 3.26562 10.6953 3.75781 10.1484L6.32812 7.57812L11.1406 12.5L6.27344 17.3672L3.70312 14.7969C3.375 14.4688 2.9375 14.3047 2.5 14.3047C2.22656 14.3047 2.00781 14.3047 1.78906 14.4141C1.13281 14.6875 0.75 15.3438 0.75 16.0547V23.4375C0.75 24.1484 1.29688 24.6953 2.00781 24.6953H9.39062C10.1016 24.6953 10.7031 24.2578 10.9766 23.6016C11.25 22.9453 11.0859 22.1797 10.5938 21.6875L8.02344 19.1172L12.8906 14.3047L17.7031 19.1172L15.1328 21.6875C14.8047 22.0156 14.6406 22.4531 14.6406 22.9453C14.6406 23.1641 14.6953 23.3828 14.75 23.6016C15.0234 24.2578 15.6797 24.6953 16.3906 24.6953H23.7734C24.4844 24.6953 25.0312 24.0938 25.0312 23.4375V16C25.1953 15.3438 24.8125 14.6875 24.1562 14.4141Z"
        fill={fillColor}
      />
    </svg>
  );
}

export function UserIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 26 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.625 17.125H10.375C5.01562 17.125 0.75 21.4453 0.75 26.75C0.75 27.7344 1.51562 28.5 2.5 28.5H23.5C24.4297 28.5 25.25 27.7344 25.25 26.75C25.25 21.4453 20.9297 17.125 15.625 17.125ZM3.375 25.875C3.8125 22.4297 6.76562 19.75 10.375 19.75H15.625C19.1797 19.75 22.1328 22.4297 22.5703 25.875H3.375ZM13 14.5C16.8281 14.5 20 11.3828 20 7.5C20 3.67188 16.8281 0.5 13 0.5C9.11719 0.5 6 3.67188 6 7.5C6 11.3828 9.11719 14.5 13 14.5ZM13 3.125C15.4062 3.125 17.375 5.09375 17.375 7.5C17.375 9.96094 15.4062 11.875 13 11.875C10.5391 11.875 8.625 9.96094 8.625 7.5C8.625 5.09375 10.5391 3.125 13 3.125Z"
        fill={fillColor}
      />
    </svg>
  );
}

export function AddressIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 27 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.875 16.25H10.375C7.91406 16.25 6 18.2188 6 20.625C6 21.1172 6.38281 21.5 6.875 21.5H17.375C17.8125 21.5 18.25 21.1172 18.25 20.625C18.25 18.2188 16.2812 16.25 13.875 16.25ZM12.125 14.5C14.0391 14.5 15.625 12.9688 15.625 11C15.625 9.08594 14.0391 7.5 12.125 7.5C10.1562 7.5 8.625 9.08594 8.625 11C8.625 12.9688 10.1562 14.5 12.125 14.5ZM26.125 18H25.25V23.25H26.125C26.5625 23.25 27 22.8672 27 22.375V18.875C27 18.4375 26.5625 18 26.125 18ZM26.125 4H25.25V9.25H26.125C26.5625 9.25 27 8.86719 27 8.375V4.875C27 4.4375 26.5625 4 26.125 4ZM26.125 11H25.25V16.25H26.125C26.5625 16.25 27 15.8672 27 15.375V11.875C27 11.4375 26.5625 11 26.125 11ZM20 0.5H4.25C2.28125 0.5 0.75 2.08594 0.75 4V25C0.75 26.9688 2.28125 28.5 4.25 28.5H20C21.9141 28.5 23.5 26.9688 23.5 25V4C23.5 2.08594 21.9141 0.5 20 0.5ZM20.875 25C20.875 25.4922 20.4375 25.875 20 25.875H4.25C3.75781 25.875 3.375 25.4922 3.375 25V4C3.375 3.5625 3.75781 3.125 4.25 3.125H20C20.4375 3.125 20.875 3.5625 20.875 4V25Z"
        fill={fillColor}
      />
    </svg>
  );
}

export function PlanetIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4578 0.75C17.5164 0.75 22.4578 5.69141 22.4578 11.75C22.4578 17.8516 17.5164 22.75 11.4578 22.75C5.35627 22.75 0.457831 17.8516 0.457831 11.75C0.457831 5.69141 5.35627 0.75 11.4578 0.75ZM11.4578 20.6875C11.7586 20.6875 12.575 20.3867 13.4774 18.625C13.8641 17.8516 14.1649 16.9492 14.4227 15.875H8.45002C8.70783 16.9492 9.00861 17.8516 9.39533 18.625C10.2977 20.3867 11.1141 20.6875 11.4578 20.6875ZM8.10627 13.8125H14.7664C14.8524 13.168 14.8953 12.4805 14.8953 11.75C14.8953 11.0625 14.8524 10.375 14.7664 9.6875H8.10627C8.02033 10.375 8.02033 11.0625 8.02033 11.75C8.02033 12.4805 8.02033 13.168 8.10627 13.8125ZM14.4227 7.625C14.1649 6.59375 13.8641 5.69141 13.4774 4.91797C12.575 3.15625 11.7586 2.8125 11.4578 2.8125C11.1141 2.8125 10.2977 3.15625 9.39533 4.91797C9.00861 5.69141 8.70783 6.59375 8.45002 7.625H14.4227ZM16.8289 9.6875C16.9149 10.375 16.9578 11.0625 16.9578 11.75C16.9578 12.4805 16.9149 13.168 16.8289 13.8125H20.1375C20.3094 13.168 20.3953 12.4805 20.3953 11.75C20.3953 11.0625 20.3094 10.375 20.1375 9.6875H16.8289ZM15.1531 3.62891C15.7547 4.74609 16.2274 6.12109 16.5281 7.625H19.3641C18.4617 5.86328 16.9578 4.44531 15.1531 3.62891ZM7.71955 3.62891C5.91486 4.44531 4.41096 5.86328 3.50861 7.625H6.34455C6.64533 6.12109 7.11799 4.74609 7.71955 3.62891ZM2.52033 11.75C2.52033 12.4805 2.5633 13.168 2.73518 13.8125H6.04377C5.95783 13.168 5.95783 12.4805 5.95783 11.75C5.95783 11.0625 5.95783 10.375 6.04377 9.6875H2.73518C2.5633 10.375 2.52033 11.0625 2.52033 11.75ZM19.3641 15.875H16.5281C16.2274 17.4219 15.7547 18.7969 15.1531 19.9141C16.9578 19.0977 18.4617 17.6797 19.3641 15.875ZM6.34455 15.875H3.50861C4.41096 17.6797 5.91486 19.0977 7.71955 19.9141C7.11799 18.7969 6.64533 17.4219 6.34455 15.875Z"
        fill={fillColor}
      />
    </svg>
  );
}

export function MembersIcon() {
  return (
    <svg
      width="28"
      height="23"
      viewBox="0 0 29 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5649 14.5C17.0571 14.5 19.0336 12.5234 19.0336 10.0312C19.0336 7.58203 17.0141 5.5625 14.5649 5.5625C12.1157 5.5625 10.1391 7.58203 10.1391 10.0312C10.0961 12.5234 12.1157 14.5 14.5649 14.5ZM14.5649 7.625C15.8969 7.625 16.9711 8.74219 16.9711 10.0312C16.9711 11.3633 15.8969 12.4375 14.5649 12.4375C13.2758 12.4375 12.1586 11.3633 12.1586 10.0312C12.1586 8.74219 13.2758 7.625 14.5649 7.625ZM22.8578 7.625C24.7485 7.625 26.2953 6.12109 26.2953 4.1875C26.2953 2.29688 24.7485 0.75 22.8578 0.75C20.9242 0.75 19.4203 2.29688 19.4203 4.1875C19.4203 6.12109 20.9242 7.625 22.8578 7.625ZM16.7133 15.875H12.4594C9.06487 15.875 6.35784 18.4531 6.35784 21.6328C6.35784 22.2773 6.87347 22.75 7.56097 22.75H21.6117C22.2992 22.75 22.8578 22.2773 22.8578 21.6328C22.8578 18.4531 20.1078 15.875 16.7133 15.875ZM8.50628 20.6875C8.93597 19.1406 10.5258 17.9375 12.4164 17.9375H16.7133C18.6039 17.9375 20.1938 19.1406 20.6664 20.6875H8.50628ZM24.5336 9H21.9125C21.3539 9 20.8383 9.17188 20.3657 9.38672C20.3657 9.60156 20.4086 9.81641 20.4086 10.0312C20.4086 11.4922 19.893 12.8242 18.9907 13.8125H27.5844C28.0141 13.8125 28.3578 13.4688 28.3578 13.0391C28.3578 10.8047 26.6391 9 24.5336 9ZM8.72112 10.0312C8.72112 9.81641 8.76409 9.60156 8.80706 9.38672C8.3344 9.12891 7.81878 9 7.26019 9H4.63909C2.53362 9 0.857842 10.8047 0.857842 13.0391C0.857842 13.4688 1.15862 13.8125 1.58831 13.8125H10.1821C9.27972 12.8242 8.72112 11.4922 8.72112 10.0312ZM6.35784 7.625C8.24847 7.625 9.79534 6.12109 9.79534 4.1875C9.79534 2.29688 8.24847 0.75 6.35784 0.75C4.42425 0.75 2.92034 2.29688 2.92034 4.1875C2.92034 6.12109 4.42425 7.625 6.35784 7.625Z"
        fill={fillColor}
      />
    </svg>
  );
}
