import './MemberModal.scss';
import { Modal } from 'antd';
import { FaTimes } from 'react-icons/fa';
import SuccessMessage from '../Messages/SuccessMessage';
import ErrorMessage from '../Messages/ErrorMessage';
import MemberForm from '../MemberForm';

function MemberModal(props) {
  function closeModalAndReload() {
    props.closeMemberModal();
    props.fetchAllMembers();
  }

  function getContent() {
    if (props.requestCompleted) {
      return props.success ? (
        <SuccessMessage closeModal={closeModalAndReload}>
          A new member was successfully created.
          <br />
          <br />
          Your new member will receive an email with access credentials for this
          portal.
        </SuccessMessage>
      ) : (
        <ErrorMessage closeModal={props.closeMemberModal}>
          We can’t create a new member at this time.
          <br />
          Refresh your browser window and try again.
          <br />
          <br />
          Error message: {props.message}
        </ErrorMessage>
      );
    } else {
      return (
        <MemberForm
          authoriser={props.authoriser}
          managedOrganisationsDomains={props.managedOrganisationsDomains}
        />
      );
    }
  }

  const closable = !props.requestCompleted;

  const defaultTitle = 'CREATE NEW MEMBER USER';
  const title = props.requestCompleted ? null : defaultTitle;

  return (
    <Modal
      title={title}
      open={props.isOpenModal}
      onCancel={props.closeMemberModal}
      destroyOnClose={true}
      closeIcon={<FaTimes />}
      wrapClassName="create-member-modal"
      width="400px"
      footer={null}
      closable={closable}
    >
      <div className="member-form-panel">{getContent()}</div>
    </Modal>
  );
}

export default MemberModal;
