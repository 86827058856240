import {
  NOTIFICATIONS_FETCH_REQUESTED,
  NOTIFICATION_MARK_AS_READ,
  NOTIFICATIONS_FETCH_BY_PAGE_REQUESTED,
} from './actionTypes';

export const fetchNotifications = () => ({
  type: NOTIFICATIONS_FETCH_REQUESTED,
});

export const setNotificationReadState = (notification) => ({
  type: NOTIFICATION_MARK_AS_READ,
  payload: notification,
});

export const fetchNotificationsByPage = (page) => ({
  type: NOTIFICATIONS_FETCH_BY_PAGE_REQUESTED,
  payload: page,
});
