import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { insightUrl, ctsUrl } from '../../../clientConfig';
import MenuItem from './MenuItem';
import history from '../../../utils/history';
import { sideBar as definition } from '../MenuItemDefinition';

import {
  AddressIcon,
  CTSIcon,
  EyeIcon,
  HomeIcon,
  ReportIcon,
} from '../../icons/menu';

const SidebarWrapper = styled.div`
  position: relative;
  z-index: 10;
  display: none;

  ${breakpoint('xl')`
    display: block;
  `};
`;

const MenuWrapper = styled.nav`
  display: flex;
  flex-direction: column;
  width: 99px;
  height: 100%;
  min-height: 400px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 5px 50px 0 rgba(0, 0, 0, 0.12);
`;

const LogoLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  padding: 16px;
`;

class SideBar extends Component {
  constructor(props) {
    super(props);

    const pathName = history.location.pathname;
    this.props.setCurrentPathName(pathName);

    this.state = {
      hoveredMenuItem: null,
    };
  }

  setHoveredMenuItem = (link) => {
    this.setState({ hoveredMenuItem: link });
  };

  unsetHoveredMenuItem = () => {
    this.setState({ hoveredMenuItem: null });
  };

  showMenu = (linkName) => {
    this.setState({ hoveredMenuItem: linkName });
  };

  hideSubMenu = () => {
    this.setState({ hoveredMenuItem: null });
  };

  render() {
    const { className, currentPathName } = this.props;

    return (
      <SidebarWrapper className={className}>
        <MenuWrapper>
          <LogoLink href="/">
            <img src="/logo-hvccc.svg" alt="HVCCC" />
          </LogoLink>
          <MenuItem
            to="/"
            label="Dashboard"
            icon={<HomeIcon />}
            handleMouseEnter={() => this.setHoveredMenuItem('Dashboard')}
            handleMouseLeave={this.unsetHoveredMenuItem}
            isActive={currentPathName === '/'}
          />
          <MenuItem
            to="/reports"
            label="Reports"
            icon={<ReportIcon />}
            isActive={currentPathName === '/reports'}
          />
          <MenuItem
            href={insightUrl}
            label="Insight"
            icon={<EyeIcon />}
            handleMouseEnter={() => this.setHoveredMenuItem('Insight')}
            handleMouseLeave={this.unsetHoveredMenuItem}
          />
          <MenuItem href={ctsUrl} label="CTS" icon={<CTSIcon />} />
          <MenuItem
            to={definition.user.path}
            label={definition.user.text}
            icon={<definition.user.iconComponent />}
            handleMouseEnter={() =>
              this.setHoveredMenuItem(definition.user.text)
            }
            handleMouseLeave={this.unsetHoveredMenuItem}
            isActive={currentPathName === definition.user.path}
          />
          <MenuItem
            to="/address-book"
            label="Address Book"
            icon={<AddressIcon />}
            handleMouseEnter={() => this.setHoveredMenuItem('Address Book')}
            handleMouseLeave={this.unsetHoveredMenuItem}
            isActive={currentPathName === '/address-book'}
          />
          {this.props.userProfile.canManageUsers ? (
            <MenuItem
              to={definition.myMembers.path}
              label={definition.myMembers.text}
              icon={<definition.myMembers.iconComponent />}
              handleMouseEnter={() => {
                return this.setHoveredMenuItem(definition.myMembers.text);
              }}
              handleMouseLeave={this.unsetHoveredMenuItem}
              isActive={currentPathName === definition.myMembers.path}
            />
          ) : null}
        </MenuWrapper>
      </SidebarWrapper>
    );
  }
}

const propTypes = {
  className: PropTypes.string,
  userProfile: PropTypes.object.isRequired,
  setCurrentPathName: PropTypes.func.isRequired,
  currentPathName: PropTypes.string,
};

const defaultProps = {
  className: '',
};

SideBar.propTypes = propTypes;
SideBar.defaultProps = defaultProps;

export default SideBar;
