import styled from 'styled-components';
import { pl, py, ml } from 'styled-components-spacing';
import breakpoint from 'styled-components-breakpoint';
import { TextPanel } from '@hvccc/component-library';

export default styled(TextPanel)`
  background: unset;
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  ${({ theme }) => py(1, theme)};
  margin: 50px 0;

  :before {
    content: '';
    display: block;
    height: 100%;
    width: 6px;
    background-color: #cbcfde;
    border-radius: 4px 0 0 4px;
    position: absolute;
    top: 0;
  }

  ${TextPanel.TextComponent} {
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.fonts.heading};
    font-weight: 700;
    ${({ theme }) => pl(4, theme)};
    font-size: 1.75rem;

    ${breakpoint('xl')`
      font-size: ${({ theme }) => theme.typeScale[2]};
      ${({ theme }) => pl(5, theme)};
    `} p {
      display: inline;
    }

    :before {
      content: '“';
      display: inline-block;
      font-size: 1.75rem;
      margin-left: -15px;
      position: relative;
      padding-right: 7px;

      ${breakpoint('xl')`
        font-size: 3rem;
        top: 8px;
        margin-left: -30px;
        padding-right: 15px;
      `};
    }

    :after {
      content: '”';
      display: inline-block;
      font-size: 1.75rem;
      margin-left: 15px;
      position: relative;

      ${breakpoint('xl')`
        font-size: 3rem;
        top: 8px;
        margin-left: 7px;
      `};
    }
  }

  ${TextPanel.TitleComponent} {
    font-family: ${({ theme }) => theme.fonts.copy};
    font-weight: 600;
    font-size: ${({ theme }) => theme.typeScale[6]};
    ${({ theme }) => ml(4, theme)};
    color: ${({ theme }) => theme.colors.lightestGrey};

    ${breakpoint('xl')`
      margin-left: 30px;
    `};
  }
`;
