import { userProfileDataSelector } from '../../../redux/user/selectors';
import {
  notificationsLoadedSelector,
  notificationsDataSelector,
  notificationsUnreadCountSelector,
} from '../../../redux/notification/selectors';

export default (state) => ({
  user: userProfileDataSelector(state),
  notifications: notificationsDataSelector(state),
  notificationsLoaded: notificationsLoadedSelector(state),
  unreadNotificationCount: notificationsUnreadCountSelector(state),
});
