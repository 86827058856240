import {
  UserIcon,
  MembersIcon,
  HomeIcon,
  PlanetIcon,
  AddressIcon,
} from '../../icons/menu';

export const myMembers = {
  text: 'My Member Users',
  path: '/my-members',
  iconComponent: MembersIcon,
};

const user = {
  text: 'User',
  path: '/user',
  iconComponent: UserIcon,
};

const home = {
  text: 'Dashboard',
  path: '/',
  iconComponent: HomeIcon,
};

const news = {
  text: 'News',
  path: '/news',
  iconComponent: PlanetIcon,
};
const addressBook = {
  text: 'Address Book',
  path: '/address-book',
  iconComponent: AddressIcon,
};

export const sideBar = {
  user,
  myMembers,
};

export const mobile = {
  home,
  user,
  news,
  addressBook,
  myMembers,
};
