import { bindActionCreators } from 'redux';
import { fetchSiteLayout } from '../../redux/app/actions';
import { fetchUserProfile } from '../../redux/user/actions';

export default (dispatch) => ({
  ...bindActionCreators(
    {
      fetchUserProfile,
      fetchSiteLayout,
    },
    dispatch,
  ),
});
