import { call, put, takeLatest, select, fork, all } from 'redux-saga/effects';
import {
  categoryReadySelector,
  categoryDataSelector,
  categoryCurrentPageSelector,
} from './selectors';

import {
  CATEGORY_FETCH_REQUESTED,
  CATEGORY_FETCH_SUCCEEDED,
  CATEGORY_FETCH_FAILED,
  CATEGORY_NAVIGATE,
} from './actionTypes';

import { default as apiFetchCategory } from '../../api/category';

// ----------------------------------
// WORKERS
// ----------------------------------
function* fetchCategory({ payload: { category, page, pageSize } }) {
  const categoryReady = yield select(categoryReadySelector);
  const currentPage = yield select(categoryCurrentPageSelector);

  const { error, data } =
    categoryReady && currentPage === page
      ? yield select(categoryDataSelector)
      : yield call(apiFetchCategory, category, page, pageSize);

  if (error) {
    yield put({
      type: CATEGORY_FETCH_FAILED,
      payload: error,
    });
  } else {
    yield put({
      type: CATEGORY_FETCH_SUCCEEDED,
      payload: data,
    });
  }
}

function* navigateCategory({ payload: page }) {
  const categoryReady = yield select(categoryReadySelector);
  if (!categoryReady) {
    return;
  }

  const { slug: category } = yield select(categoryDataSelector);
  const payload = {
    category,
    page,
  };

  yield put({
    type: CATEGORY_FETCH_REQUESTED,
    payload: payload,
  });
}

// ----------------------------------
// WATCHERS
// ----------------------------------
export function* watchFetchCategory() {
  yield takeLatest(CATEGORY_FETCH_REQUESTED, fetchCategory);
}

export function* watchNavigateCategory() {
  yield takeLatest(CATEGORY_NAVIGATE, navigateCategory);
}

export default function* () {
  yield all([fork(watchFetchCategory), fork(watchNavigateCategory)]);
}
