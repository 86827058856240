import {
  TOGGLE_SIDE_BAR_OPEN,
  FETCH_CURRENT_PATH_NAME,
  SET_CURRENT_PATH_NAME,
  FETCH_SITE_LAYOUT_REQUESTED,
  FETCH_SITE_LAYOUT_SUCCEEDED,
  FETCH_SITE_LAYOUT_FAILED,
} from './actionTypes';

const initialState = {
  isSideBarOpen: false,
  currentPathName: null,
  layout: {
    loading: true,
    loaded: false,
    error: null,
    header: null,
    footer: null,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case TOGGLE_SIDE_BAR_OPEN: {
      return {
        ...state,
        isSideBarOpen: !state.isSideBarOpen,
      };
    }

    case FETCH_CURRENT_PATH_NAME: {
      return {
        ...state,
      };
    }

    case SET_CURRENT_PATH_NAME: {
      return {
        ...state,
        currentPathName: payload,
      };
    }

    case FETCH_SITE_LAYOUT_REQUESTED: {
      return {
        ...state,
        layout: {
          ...state.layout,
          loading: true,
          loaded: false,
          error: null,
        },
      };
    }

    case FETCH_SITE_LAYOUT_SUCCEEDED: {
      return {
        ...state,
        layout: {
          ...payload,
          loading: false,
          loaded: true,
          error: null,
        },
      };
    }

    case FETCH_SITE_LAYOUT_FAILED: {
      return {
        ...state,
        layout: {
          ...state.layout,
          loading: false,
          loaded: true,
          error: payload,
        },
      };
    }

    default:
      return state;
  }
};
