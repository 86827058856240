import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import history from '../../../utils/history';
import MenuItem from './MenuItem';
import { mobile as definition } from '../MenuItemDefinition';

const Wrapper = styled.nav`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  background: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.boxShadow};

  ${breakpoint('xl')`
    display: none;
  `};
`;

class MobileMenu extends Component {
  constructor(props) {
    super(props);

    const pathName = history.location.pathname;
    this.props.setCurrentPathName(pathName);
  }

  render() {
    const { className, currentPathName } = this.props;

    const visibilityConditions = {
      myMembers: this.props.userProfile.canManageUsers,
    };

    return (
      <Wrapper className={className}>
        {Object.keys(definition)
          .filter(
            (key) =>
              visibilityConditions[key] === undefined ||
              visibilityConditions[key],
          )
          .map((key) => {
            const obj = definition[key];

            return (
              <MenuItem
                to={obj.path}
                key={obj.path}
                label={obj.text}
                icon={<obj.iconComponent />}
                isActive={currentPathName === obj.path}
              />
            );
          })}
      </Wrapper>
    );
  }
}

const propTypes = {
  className: PropTypes.string,
  setCurrentPathName: PropTypes.func.isRequired,
  currentPathName: PropTypes.string,
};

const defaultProps = {
  className: '',
};

MobileMenu.propTypes = propTypes;
MobileMenu.defaultProps = defaultProps;

export default MobileMenu;
