import { bindActionCreators } from 'redux';
import { setReportCriteria } from '../../../redux/reports/actions';

export default (dispatch) => ({
  ...bindActionCreators(
    {
      setReportCriteria,
    },
    dispatch,
  ),
});
