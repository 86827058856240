import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { p, px, pt } from 'styled-components-spacing';
import breakpoint from 'styled-components-breakpoint';
import { Heading } from '@hvccc/component-library';
import Loading from '../../../libs/Loader';
import Article from './Article';

const Container = styled.div`
  ${pt(3)};

  ${breakpoint('xxl')`
    ${pt(4)};
  `};
`;

const Title = styled(Heading)`
  margin: 0;
  ${px(3)};

  ${breakpoint('xxl')`
    ${px(4)};
  `};
`;

const Articles = styled.div`
  ${px(3)};

  ${breakpoint('xxl')`
    ${px(4)};
  `};
`;

const AllArticlesLink = styled(RouterLink)`
  display: block;
  background-color: rgba(13, 45, 108, 0.05);
  color: ${({ theme }) => theme.colors.primary};
  padding: 46px 0;
  text-align: center;
  font-size: ${({ theme }) => theme.typeScale[4]};
  font-weight: ${({ theme }) => theme.weights.semi};
  line-height: 1.5;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

const NoArticles = styled.div`
  ${p(4)};
  ${pt(3)};
  text-align: center;
  font-size: 16px;
  font-weight: ${({ theme }) => theme.weights.semi};
  color: #666;
`;

class Newsfeed extends Component {
  componentDidMount() {
    this.props.fetchNews();
  }

  render() {
    const { news, isLoading } = this.props;

    return (
      <Container>
        <Title size={3} text="Newsfeed" />
        {isLoading && <Loading />}
        {news ? (
          <div>
            <Articles>
              {news.map((article, idx) => (
                <Article key={idx} article={article} />
              ))}
            </Articles>
            <AllArticlesLink to="/news">Read All Articles</AllArticlesLink>
          </div>
        ) : (
          <NoArticles>There are currently no articles</NoArticles>
        )}
      </Container>
    );
  }
}

const propTypes = {
  fetchNews: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  news: PropTypes.array.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

Newsfeed.propTypes = propTypes;
Newsfeed.defaultProps = defaultProps;

export default Newsfeed;
