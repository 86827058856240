import { bindActionCreators } from 'redux';
import { fetchCategory } from '../../redux/category/actions';
import { setCurrentPathName } from '../../redux/app/actions';

export default (dispatch) => ({
  ...bindActionCreators(
    {
      fetchCategory,
      setCurrentPathName,
    },
    dispatch,
  ),
});
