import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { py, pl } from 'styled-components-spacing';
import breakpoint from 'styled-components-breakpoint';
import moment from 'moment';
import { BackgroundImage } from '@hvccc/component-library';

const Title = styled.h3`
  font-size: 20px;
  font-weight: 700;
  line-height: 1.35;
  margin: 0 0 7px 0;
  ${({ theme }) => theme.transition};
`;

const WrapperLink = styled(RouterLink)`
  color: #2e2e37;
  text-decoration: none;
  font-family: 'Open Sans', sans-serif;
  ${py(3)};
  border-bottom: 1px solid #f3f4f8;
  display: flex;
  width: 100%;
  position: relative;

  ${breakpoint('xxl')`
    ${py(4)};
  `};

  :hover ${Title} {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const Date = styled.span`
  font-size: 14px;
  line-height: 1.93;
`;

const ThumbnailImage = styled(BackgroundImage)`
  flex-shrink: 0;
  width: 52px;
  height: 52px;

  ${breakpoint('xxl')`
    width: 124px;
    height: 124px;
  `};
`;

const TextBl = styled.div`
  flex-grow: 1;
  position: relative;
  ${pl(2)};

  ${breakpoint('xxl')`
    ${pl(3)};
  `};

  :after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    display: block;
    width: 0;
    height: 0;
    border-bottom: 7px solid ${({ theme }) => theme.colors.primary};
    border-left: 7px solid transparent;
  }
`;

class Article extends Component {
  render() {
    const { title, slug, featuredPicturePanel, createdDate } =
      this.props.article;
    return (
      <WrapperLink to={slug}>
        <ThumbnailImage {...featuredPicturePanel.picture} alt={title} />
        <TextBl>
          <Title>{title}</Title>
          {createdDate && (
            <Date>{moment(createdDate).format('Do MMMM YYYY')}</Date>
          )}
        </TextBl>
      </WrapperLink>
    );
  }
}

const propTypes = {
  article: PropTypes.object.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

Article.propTypes = propTypes;
Article.defaultProps = defaultProps;

export default Article;
