import React from 'react';
import ServerError from './500';
import NotFound from './404';

const Component = ({ status, ...props }) => {
  switch (status) {
    case 404:
      return <NotFound {...props} />;
    default:
      return <ServerError {...props} />;
  }
};

export default Component;
