import {
  OPEN_MEMBER_MODAL,
  CLOSE_MEMBER_MODAL,
  COMPLETE_CREATING_NEW_MEMBER,
  START_CREATING_NEW_MEMBER,
  START_FETCHING_MEMBERS,
  COMPLETE_FETCHING_MEMBERS,
  START_UPDATING_MEMBER,
  COMPLETE_UPDATING_MEMBER,
  START_EDITING_MEMBER,
  COMPLETE_EDITING_MEMBER,
  START_DELETING_MEMBER,
  COMPLETE_DELETING_MEMBER,
  COMPLETE_DELETING_MEMBER_ERROR,
  OPEN_MESSAGE_MODAL,
  CLOSE_MESSAGE_MODAL,
  COMPLETE_UPDATING_MEMBER_ERROR,
  PREPARE_DELETING_MEMBER,
  CANCEL_DELETING_MEMBER,
  START_FETCHING_AUDIT_LOGS,
  COMPLETE_FETCHING_AUDIT_LOGS,
} from './actionTypes';

import {
  messageIdentifiers,
  updateActions,
  updateSubjects,
} from '../../models/members';

const initialState = {
  members: [],
  managedOrganisations: [],
  isTableLoaderVisible: false,
  isAuditTableLoaderVisible: false,
  auditLogs: [],
  modal: {
    isOpen: false,
    isLoaderVisible: false,
    creationSuccess: false,
    creationMessage: '',
    requestCompleted: false,
  },
  messageModal: {
    isOpen: false,
    contentIdentifier: null,
    contentProps: null,
  },
  updatingMemberIds: [],
  editingMemberIds: [],
  deletingMemberIds: [],
  deletePendingMemberIds: [],
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case OPEN_MEMBER_MODAL: {
      return {
        ...state,
        modal: {
          ...state.modal,
          isOpen: true,
        },
      };
    }
    case CLOSE_MEMBER_MODAL: {
      return {
        ...state,
        modal: {
          ...state.modal,
          isOpen: false,
          isLoaderVisible: false,
          creationSuccess: false,
          creationMessage: '',
          requestCompleted: false,
        },
      };
    }
    case START_CREATING_NEW_MEMBER: {
      return {
        ...state,
        modal: {
          ...state.modal,
          isLoaderVisible: true,
        },
      };
    }
    case COMPLETE_CREATING_NEW_MEMBER: {
      return {
        ...state,
        modal: {
          ...state.modal,
          isLoaderVisible: false,
          creationSuccess: payload.success,
          creationMessage: payload.message,
          requestCompleted: true,
        },
      };
    }
    case START_FETCHING_MEMBERS: {
      return {
        ...state,
        isTableLoaderVisible: true,
        modal: {
          ...state.modal,
        },
      };
    }

    case START_FETCHING_AUDIT_LOGS: {
      return {
        ...state,
        isAuditTableLoaderVisible: true,
        auditLogs: state.auditLogs,
      };
    }

    case COMPLETE_FETCHING_MEMBERS: {
      const organisations = [];
      const organisationsDomains = [];
      let members = {};

      for (const organisation of payload) {
        const organisationsName = organisation.name;
        for (const member of organisation.members) {
          const emailDomain = getEmailDomain(member.email);

          const memberExtended = {
            ...member,
            organisationName: organisation.name,
            organisationEmailDomains: organisation.emailDomains,
          };

          if (members[emailDomain]) {
            members[emailDomain].push(memberExtended);
          } else {
            members[emailDomain] = [memberExtended];
          }
        }

        organisations.push(organisationsName);
        organisationsDomains.push(organisation.emailDomains);
      }

      const domains = Object.keys(members).sort();

      let sortedMembers = [];
      for (const domain of domains) {
        const temp = members[domain];
        temp.sort(emailSortFn);

        sortedMembers = sortedMembers.concat(temp);
      }

      return {
        ...state,
        isTableLoaderVisible: false,
        managedOrganisations: organisations,
        managedOrganisationsDomains: organisationsDomains.flat(),
        members: sortedMembers,
        modal: {
          ...state.modal,
        },
        updatingMemberIds: [],
        editingMemberIds: [],
        deletingMemberIds: [],
        deletePendingMemberIds: [],
      };
    }

    case COMPLETE_FETCHING_AUDIT_LOGS: {
      return {
        ...state,
        isAuditTableLoaderVisible: false,
        auditLogs: payload,
      };
    }

    case START_UPDATING_MEMBER: {
      const updatingMemberIds = [...state.updatingMemberIds, payload.id];
      return {
        ...state,
        modal: {
          ...state.modal,
        },
        updatingMemberIds: updatingMemberIds,
      };
    }

    case COMPLETE_UPDATING_MEMBER: {
      const memberIndex = state.members.findIndex((m) => m.id === payload.id);
      const member = state.members[memberIndex];

      const newMember = { ...member };
      newMember.phoneNumber = payload.model.phoneNumber;
      processActions(newMember, payload.model.actions);

      const newMembers = [...state.members];
      newMembers.splice(memberIndex, 1, newMember);

      const updatingMemberIds = state.updatingMemberIds.filter(
        (id) => id !== payload.id,
      );

      const editingMemberIds = state.editingMemberIds.filter(
        (id) => id !== payload.id,
      );

      return {
        ...state,
        members: newMembers,
        modal: {
          ...state.modal,
        },
        updatingMemberIds: updatingMemberIds,
        editingMemberIds: editingMemberIds,
      };
    }

    case COMPLETE_UPDATING_MEMBER_ERROR: {
      return {
        ...state,
        updatingMemberIds: state.updatingMemberIds.filter(
          (id) => id !== payload.id,
        ),
        editingMemberIds: state.editingMemberIds.filter(
          (id) => id !== payload.id,
        ),
        messageModal: {
          isOpen: true,
          contentIdentifier: messageIdentifiers.updateError,
          contentProps: { message: payload.message },
        },
      };
    }

    case START_EDITING_MEMBER: {
      const editingMemberIds = [...state.editingMemberIds, payload.id];
      return {
        ...state,
        modal: {
          ...state.modal,
        },
        editingMemberIds: editingMemberIds,
      };
    }

    case COMPLETE_EDITING_MEMBER: {
      return {
        ...state,
        modal: {
          ...state.modal,
        },
        editingMemberIds: state.editingMemberIds.filter(
          (id) => id !== payload.id,
        ),
      };
    }

    case PREPARE_DELETING_MEMBER: {
      const deletePendingMemberIds = [
        ...state.deletePendingMemberIds,
        payload.id,
      ];

      return {
        ...state,
        deletePendingMemberIds: deletePendingMemberIds,
        messageModal: {
          isOpen: true,
          contentIdentifier: messageIdentifiers.deleteConfirm,
          contentProps: { memberId: payload.id },
        },
      };
    }

    case START_DELETING_MEMBER: {
      const deletingMemberIds = [...state.deletingMemberIds, payload.id];
      return {
        ...state,
        deletingMemberIds: deletingMemberIds,
      };
    }

    case COMPLETE_DELETING_MEMBER: {
      return {
        ...state,
        members: state.members.filter((member) => member.id !== payload.id),
        deletingMemberIds: state.deletingMemberIds.filter(
          (id) => id !== payload.id,
        ),
        deletePendingMemberIds: state.deletePendingMemberIds.filter(
          (id) => id !== payload.id,
        ),
        messageModal: {
          isOpen: true,
          contentIdentifier: messageIdentifiers.deleteSuccess,
          contentProps: null,
        },
      };
    }

    case COMPLETE_DELETING_MEMBER_ERROR: {
      return {
        ...state,
        deletingMemberIds: state.deletingMemberIds.filter(
          (id) => id !== payload.id,
        ),
        deletePendingMemberIds: state.deletePendingMemberIds.filter(
          (id) => id !== payload.id,
        ),
        messageModal: {
          isOpen: true,
          contentIdentifier: messageIdentifiers.deleteError,
          contentProps: { message: payload.message },
        },
      };
    }

    case CANCEL_DELETING_MEMBER: {
      return {
        ...state,
        deletePendingMemberIds: state.deletePendingMemberIds.filter(
          (id) => id !== payload.id,
        ),
        messageModal: {
          isOpen: false,
          contentIdentifier: null,
          contentProps: null,
        },
      };
    }

    case OPEN_MESSAGE_MODAL: {
      return {
        ...state,
        modal: {
          ...state.modal,
        },
        messageModal: {
          isOpen: true,
          contentIdentifier: payload.contentIdentifier,
          contentProps: payload.contentProps,
        },
      };
    }

    case CLOSE_MESSAGE_MODAL: {
      return {
        ...state,
        messageModal: {
          isOpen: false,
          contentIdentifier: null,
          contentProps: null,
        },
      };
    }

    default:
      return state;
  }
};

function processActions(member, actions) {
  for (const subj in actions) {
    switch (subj) {
      case updateSubjects.memberAuthoriser:
        member.isMemberAuthoriser = actions[subj] === updateActions.add;
        break;
      case updateSubjects.dailySMSRecipient:
        member.isDailySMSRecipient = actions[subj] === updateActions.add;
        break;
      case updateSubjects.capPublishRecipient:
        member.isCapPublishSubscriber = actions[subj] === updateActions.add;
        break;
      case updateSubjects.railPublishRecipient:
        member.isRailPublishSubscriber = actions[subj] === updateActions.add;
        break;
      case updateSubjects.vesselPublishRecipient:
        member.isVesselPublishSubscriber = actions[subj] === updateActions.add;
        break;
      case updateSubjects.memberPortal:
        member.isAuthorisedToMemberPortal = actions[subj] === updateActions.add;
        break;
      case updateSubjects.operationalReports:
        member.isAuthorisedToOperationalReports =
          actions[subj] === updateActions.add;
        break;
      case updateSubjects.ips:
        member.isAuthorisedToIPS = actions[subj] === updateActions.add;
        break;
      default:
        break;
    }
  }
}

function getEmailDomain(email) {
  if (email) {
    const index = email.indexOf('@');
    return email.substr(index);
  } else {
    return '';
  }
}

function emailSortFn(member1, member2) {
  return member1?.email?.localeCompare(member2?.email);
}

export default reducer;
