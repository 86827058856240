import { bindActionCreators } from 'redux';
import {
  updateUserProfile,
  startUpdatingUserProfile,
  startEditingProfile,
  completeEditingProfile,
  completeUpdatingUserProfile,
} from '../../../redux/user/actions';

export default (dispatch) => ({
  ...bindActionCreators(
    {
      updateUserProfile,
      startUpdatingUserProfile,
      startEditingProfile,
      completeUpdatingUserProfile,
      completeEditingProfile,
    },
    dispatch,
  ),
});
