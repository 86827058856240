import React from 'react';
import styled from 'styled-components';
import StandardInput from './StandardInput';

const Wrapper = styled.div`
  position: relative;

  &:after {
    content: '\f002';
    font-family: ${({ theme }) => theme.fonts.icon};
    font-weight: 700;
    font-size: 16px;
    position: absolute;
    right: 1px;
    top: 1px;
    bottom: 2px;
    width: 45px;
    color: ${({ theme }) => theme.colors.shades[5]};
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledInput = styled(StandardInput)`
  padding-right: 40px;
`;

const SearchInput = (props) => (
  <Wrapper>
    <StyledInput {...props} />
  </Wrapper>
);

export default SearchInput;
