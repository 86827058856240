import { createSelector } from 'reselect';

export const select = (state) => state.members || {};
export const modalSelect = (state) => select(state)?.modal || {};
export const messageModalSelect = (state) => select(state)?.messageModal || {};

export const isOpenModalSelector = (state) => modalSelect(state).isOpen;

export const isModalLoaderVisibleSelector = (state) =>
  modalSelect(state).isLoaderVisible;

export const newMemberCreationStatusSelector = (state) =>
  modalSelect(state).creationSuccess;

export const newMemberCreationMessageSelector = (state) =>
  modalSelect(state).creationMessage;

export const newMemberRequestCompeletedSelector = (state) =>
  modalSelect(state).requestCompleted;

export const membersSelector = (state) => select(state).members;

export const managedOrganisationsSelector = (state) =>
  select(state).managedOrganisations;

export const managedOrganisationsDomainsSelector = (state) =>
  select(state).managedOrganisationsDomains;

export const auditLogsSelector = (state) => select(state).auditLogs;

export const isAuditTableLoaderVisibleSelector = (state) =>
  select(state).isAuditTableLoaderVisible;

export const isTableLoaderVisibleSelector = (state) =>
  select(state).isTableLoaderVisible;

export const memberUpdatingIdsSelector = (state) =>
  select(state).updatingMemberIds;

export const memberEditingIdsSelector = (state) =>
  select(state).editingMemberIds;

export const memberDeletingIdsSelector = (state) =>
  select(state).deletingMemberIds;

export const memberPendingDeleteIdsSelector = (state) =>
  select(state).deletePendingMemberIds;

export const isMemberUpdatingSelector = createSelector(
  [
    // Usual first input - extract value from `state`
    memberUpdatingIdsSelector,
    // Take the second arg, `category`, and forward to the output selector
    (state, memberId) => memberId,
  ],
  (array, memberId) => array.indexOf(memberId) !== -1,
);

export const isMemberEditingSelector = createSelector(
  [
    // Usual first input - extract value from `state`
    memberEditingIdsSelector,
    // Take the second arg, `category`, and forward to the output selector
    (state, memberId) => memberId,
  ],
  (array, memberId) => array.indexOf(memberId) !== -1,
);

export const isMemberDeletingSelector = createSelector(
  [
    // Usual first input - extract value from `state`
    memberDeletingIdsSelector,
    // Take the second arg, `category`, and forward to the output selector
    (state, memberId) => memberId,
  ],
  (array, memberId) => array.indexOf(memberId) !== -1,
);

export const isMemberPendingDeleteSelector = createSelector(
  [
    // Usual first input - extract value from `state`
    memberPendingDeleteIdsSelector,
    // Take the second arg, `category`, and forward to the output selector
    (state, memberId) => memberId,
  ],
  (array, memberId) => array.indexOf(memberId) !== -1,
);
