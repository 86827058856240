import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PagerComponent from './Component';

class CategoryPage extends Component {
  constructor(props) {
    super(props);

    this.handleNavigate = this.handleNavigate.bind(this);
  }

  handleNavigate(e, page) {
    e.preventDefault();

    const { totalPages, navigate } = this.props;

    if (page < 1 || page > totalPages) {
      return;
    }

    if (navigate) {
      navigate(page);
    }
  }

  render() {
    const { currentPage, totalPages } = this.props;

    if (totalPages <= 1) {
      return null;
    }

    return (
      <PagerComponent
        totalPages={totalPages}
        currentPage={currentPage}
        onNavigate={this.handleNavigate}
      />
    );
  }
}

const propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  navigate: PropTypes.func.isRequired,
};

CategoryPage.propTypes = propTypes;

export default CategoryPage;
