import { bindActionCreators } from 'redux';
import { updateUserSubscriptions } from '../../../redux/user/actions';

const map = (dispatch) => ({
  ...bindActionCreators(
    {
      updateUserSubscriptions,
    },
    dispatch,
  ),
});

export default map;
