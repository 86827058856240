import {
  ADDRESS_BOOK_ENTRIES_FETCH_REQUESTED,
  ADDRESS_BOOK_ENTRIES_FETCH_SUCCEEDED,
  ADDRESS_BOOK_ENTRIES_FETCH_FAILED,
} from './actionTypes';

const initialState = {
  addressBookEntries: {
    isLoading: false,
    isLoaded: false,
    data: [],
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ADDRESS_BOOK_ENTRIES_FETCH_SUCCEEDED: {
      return {
        ...state,
        addressBookEntries: {
          ...state.addressBookEntries,
          isLoading: false,
          isLoaded: true,
          data: payload,
        },
      };
    }
    case ADDRESS_BOOK_ENTRIES_FETCH_FAILED: {
      return {
        ...state,
        addressBookEntries: {
          ...state.addressBookEntries,
          isLoading: false,
          isLoaded: true,
          data: [],
        },
      };
    }

    case ADDRESS_BOOK_ENTRIES_FETCH_REQUESTED: {
      return {
        ...state,
        addressBookEntries: {
          ...state.addressBookEntries,
          isLoading: true,
        },
      };
    }

    default:
      return state;
  }
};
