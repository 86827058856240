import { withVariants } from '@hvccc/component-library';
import StandardInput from './StandardInput';
import SearchInput from './SearchInput';
import CalendarInput from './CalendarInput';

const variants = {
  standard: StandardInput,
  search: SearchInput,
  calendar: CalendarInput,
};

export default withVariants(variants)(StandardInput);
