import { createSelector } from 'reselect';

export const select = (state) => state.notification || {};
// Notifications (for Header)
export const notificationsSelector = (state) =>
  select(state).notifications || {};
export const notificationsDataParentSelector = (state) =>
  notificationsSelector(state).data || {};
export const notificationsDataSelector = (state) =>
  notificationsDataParentSelector(state).data || [];
export const notificationsLoadingSelector = (state) =>
  notificationsSelector(state).isLoading;
export const notificationsLoadedSelector = (state) =>
  notificationsSelector(state).isLoaded;
export const notificationsUnreadCountSelector = (state) =>
  notificationsDataParentSelector(state).unreadNotificationCount;
export const notificationsReadySelector = createSelector(
  notificationsLoadingSelector,
  notificationsLoadedSelector,
  (loading, loaded) => loading && loaded,
);

// Notifications by page (for Page of Notifications)
export const notificationsByPageSelector = (state) =>
  select(state).notificationsByPage || {};
export const notificationsByPageDataSelector = (state) =>
  notificationsByPageSelector(state).data || [];
export const notificationsByPageLoadingSelector = (state) =>
  notificationsByPageSelector(state).isLoading;
export const notificationsByPageLoadedSelector = (state) =>
  notificationsByPageSelector(state).isLoaded;
export const notificationsByPageReadySelector = createSelector(
  notificationsByPageLoadingSelector,
  notificationsByPageLoadedSelector,
  (loading, loaded) => loading && loaded,
);

export const notificationsPageSelector = (state) =>
  select(state).notificationsPage;
export const notificationTotalSelector = (state) =>
  notificationsByPageSelector(state).totalPages;
