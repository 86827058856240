import {
  isOpenModalSelector,
  newMemberCreationMessageSelector,
  newMemberCreationStatusSelector,
  newMemberRequestCompeletedSelector,
} from '../../../redux/members/selectors';

const map = (state) => ({
  isOpenModal: isOpenModalSelector(state),
  success: newMemberCreationStatusSelector(state),
  message: newMemberCreationMessageSelector(state),
  requestCompleted: newMemberRequestCompeletedSelector(state),
});

export default map;
