import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { px, pb, pt, mt, mb } from 'styled-components-spacing';
import { Heading, Button } from '@hvccc/component-library';
import ContentWrapper from '../../ContentWrapper';
import { Tooltip } from 'antd';
import './Profile.scss';

import PhoneInput, {
  validateAustralianPhoneNumber,
  PHONE_NUMBER_REQUIRED_ERROR,
} from '../../PhoneInput';

const StyledContentWrapper = styled(ContentWrapper)`
  max-width: 600px;
  ${({ theme }) => px(3, theme)};
  ${({ theme }) => pt(1, theme)};
  ${({ theme }) => pb(3, theme)};
  ${({ theme }) => mb(3, theme)};
  ${({ theme }) => mt(3, theme)};
`;

const SubHeading = styled(Heading)`
  font-size: 12px;
  letter-spacing: 1.7px;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.weights.semi};
  font-family: ${({ theme }) => theme.fonts.copy};
  ${({ theme }) => mt(3, theme)};
`;

const StyledValue = styled.p`
  font-size: ${({ theme }) => theme.typeScale[4]};
  font-weight: ${({ theme }) => theme.weights.semi};
  margin: 0;
`;

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.weights.semi};
  font-size: ${({ theme }) => theme.typeScale[4]};
`;

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phoneNumber: this.props.phoneNumber || '',
      mobileNumber: this.props.mobileNumber || '',
      telephoneError: undefined,
      mobileError: undefined,
    };
  }

  edit() {
    this.props.startEditingProfile(this.props.id);
  }

  save() {
    if (!this.state.telephoneError && !this.state.mobileError) {
      this.props.updateUserProfile(this.props.id, {
        PhoneNumber: this.state.phoneNumber,
        MobileNumber: this.state.mobileNumber,
      });
    }
  }

  cancel() {
    this.setState({
      mobileNumber: this.props.mobileNumber,
      phoneNumber: this.props.phoneNumber,
      telephoneError: null,
      mobileError: null,
    });

    this.props.completeEditingProfile(this.props.id);
  }

  getSaveButtonProps(isUpdating) {
    return {
      text: isUpdating ? 'Updating...' : 'Update Profile',
      disabled: isUpdating,
    };
  }

  getPhoneInputClass(isEditing) {
    const phoneInputDisabled = isEditing ? '' : 'disabled';
    const phoneInputError = this.state.telephoneError
      ? 'input-validation-error'
      : '';
    const phoneInputClass = `member-phone-input ${phoneInputDisabled} ${phoneInputError}`;

    return phoneInputClass;
  }

  getMobileInputClass(isEditing) {
    const phoneInputDisabled = isEditing ? '' : 'disabled';
    const phoneInputError = this.state.mobileError
      ? 'input-validation-error'
      : '';
    const phoneInputClass = `member-phone-input ${phoneInputDisabled} ${phoneInputError}`;

    return phoneInputClass;
  }

  onPhoneChange(phoneNumber) {
    this.setState({
      phoneNumber: phoneNumber,
    });
  }

  onMobileChange(mobileNumber) {
    this.setState({
      mobileNumber: mobileNumber,
    });
  }

  validatePhoneNumber() {
    const phoneNumber = this.state.phoneNumber;

    let error;
    if (phoneNumber) {
      error = validateAustralianPhoneNumber(phoneNumber);
    } else {
      error = PHONE_NUMBER_REQUIRED_ERROR;
    }

    this.setState({
      telephoneError: error,
    });
  }

  validateMobileNumber() {
    const mobileNumber = this.state.mobileNumber;

    let error;
    if (mobileNumber) {
      error = validateAustralianPhoneNumber(mobileNumber);
    }

    this.setState({
      mobileError: error,
    });
  }

  render() {
    const { name, phoneNumber, mobileNumber, email, companyName } = this.props;
    const isEditing = this.props.isProfileEditing();
    const phoneInputClass = this.getPhoneInputClass(isEditing);
    const mobileInputClass = this.getMobileInputClass(isEditing);
    const telephoneError = this.state.telephoneError;
    const isTelephoneError = !!telephoneError;
    const mobileError = this.state.mobileError;
    const isMobileError = !!mobileError;
    const isUpdating = this.props.isProfileUpdating();
    const saveButtonProps = this.getSaveButtonProps(isUpdating);

    return (
      <StyledContentWrapper>
        {isEditing ? (
          <div className="btn-container">
            <Button
              className="btn-crud grey-outlined"
              onClick={this.cancel.bind(this)}
              icon="fas fa-times"
              disabled={isUpdating}
              text="Cancel"
            ></Button>

            <Button
              className="btn-crud green"
              onClick={this.save.bind(this)}
              {...saveButtonProps}
            ></Button>
          </div>
        ) : (
          <div className="btn-container">
            <Button
              className="btn-crud grey"
              onClick={this.edit.bind(this)}
              icon="fas fa-pencil"
              text="Edit"
            ></Button>
          </div>
        )}

        <div>
          <SubHeading text="Full Name" />
          <StyledValue>{name}</StyledValue>
        </div>

        <div>
          <SubHeading text="Telephone" />

          {isEditing ? (
            <Tooltip
              overlayClassName="error-phone-tooltip"
              title={telephoneError}
              open={isTelephoneError}
              placement="bottom"
              color="white"
              arrow={{ pointAtCenter: true }}
            >
              <PhoneInput
                value={this.state.phoneNumber}
                containerClass={phoneInputClass}
                disabled={!isEditing || isUpdating}
                onChange={this.onPhoneChange.bind(this)}
                onBlur={this.validatePhoneNumber.bind(this)}
              ></PhoneInput>
            </Tooltip>
          ) : (
            <div>
              {phoneNumber ? (
                <StyledLink href={'tel:' + phoneNumber}>
                  {phoneNumber}
                </StyledLink>
              ) : (
                <StyledValue>No phone number</StyledValue>
              )}
            </div>
          )}
        </div>

        <div>
          <SubHeading text="Mobile phone" />

          {isEditing ? (
            <Tooltip
              overlayClassName="error-phone-tooltip"
              title={mobileError}
              open={isMobileError}
              placement="bottom"
              color="white"
              arrow={{ pointAtCenter: true }}
            >
              <PhoneInput
                value={this.state.mobileNumber}
                containerClass={mobileInputClass}
                disabled={!isEditing || isUpdating}
                onChange={this.onMobileChange.bind(this)}
                onBlur={this.validateMobileNumber.bind(this)}
              ></PhoneInput>
            </Tooltip>
          ) : (
            <div>
              {mobileNumber ? (
                <StyledLink href={'tel:' + mobileNumber}>
                  {mobileNumber}
                </StyledLink>
              ) : (
                <StyledValue>No phone number</StyledValue>
              )}
            </div>
          )}
        </div>

        <div>
          <SubHeading text="Email" />
          {email ? (
            <StyledLink href={'mailto:' + email}>{email}</StyledLink>
          ) : (
            <StyledValue>No email address</StyledValue>
          )}
        </div>

        <div>
          <SubHeading text="Company Name" />
          {companyName ? (
            <StyledValue>{companyName}</StyledValue>
          ) : (
            <StyledValue>You do not belong to any organisation</StyledValue>
          )}
        </div>
      </StyledContentWrapper>
    );
  }
}

const propTypes = {
  email: PropTypes.string,
  name: PropTypes.string,
  phoneNumber: PropTypes.string,
  mobileNumber: PropTypes.string,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

Profile.propTypes = propTypes;
Profile.defaultProps = defaultProps;

export default Profile;
