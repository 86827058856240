import React from 'react';
import FormControl from '../../FormControl';
import { Formik, Form, Field } from 'formik';
import Input from '../../Input';
import { Button } from '@hvccc/component-library';
import {
  validateEmailAddressApi,
  validateVesselPublishApi,
} from '../../../api/members';
import Checkbox from '../Checkbox';
import PhoneInput, {
  AUS_PHONE_NUMBER_VALIDATION_ERROR,
  validateAustralianPhoneNumber,
  sanitisePhoneNumber,
} from '../../PhoneInput';
import './MemberForm.scss';

import loader from '../../../assets/images/loader.gif';
import MemberRadioButtonList from '../MembersTable/MemberRadioButtonList';

const PhoneInputWrapper = ({ field, form, ...props }) => {
  return <PhoneInput {...props} value={props.value.phoneNumber} />;
};

class MemberForm extends React.Component {
  _subscribeToSMSEmptyPhone =
    'Provide a phone number once you subscribed to daily SMS';

  phoneNumberIdentifier = 'phoneNumber';
  subscribeToDailySMSIdentifier = 'subscribeToDailySMS';
  subscribeToCAPPublishIdentifier = 'subscribeToCAPPublish';
  subscribeToRailPublishIdentifier = 'subscribeToRailPublish';
  subscribeToVesselPublishIdentifier = 'subscribeToVesselPublish';
  subscribeToMaintenancePublishIdentifier = 'subscribeToMaintenancePublish';
  authoriserIdentifier = 'authoriserAccess';
  ipsIdentifier = 'ipsAccess';
  ctsIdentifier = 'ctsAccess';
  memberPortalIdentifer = 'memberPortalAccess';
  operationalReportsIdentifier = 'operationalReportsAccess';

  constructor(props) {
    super(props);

    this.state = {
      memberToCopyPermissionsFrom: null,
      subscribeToDailySMS: false,
      subscribeToCAPPublish: false,
      subscribeToRailPublish: false,
      subscribeToMaintenancePublish: false,
      subscribeToVesselPublish: false,
      authoriserAccess: false,
      ipsAccess: false,
      ctsAccess: false,
      memberPortalAccess: false,
      operationalReportsAccess: false,
      emailValidation: {
        value: '',
        valid: false,
        inProgress: false,
        organisationDomainsByEmail: null,
      },
      canManageVesselPublish: false,
    };

    this.canManageVesselPublish();
  }

  handleCancel() {}

  validateEmailAddress(email) {
    return validateEmailAddressApi(email);
  }

  canManageVesselPublish() {
    validateVesselPublishApi().then(({ data: validationResult }) => {
      this.setState({
        canManageVesselPublish: validationResult.isValid,
      });
    });
  }

  validateFullName(value) {
    let error;
    if (!value) {
      error = 'Provide the member’s first and last name';
    }

    return error;
  }

  validateEmail(value) {
    return new Promise((resolve, reject) => {
      const invalidEmailMessage = 'Format email as: name@yourorganisation.com';

      if (!value) {
        resolve(invalidEmailMessage);
      } else {
        const regexp = /^[A-Z0-9.'_%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

        if (!regexp.test(value)) {
          resolve(invalidEmailMessage);
        } else {
          const emailValidation = this.state.emailValidation;
          if (emailValidation.value !== value) {
            this.setState({
              emailValidation: { ...emailValidation, inProgress: true },
            });

            this.validateEmailAddress(value).then(
              ({ data: validationResult }) => {
                this.setState({
                  emailValidation: {
                    value: value,
                    valid: validationResult.isValid,
                    organisationDomainsByEmail:
                      validationResult.organisationDomainsByEmail,
                    inProgress: false,
                  },
                });

                if (validationResult.isValid) {
                  resolve(null);
                } else {
                  resolve(validationResult.message || invalidEmailMessage);
                }
              },
            );
          } else {
            resolve(emailValidation.valid ? null : invalidEmailMessage);
          }
        }
      }
    });
  }

  validatephoneNumber(value, isDailySMSRecipient) {
    const sanitised = sanitisePhoneNumber(value);
    let error = validateAustralianPhoneNumber(sanitised, 12);

    if (!error && !sanitised && isDailySMSRecipient) {
      error = AUS_PHONE_NUMBER_VALIDATION_ERROR;
    }
    return error;
  }

  validatePhoneNumberCheckbox(phoneNumber, isDailySMSRecipient, setFieldError) {
    const error = this.validatephoneNumber(phoneNumber, isDailySMSRecipient);
    setFieldError(this.phoneNumberIdentifier, error);
  }

  onAuthoriserChange(checked, setFieldValue) {
    setFieldValue(this.authoriserIdentifier, checked);
  }

  onIPSChange(checked, setFieldValue) {
    setFieldValue(this.ipsIdentifier, checked);
  }

  onCTSChange(checked, setFieldValue) {
    setFieldValue(this.ctsIdentifier, checked);
    if (checked) setFieldValue(this.memberPortalIdentifer, true);
  }

  onMemberPortalChange(checked, setFieldValue) {
    setFieldValue(this.memberPortalIdentifer, checked);

    if (!checked) setFieldValue(this.ctsIdentifier, false);
  }

  onOperationalReportsChange(checked, setFieldValue) {
    setFieldValue(this.operationalReportsIdentifier, checked);
  }

  onDailySMSChange(checked, setFieldValue) {
    setFieldValue(this.subscribeToDailySMSIdentifier, checked);
  }

  onCapPublishChange(checked, setFieldValue) {
    setFieldValue(this.subscribeToCAPPublishIdentifier, checked);
  }

  onRailPublishChange(checked, setFieldValue) {
    setFieldValue(this.subscribeToRailPublishIdentifier, checked);
  }

  onMaintenancePublishChange(checked, setFieldValue) {
    setFieldValue(this.subscribeToMaintenancePublishIdentifier, checked);
  }

  onVesselPublishChange(checked, setFieldValue) {
    setFieldValue(this.subscribeToVesselPublishIdentifier, checked);
  }

  getSubmitButtonProps(isLoading) {
    const emailValidationInProgress = this.state.emailValidation.inProgress;
    isLoading = isLoading || emailValidationInProgress;

    return {
      className: `btn-submit ${isLoading ? 'loading' : ''}`,
      text: isLoading ? null : 'Create new member user',
      image: isLoading ? loader : null,
    };
  }

  onMemberSelectionChange(selectedMember) {
    this.setState({
      memberToCopyPermissionsFrom: selectedMember,
    });
  }

  render() {
    const isLoading = this.props.isLoading;
    const memberList = this.props.members?.filter((member) => {
      return this.props.managedOrganisationsDomains?.some(
        (org) =>
          member.email?.toLowerCase()?.includes(org?.toLowerCase()) &&
          member.isAuthorisedToIPS,
      );
    });
    const showOperationalReports = this.props.organisations.some(
      (o) => o == 'Pacific National',
    );
    const submitButtonProps = this.getSubmitButtonProps(isLoading);
    const authoriserName = this.props.authoriser;

    return (
      <Formik
        initialValues={{
          fullName: '',
          email: '',
          phoneNumber: '',
          subscribeToDailySMS: false,
          subscribeToCAPPublish: false,
          subscribeToRailPublish: false,
          subscribeToMaintenancePublish: false,
          subscribeToVesselPublish: false,
          authoriserAccess: false,
          ipsAccess: false,
          ctsAccess: false,
          memberPortalAccess: false,
          operationalReportsAccess: false,
        }}
        onSubmit={(values) => {
          const member = {
            fullName: values.fullName,
            email: values.email,
            phoneNumber: sanitisePhoneNumber(values.phoneNumber),

            subscribeToDailySMS: values.subscribeToDailySMS,
            subscribeToCAPPublish: values.subscribeToCAPPublish,
            subscribeToRailPublish: values.subscribeToRailPublish,
            subscribeToMaintenancePublish: values.subscribeToMaintenancePublish,
            subscribeToVesselPublish: values.subscribeToVesselPublish,

            authoriserAccess: values.authoriserAccess,
            ipsAccess: values.ipsAccess,
            memberToCopyPermissionsFrom: this.state.memberToCopyPermissionsFrom,

            ctsAccess: values.ctsAccess,
            memberPortalAccess: values.memberPortalAccess,
            operationalReportsAccess: values.operationalReportsAccess,
            authoriserName: authoriserName,
          };
          this.props.createNewMember(member);
        }}
        validateOnMount={false}
        validateOnBlur={true}
        validateOnChange={false}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          errors,
          touched,
          setFieldValue,
          setFieldError,
        }) => {
          const disabledSubmit =
            this.state.emailValidation.inProgress ||
            Object.keys(errors).length > 0 ||
            Object.keys(touched).length === 0 ||
            (values.ipsAccess && !this.state.memberToCopyPermissionsFrom) ||
            (!values.authoriserAccess &&
              !values.ipsAccess &&
              !values.ctsAccess &&
              !values.memberPortalAccess &&
              !values.operationalReportsAccess);

          const isValidVesselPublish = values.email.endsWith('pwcs.com.au');

          const showErrorFn = (fieldName) =>
            errors[fieldName] && touched[fieldName];

          const showEmailError = () =>
            showErrorFn('email') && !this.state.emailValidation.inProgress;

          return (
            <Form onSubmit={handleSubmit} className="member-form">
              {isLoading ? <div className="loading-overlay"></div> : null}
              <FormControl
                htmlFor="fullName"
                labelText="Member User full name"
                className={
                  'member-form-control' +
                  (showErrorFn('fullName') ? ' validation-error' : '')
                }
              >
                <span className="asterik">*</span>
                <Field
                  id="fullName"
                  type="text"
                  name="fullName"
                  value={values.fullName}
                  component={Input}
                  onChange={handleChange}
                  validate={this.validateFullName.bind(this)}
                />
                {showErrorFn('fullName') ? (
                  <span className="error-message">{errors.fullName}</span>
                ) : null}
              </FormControl>
              <FormControl
                htmlFor="email"
                labelText="Email address"
                className={
                  'member-form-control' +
                  (showEmailError() ? ' validation-error' : '')
                }
              >
                <span className="asterik">*</span>
                <Field
                  id="email"
                  type="text"
                  name="email"
                  placeholder="name@yourorganisation.com"
                  value={values.email}
                  component={Input}
                  onChange={handleChange}
                  validate={this.validateEmail.bind(this)}
                  validateOnChange={true}
                  validateOnBlur={false}
                />
                {showEmailError() ? (
                  <span className="error-message">{errors.email}</span>
                ) : null}
              </FormControl>
              <FormControl
                htmlFor="phoneNumber"
                labelText="Mobile"
                className={
                  'member-form-control' +
                  (errors.phoneNumber ? ' validation-error' : '')
                }
              >
                <span className="hint">Format: 0400 000 000</span>
                <Field
                  id={this.phoneNumberIdentifier}
                  name={this.phoneNumberIdentifier}
                  inputProps={{
                    name: this.phoneNumberIdentifier,
                    id: this.phoneNumberIdentifier,
                  }}
                  containerClass="member-mobile-phone"
                  validate={(value) =>
                    this.validatephoneNumber(value, values.subscribeToDailySMS)
                  }
                  onChange={(value, values, event) => handleChange(event)}
                  onBlur={(event) => handleBlur(event)}
                  as={PhoneInputWrapper}
                  validateOnBlur={true}
                />
                {errors.phoneNumber ? (
                  <span className="error-message">{errors.phoneNumber}</span>
                ) : null}
              </FormControl>

              <div className="border-box">
                <span class="border-text">
                  Access <span className="asterik">*</span>
                </span>

                <FormControl
                  containerComponent="div"
                  className="member-form-control checkbox"
                  htmlFor="authoriserAccess"
                >
                  <Checkbox
                    size="large"
                    checked={values.authoriserAccess}
                    onChange={(event) => {
                      const checked = event.target.checked;
                      this.onAuthoriserChange(checked, setFieldValue);
                    }}
                  >
                    Authoriser
                  </Checkbox>
                </FormControl>
                <FormControl
                  containerComponent="div"
                  className="member-form-control checkbox"
                  htmlFor="ipsAccess"
                >
                  <Checkbox
                    size="large"
                    checked={values.ipsAccess}
                    onChange={(event) => {
                      const checked = event.target.checked;
                      this.onIPSChange(checked, setFieldValue);
                    }}
                  >
                    IPS
                  </Checkbox>
                </FormControl>
                {values.ipsAccess &&
                  (memberList && memberList.length > 0 ? (
                    <div>
                      <span>
                        Please select a member user to copy the IPS permissions
                        from for the new user.
                      </span>
                      <MemberRadioButtonList
                        memberList={memberList}
                        onMemberSelection={this.onMemberSelectionChange.bind(
                          this,
                        )}
                      />
                    </div>
                  ) : (
                    <div className="errorMessage">No users to select from</div>
                  ))}
                <FormControl
                  containerComponent="div"
                  className="member-form-control checkbox"
                  htmlFor="ctsAccess"
                >
                  <Checkbox
                    size="large"
                    checked={values.ctsAccess}
                    onChange={(event) => {
                      const checked = event.target.checked;
                      this.onCTSChange(checked, setFieldValue);
                    }}
                  >
                    CTS
                  </Checkbox>
                </FormControl>
                <FormControl
                  containerComponent="div"
                  className="member-form-control checkbox"
                  htmlFor="memberPortalAccess"
                >
                  <Checkbox
                    size="large"
                    checked={values.memberPortalAccess}
                    onChange={(event) => {
                      const checked = event.target.checked;
                      this.onMemberPortalChange(checked, setFieldValue);
                    }}
                  >
                    Member Portal
                  </Checkbox>
                </FormControl>

                {showOperationalReports && (
                  <FormControl
                    containerComponent="div"
                    className="member-form-control checkbox"
                    htmlFor="operationalReportsAccess"
                  >
                    <Checkbox
                      size="large"
                      checked={values.operationalReportsAccess}
                      onChange={(event) => {
                        const checked = event.target.checked;
                        this.onOperationalReportsChange(checked, setFieldValue);
                      }}
                    >
                      Operational Plans & Reports
                    </Checkbox>
                  </FormControl>
                )}
              </div>

              <div className="border-box">
                <span class="border-text">Subscriptions</span>

                <FormControl
                  className="member-form-control checkbox"
                  htmlFor="subscribeToDailySMS"
                >
                  <Checkbox
                    size="large"
                    checked={values.subscribeToDailySMS}
                    onChange={(event) => {
                      const checked = event.target.checked;
                      this.onDailySMSChange(checked, setFieldValue);
                      this.validatePhoneNumberCheckbox(
                        values.phoneNumber,
                        checked,
                        setFieldError,
                      );
                    }}
                  >
                    Subscribe user to Daily SMS
                  </Checkbox>
                </FormControl>
                <FormControl
                  className="member-form-control checkbox"
                  htmlFor="subscribeToCAPPublish"
                >
                  <Checkbox
                    size="large"
                    checked={values.subscribeToCAPPublish}
                    onChange={(event) => {
                      const checked = event.target.checked;
                      this.onCapPublishChange(checked, setFieldValue);
                    }}
                  >
                    Subscribe user to CAP Publish
                  </Checkbox>
                </FormControl>
                <FormControl
                  className="member-form-control checkbox"
                  htmlFor="subscribeToRailPublish"
                >
                  <Checkbox
                    size="large"
                    checked={values.subscribeToRailPublish}
                    onChange={(event) => {
                      const checked = event.target.checked;
                      this.onRailPublishChange(checked, setFieldValue);
                    }}
                  >
                    Subscribe user to Rail Publish
                  </Checkbox>
                </FormControl>
                <FormControl
                  className="member-form-control checkbox"
                  htmlFor="subscribeToVesselPublish"
                >
                  <Checkbox
                    size="large"
                    checked={values.subscribeToVesselPublish}
                    disabled={
                      !this.state.canManageVesselPublish ||
                      !isValidVesselPublish
                    }
                    onChange={(event) => {
                      const checked = event.target.checked;
                      this.onVesselPublishChange(checked, setFieldValue);
                    }}
                  >
                    Subscribe user to Vessel Publish
                  </Checkbox>
                </FormControl>
                <FormControl
                  className="member-form-control checkbox"
                  htmlFor="subscribeToMaintenancePublish"
                >
                  <Checkbox
                    size="large"
                    checked={values.subscribeToMaintenancePublish}
                    onChange={(event) => {
                      const checked = event.target.checked;
                      this.onMaintenancePublishChange(checked, setFieldValue);
                    }}
                  >
                    Subscribe user to Maintenance Publish
                  </Checkbox>
                </FormControl>
              </div>

              <Button
                type="submit"
                {...submitButtonProps}
                disabled={disabledSubmit}
              ></Button>
            </Form>
          );
        }}
      </Formik>
    );
  }

  getServerMessage(message, isSuccess) {
    if (message) {
      return (
        <div className={isSuccess ? 'success-block' : 'error-block'}>
          {message}
        </div>
      );
    }

    return null;
  }
}

export default MemberForm;
