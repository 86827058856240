import React from 'react';
import styled from 'styled-components';

const IconContainer = styled.span`
  display: inline-block;
  padding-left: 20px;
`;

const IconSorted = styled.i`
  color: ${({ theme }) => theme.colors.shades[7]};
`;

const IconNotSorted = styled.i`
  color: ${({ theme }) => theme.colors.shades[2]};
`;

export default ({ sortingByThisField, isReverseOrder }) => (
  <IconContainer>
    {sortingByThisField ? (
      <IconSorted
        className={isReverseOrder ? 'fas fa-sort-up' : 'fas fa-sort-down'}
      />
    ) : (
      <IconNotSorted className="fas fa-sort" />
    )}
  </IconContainer>
);
