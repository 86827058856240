import {
  fork,
  all,
  takeLatest,
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';
import {
  FETCH_ALL_MEMBERS,
  CREATE_NEW_MEMBER,
  START_CREATING_NEW_MEMBER,
  COMPLETE_CREATING_NEW_MEMBER,
  START_FETCHING_MEMBERS,
  COMPLETE_FETCHING_MEMBERS,
  START_UPDATING_MEMBER,
  COMPLETE_UPDATING_MEMBER,
  UPDATE_MEMBER,
  DELETE_MEMBER,
  START_DELETING_MEMBER,
  COMPLETE_DELETING_MEMBER,
  COMPLETE_DELETING_MEMBER_ERROR,
  COMPLETE_UPDATING_MEMBER_ERROR,
  START_FETCHING_AUDIT_LOGS,
  COMPLETE_FETCHING_AUDIT_LOGS,
  FETCH_ALL_AUDIT_LOGS,
} from './actionTypes';
import {
  createNewMemberApi,
  deleteMemberApi,
  getAllMembersApi,
  getAllAuditLogsApi,
  updateMemberMiddleware,
} from '../../api/members';

function* fetchAllMembers() {
  yield put({ type: START_FETCHING_MEMBERS });
  try {
    const result = yield getAllMembersApi();
    yield put({ type: COMPLETE_FETCHING_MEMBERS, payload: result });
  } catch {
    yield put({ type: COMPLETE_FETCHING_MEMBERS, payload: [] });
  }
}

function* fetchAllAuditLogs() {
  yield put({ type: START_FETCHING_AUDIT_LOGS });
  try {
    const result = yield getAllAuditLogsApi();
    yield put({ type: COMPLETE_FETCHING_AUDIT_LOGS, payload: result });
  } catch {
    yield put({ type: COMPLETE_FETCHING_AUDIT_LOGS, payload: [] });
  }
}

export function* createNewMember(action) {
  yield put({ type: START_CREATING_NEW_MEMBER });
  try {
    yield call(createNewMemberApi, action.member);
    yield put({
      type: COMPLETE_CREATING_NEW_MEMBER,
      payload: { success: true, message: '' },
    });
  } catch (error) {
    yield put({
      type: COMPLETE_CREATING_NEW_MEMBER,
      payload: {
        success: false,
        message: error?.response?.data || error.message,
      },
    });
  }
}

export function* updateMember(action) {
  yield put({ type: START_UPDATING_MEMBER, payload: { id: action.id } });

  try {
    yield call(updateMemberMiddleware, action.id, action.model);
    yield put({
      type: COMPLETE_UPDATING_MEMBER,
      payload: { id: action.id, model: action.model },
    });
  } catch (error) {
    yield put({
      type: COMPLETE_UPDATING_MEMBER_ERROR,
      payload: {
        id: action.id,
        message: error.message,
      },
    });
  }
}

export function* deleteMember(action) {
  yield put({ type: START_DELETING_MEMBER, payload: { id: action.memberId } });
  try {
    yield call(deleteMemberApi, action.memberId);
    yield put({
      type: COMPLETE_DELETING_MEMBER,
      payload: { id: action.memberId },
    });
  } catch (error) {
    yield put({
      type: COMPLETE_DELETING_MEMBER_ERROR,
      payload: { id: action.memberId, message: error.response.data },
    });
  }
}

export function* watchCreateNewMember() {
  yield takeLatest(CREATE_NEW_MEMBER, createNewMember);
}

export function* watchFetchAllMembers() {
  yield takeLatest(FETCH_ALL_MEMBERS, fetchAllMembers);
}

export function* watchFetchAllAuditLogs() {
  yield takeLatest(FETCH_ALL_AUDIT_LOGS, fetchAllAuditLogs);
}

export function* watchDeleteMember() {
  yield takeLatest(DELETE_MEMBER, deleteMember);
}

export function* watchUpdateMember() {
  yield takeEvery(UPDATE_MEMBER, updateMember);
}

function* index() {
  yield all([
    fork(watchFetchAllMembers),
    fork(watchFetchAllAuditLogs),
    fork(watchCreateNewMember),
    fork(watchUpdateMember),
    fork(watchDeleteMember),
  ]);
}

export default index;
