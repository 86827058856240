import { requestAuthorized } from './index';
import { contentApiEndpoint } from '../clientConfig';

export default async (category, slug) => {
  try {
    const { data } = await requestAuthorized({
      url: `${contentApiEndpoint}/api/${category}/${slug}`,
      method: 'get',
    });

    return { data };
  } catch (error) {
    const { response } = error;

    if (response) {
      const {
        data: { message },
        status,
      } = response;

      return {
        error: {
          message,
          status,
        },
      };
    } else {
      return {
        error: {
          message: 'An unexpected error occurred.',
          status: 500,
        },
      };
    }
  }
};
