import { bindActionCreators } from 'redux';
import {
  fetchNotificationsByPage,
  setNotificationReadState,
} from '../../redux/notification/actions';
import { setCurrentPathName } from '../../redux/app/actions';

export default (dispatch) => ({
  ...bindActionCreators(
    {
      fetchNotificationsByPage,
      setNotificationReadState,
      setCurrentPathName,
    },
    dispatch,
  ),
});
