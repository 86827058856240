import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Menu, MobileMenu } from '@hvccc/component-library';
import { withRouter } from 'react-router-dom';
import { logout } from '../../../msalConfig';

const HeaderWrapper = styled.div`
  ${breakpoint('xl')`
    position: absolute;
    right: 0;
    top: 0;    
  `};
`;

const SmallMenu = styled(MobileMenu)`
  ${breakpoint('xl')`
    display: none;
  `};
`;

const LargeMenu = styled(Menu)`
  display: none;

  ${breakpoint('xl')`
    display: flex;
  `};
`;

class Header extends React.Component {
  componentDidMount() {
    this.props.fetchNotifications();
  }
  handleNotificationLink = () => {
    this.props.history.push('/notifications');
  };
  handleUserLink = () => {
    this.props.history.push('/user');
  };
  render() {
    const {
      className,
      menu,
      user,
      notificationsLoaded,
      notifications,
      unreadNotificationCount,
    } = this.props;
    return (
      <HeaderWrapper className={className}>
        {menu && (
          <React.Fragment>
            <SmallMenu {...menu} logo="/logo-hvccc.svg" onLogOut={logout} />
            <LargeMenu
              {...menu}
              userName={user.displayName}
              onLogOut={logout}
              showNotifications={notificationsLoaded}
              notifications={notifications}
              onReadNotification={this.props.setNotificationReadState}
              allNotificationsLinkProps={{}}
              allNotificationsLinkCallback={this.handleNotificationLink}
              unreadNotificationCount={unreadNotificationCount}
              userLink={this.handleUserLink}
            />
          </React.Fragment>
        )}
      </HeaderWrapper>
    );
  }
}

Header.propTypes = {
  className: PropTypes.any,
  open: PropTypes.bool,
  handleToggle: PropTypes.func,
};

export default withRouter(Header);
