import styled from 'styled-components';

const ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.borderRadius};
  border-bottom: 7px solid ${({ theme }) => theme.colors.shades[2]};
  ${({ theme }) => theme.boxShadow};
`;

export default ContentWrapper;
