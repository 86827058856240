import { bindActionCreators } from 'redux';
import {
  //fetchWidgets,
  fetchPowerBiEmbedded,
} from '../../redux/dashboard/actions';
import { setCurrentPathName } from '../../redux/app/actions';

export default (dispatch) => ({
  ...bindActionCreators(
    {
      //fetchWidgets,
      fetchPowerBiEmbedded,
      setCurrentPathName,
    },
    dispatch,
  ),
});
