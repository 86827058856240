import {
  OPEN_MEMBER_MODAL,
  CLOSE_MEMBER_MODAL,
  CREATE_NEW_MEMBER,
  FETCH_ALL_MEMBERS,
  UPDATE_MEMBER,
  START_UPDATING_MEMBER,
  COMPLETE_UPDATING_MEMBER,
  START_EDITING_MEMBER,
  COMPLETE_EDITING_MEMBER,
  OPEN_MESSAGE_MODAL,
  CLOSE_MESSAGE_MODAL,
  DELETE_MEMBER,
  CANCEL_DELETING_MEMBER,
  PREPARE_DELETING_MEMBER,
  FETCH_ALL_AUDIT_LOGS,
} from './actionTypes';

export const openMemberModal = () => ({ type: OPEN_MEMBER_MODAL });
export const closeMemberModal = () => ({ type: CLOSE_MEMBER_MODAL });
export const createNewMember = (member) => ({
  type: CREATE_NEW_MEMBER,
  member,
});

export const fetchAllMembers = () => ({ type: FETCH_ALL_MEMBERS });
export const fetchAllAuditLogs = () => ({ type: FETCH_ALL_AUDIT_LOGS });
export const deleteMember = (memberId) => ({ type: DELETE_MEMBER, memberId });
export const cancelDeletingMember = (memberId) => ({
  type: CANCEL_DELETING_MEMBER,
  payload: { id: memberId },
});

export const startUpdatingMember = (id) => ({
  type: START_UPDATING_MEMBER,
  id,
});

export const completeUpdatingMember = (id, model) => ({
  type: COMPLETE_UPDATING_MEMBER,
  payload: { id, model },
});

export const updateMember = (id, model) => ({ type: UPDATE_MEMBER, id, model });

export const startEditingMember = (id) => ({
  type: START_EDITING_MEMBER,
  payload: { id },
});

export const completeEditingMember = (id) => ({
  type: COMPLETE_EDITING_MEMBER,
  payload: { id },
});

export const openMessageModal = (contentIdentifier, contentProps) => ({
  type: OPEN_MESSAGE_MODAL,
  payload: { contentIdentifier, contentProps },
});

export const closeMessageModal = () => ({
  type: CLOSE_MESSAGE_MODAL,
});

export const prepareDeletingMember = (id) => ({
  type: PREPARE_DELETING_MEMBER,
  payload: { id },
});
