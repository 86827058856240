export const NOTIFICATIONS_FETCH_REQUESTED = 'NOTIFICATIONS_FETCH_REQUESTED';
export const NOTIFICATIONS_FETCH_SUCCEEDED = 'NOTIFICATIONS_FETCH_SUCCEEDED';
export const NOTIFICATIONS_FETCH_FAILED = 'NOTIFICATIONS_FETCH_FAILED';

export const NOTIFICATION_MARK_AS_READ = 'NOTIFICATION_MARK_AS_READ';
export const NOTIFICATION_MARK_AS_UNREAD = 'NOTIFICATION_MARK_AS_UNREAD';

export const NOTIFICATIONS_FETCH_BY_PAGE_REQUESTED =
  'NOTIFICATIONS_FETCH_BY_PAGE_REQUESTED';
export const NOTIFICATIONS_FETCH_BY_PAGE_SUCCEEDED =
  'NOTIFICATIONS_FETCH_BY_PAGE_SUCCEEDED';
export const NOTIFICATIONS_FETCH_BY_PAGE_FAILED =
  'NOTIFICATIONS_FETCH_BY_PAGE_FAILED';
