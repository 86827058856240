import { call, put, takeLatest, select, fork, all } from 'redux-saga/effects';
import {
  FETCH_SITE_LAYOUT_REQUESTED,
  FETCH_SITE_LAYOUT_SUCCEEDED,
  FETCH_SITE_LAYOUT_FAILED,
} from './actionTypes';
import { layoutReadySelector, layoutHeaderFooterSelector } from './selectors';
import { default as apiFetchSiteLayout } from '../../api/siteLayout';

function* fetchSiteLayout() {
  const layoutReady = yield select(layoutReadySelector);

  try {
    const layout = layoutReady
      ? yield select(layoutHeaderFooterSelector)
      : yield call(apiFetchSiteLayout);
    yield put({
      type: FETCH_SITE_LAYOUT_SUCCEEDED,
      payload: layout,
    });
  } catch (error) {
    yield put({
      type: FETCH_SITE_LAYOUT_FAILED,
      payload: error,
    });
  }
}

// ----------------------------------
// WATCHERS
// ----------------------------------
export function* watchFetchSiteLayout() {
  yield takeLatest(FETCH_SITE_LAYOUT_REQUESTED, fetchSiteLayout);
}

export default function* () {
  yield all([fork(watchFetchSiteLayout)]);
}
