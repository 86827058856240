import {
  categoryDataSelector,
  categoryReadySelector,
  categoryErrorSelector,
  categoryCurrentPageSelector,
} from '../../redux/category/selectors';

export default (state) => ({
  category: categoryDataSelector(state),
  error: categoryErrorSelector(state),
  isLoaded: categoryReadySelector(state),
  currentPage: categoryCurrentPageSelector(state),
});
