import {
  reportCategoriesDataSelector,
  reportCriteriaCategoryIdSelector,
  reportCategoriesLoadingSelector,
  reportCategoriesErrorSelector,
} from '../../../redux/reports/selectors';

export default (state) => ({
  reportCategories: reportCategoriesDataSelector(state),
  selectedReportCategoryId: reportCriteriaCategoryIdSelector(state),
  isLoading: reportCategoriesLoadingSelector(state),
  error: reportCategoriesErrorSelector(state),
});
