import React from 'react';
import styled from 'styled-components';
import { p, my } from 'styled-components-spacing';

const Wrapper = styled.div`
  position: relative;

  &:after {
    content: '\f0d7';
    font-family: ${({ theme }) => theme.fonts.icon};
    font-weight: 700;
    font-size: 16px;
    position: absolute;
    right: 15px;
    top: 10px;
    color: ${({ theme }) => theme.colors.shades[5]};
    pointer-events: none;
  }
`;

const StyledSelect = styled.select`
  border-radius: ${({ theme: { borderRadius } }) =>
    `${borderRadius} ${borderRadius} 0 0`};
  border: 1px solid;
  border-bottom-width: 2px;
  border-color: ${({ theme, hasError }) =>
    hasError ? theme.colors.error : theme.colors.disabled};
  color: ${({ theme }) => theme.colors.copy};
  background-color: ${({ theme }) => theme.colors.white};
  display: block;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  ${({ theme }) => p(2, theme)};
  padding-right: 30px;
  ${({ theme }) => my(1, theme)};
  ${({ theme, hasError }) => hasError && theme.boxGlowError};
  font-size: 1rem;
  opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
  appearance: none;

  :focus {
    border-color: ${({ theme }) => theme.colors.primary};
    outline: none;
    ${({ theme }) => theme.boxGlow};
  }

  ::-ms-expand {
    display: none;
  }
`;

const Select = ({ field, hasError, children, ...props }) => (
  <Wrapper>
    <StyledSelect {...field} {...props} hasError={hasError}>
      {children}
    </StyledSelect>
  </Wrapper>
);

export default Select;
