import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { p, mb } from 'styled-components-spacing';
import { Pagination } from 'antd';
import { Notification } from '@hvccc/component-library';
import { getObjectFromQueryString } from '../../utils/queryString';
import history from '../../utils/history';
import { PageContainer, PaginationWrapper } from '../../libs/BasicStyles';
import Loading from '../../libs/Loader';
import ContentWrapper from '../ContentWrapper';
import Heading from '../Heading';
import Button from '../Button';

const PageTitle = styled(Heading)`
  ${mb(3)};
`;

const MainContent = styled.div`
  ${p(4)};
  padding-bottom: 0;
`;

const StyledNotification = styled(Notification)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.shades[1]};
  border-radius: 0;

  ${Notification.IconContainer} {
    background-color: transparent;
  }

  ${Notification.ContentContainer} {
    background-color: transparent;
  }

  :last-child {
    border: 0;
  }
`;

class NotificationsPage extends Component {
  constructor(props) {
    super(props);

    const pathName = history.location.pathname;
    this.props.setCurrentPathName(pathName);

    this.initialPageNumber = getObjectFromQueryString();
    const paginationParameter = {
      page: this.initialPageNumber.page ? +this.initialPageNumber.page : 1,
    };

    this.props.fetchNotificationsByPage(paginationParameter);
  }

  render() {
    const { notifications, isLoading } = this.props;

    const currentPage = +this.props.paginationData.page;
    const total = +this.props.total;

    return (
      <PageContainer>
        <PageTitle size={1} text="Notifications" variant="page-title" />
        <ContentWrapper>
          <MainContent>
            {isLoading && <Loading />}
            {!isLoading &&
              notifications &&
              notifications.map((notification, idx) => (
                <StyledNotification
                  key={idx}
                  notification={notification}
                  onReadNotification={this.props.setNotificationReadState}
                />
              ))}
          </MainContent>
          <PaginationWrapper>
            {total > 1 && (
              <Pagination
                defaultCurrent={currentPage}
                defaultPageSize={10}
                total={total * 10}
                onChange={(page) =>
                  this.props.fetchNotificationsByPage({ page })
                }
                itemRender={(page, type, originalElement) => (
                  <Button
                    page={page}
                    type={type}
                    active={page === this.props.page}
                    variant="reports-pagination"
                  />
                )}
              />
            )}
          </PaginationWrapper>
        </ContentWrapper>
      </PageContainer>
    );
  }
}

const propTypes = {
  fetchNotificationsByPage: PropTypes.func.isRequired,
  setCurrentPathName: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  notifications: PropTypes.array.isRequired,
  paginationData: PropTypes.object.isRequired,
  total: PropTypes.number,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
  total: 0,
};

NotificationsPage.propTypes = propTypes;
NotificationsPage.defaultProps = defaultProps;

export default NotificationsPage;
