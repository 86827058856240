import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Heading } from '@hvccc/component-library';
import { p, mb } from 'styled-components-spacing';
import breakpoint from 'styled-components-breakpoint';

import defaultImg from '../../../assets/images/default-bg.jpg';

const Wrapper = styled.div`
  flex: 0 0 100%;
  ${p(2)};

  ${breakpoint('lg')`
    flex: 0 0 50%;
    max-width: 50%;
  `};

  ${breakpoint('xxl')`
    flex: 0 0 33.333%;
    max-width: 33.333%;
  `};

  ${breakpoint('xxxl')`
    flex: 0 0 25%;
    max-width: 25%;
  `};
`;

const Container = styled.div`
  background: ${({ theme }) => theme.colors.white};
  height: 100%;
  border: 1px solid ${({ theme }) => theme.colors.shades[1]};
  border-radius: ${({ theme }) => theme.borderRadius};
  overflow: hidden;
  border-top: none;
  ${({ theme }) => theme.transition};

  &:hover {
    ${({ theme }) => theme.boxShadow};
    transform: translateY(-3px);
  }
`;

const StyledHeading = styled(Heading)`
  margin: 25px 0 15px 0;
`;

const ImgWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.shades[2]};
  background-image: url('${(props) =>
    props.picture ? props.picture : defaultImg}');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 200px;
`;

const TextItem = styled.p`
  font-size: ${({ theme }) => theme.typeScale[4]};
  color: ${({ theme }) => theme.colors.darkGrey};
  font-weight: ${({ theme }) => theme.weights.semi};
  ${mb(2)};
  margin-top: 0;
  padding-left: 25px;
  position: relative;
`;

const Icon = styled.i`
  position: absolute;
  top: 4px;
  left: 0;
  height: 16px;
  font-size: 18px;
`;

const ContactLink = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  display: inline-block;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
`;

const ContentWrapper = styled.div`
  padding: 0 30px 30px;
`;

class AddressItem extends Component {
  render() {
    const { title, pictureUrl, phone, email } = this.props;

    return (
      <Wrapper>
        <Container>
          <ImgWrapper className="contact-image" picture={pictureUrl} />

          <ContentWrapper>
            <StyledHeading size={4} text={title} />
            {phone && (
              <TextItem>
                <Icon className="fas fa-phone" />
                <ContactLink href={`tel:${phone.replace(/\s+/g, '')}`}>
                  {phone}
                </ContactLink>
              </TextItem>
            )}
            {email && (
              <TextItem>
                <Icon className="fas fa-envelope" />
                <ContactLink href={`mailto:${email}`}>{email}</ContactLink>
              </TextItem>
            )}
          </ContentWrapper>
        </Container>
      </Wrapper>
    );
  }
}

const propTypes = {
  name: PropTypes.string,
  picture: PropTypes.string,
  telephone: PropTypes.string,
  email: PropTypes.string,
  address: PropTypes.string,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

AddressItem.propTypes = propTypes;
AddressItem.defaultProps = defaultProps;

export default AddressItem;
