import { requestAuthorized } from './index';
import { webApiEndpoint } from '../clientConfig';

export const fetchUserProfile = () => {
  return requestAuthorized({
    url: `${webApiEndpoint}/api/user/profile`,
    method: 'get',
  }).then((response) => response.data);
};

export const updateUserSubscriptionApi = (id, model) => {
  const url = `${webApiEndpoint}/api/user/subscriptions/${id}`;

  return requestAuthorized({
    url: url,
    method: 'patch',
    data: model,
  });
};

export const updateUserProfileApi = (id, model) => {
  const url = `${webApiEndpoint}/api/user/profile`;

  return requestAuthorized({
    url: url,
    method: 'patch',
    data: model,
  });
};

const pendingUpdate = [];
export const updateUserSubscriptionMiddleware = async (id, model) => {
  const copy = [...pendingUpdate];

  let resFn;
  const p = new Promise((resolve) => {
    resFn = resolve;
  });
  pendingUpdate.push(p);

  let task = copy.shift();
  while (task) {
    await task;
    task = copy.shift();
  }

  const data = await updateUserSubscriptionApi(id, model);
  resFn();

  return data;
};

const pendingProfileUpdate = [];
export const updateUserProfileMiddleware = async (id, model) => {
  const copy = [...pendingProfileUpdate];

  let resFn;
  const p = new Promise((resolve) => {
    resFn = resolve;
  });
  pendingProfileUpdate.push(p);

  let task = copy.shift();
  while (task) {
    await task;
    task = copy.shift();
  }

  const data = await updateUserProfileApi(id, model);
  resFn();

  return data;
};
