import React from 'react';
import styled from 'styled-components';
import { py, px, mr } from 'styled-components-spacing';

const Button = styled.button`
  /* border: none; */
  /* color: ${({ theme }) => theme.colors.primary}; */
  background-color: transparent;
  /*border: 1px solid ${({ theme }) => theme.colors.primary};*/
  /*border-radius: ${({ theme }) => theme.borderRadius};*/
  padding-left: 12px;
  padding-right: 12px;
  /* ${py(1)}; */
  /* ${px(2)}; */
  /* ${mr(1)}; */
  cursor: pointer;

  .ant-pagination-disabled & {
    opacity: 0.4;
  }

  .ant-pagination-item-active & {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
  }

  :before {
    content: ${({ icon }) => (icon ? `'${icon}'` : 'none')};
    font-family: ${({ theme }) => theme.fonts.icon};
    font-weight: 700;
  }
`;

export default ({ page, type }) => {
  if (type === 'prev') {
    return <Button icon="\f053" />;
  }
  if (type === 'next') {
    return <Button icon="\f054" />;
  }
  return <Button>{page}</Button>;
};
