import React, { Component } from 'react';
import styled from 'styled-components';
import { mx, my, mr, ml, mt } from 'styled-components-spacing';
import breakpoint from 'styled-components-breakpoint';
import { PicturePanel } from '@hvccc/component-library';

const StyledPicturePanel = styled(PicturePanel)`
  ${({ theme }) => my(5, theme)};
  display: flex;
  flex-wrap: wrap;

  picture {
    order: 2;
    min-width: 1px;

    ${breakpoint('xl')`
    order: -1;
  `};
  }

  img {
    height: auto;
    max-width: 100%;

    ${breakpoint('xl')`
    max-width: calc(100% - (${({ theme }) => theme.spacing[6]}*2));
    ${({ theme }) => mx(6, theme)};
  `};
  }

  h4 {
    order: 1;
    font-size: 0.75rem;
    color: ${({ theme }) => theme.colors.lightGrey};
    font-weight: 600;
    font-family: ${({ theme }) => theme.fonts.copy};
    ${({ theme }) => mt(1, theme)};

    ${breakpoint('xl')`
    ${({ theme }) => ml(6, theme)};
  `} :before {
      content: '';
      font-family: ${({ theme }) => theme.fonts.icon};
      ${({ theme }) => mr(1, theme)};
    }
  }
`;

class PicPanel extends Component {
  componentDidMount() {
    try {
      require('picturefill')(); // eslint-disable-line global-require
    } catch (e) {
      console.log('Picturefill failed to load', JSON.stringify(e, null, 2)); // eslint-disable-line no-console
    }
  }

  render() {
    return <StyledPicturePanel {...this.props} />;
  }
}

export default PicPanel;
