import { bindActionCreators } from 'redux';
import { setReportCriteria } from '../../redux/reports/actions';
import { setCurrentPathName } from '../../redux/app/actions';

export default (dispatch) => ({
  ...bindActionCreators(
    {
      setReportCriteria,
      setCurrentPathName,
    },
    dispatch,
  ),
});
