import {
  NOTIFICATIONS_FETCH_REQUESTED,
  NOTIFICATIONS_FETCH_SUCCEEDED,
  NOTIFICATIONS_FETCH_FAILED,
  NOTIFICATIONS_FETCH_BY_PAGE_REQUESTED,
  NOTIFICATIONS_FETCH_BY_PAGE_SUCCEEDED,
  NOTIFICATIONS_FETCH_BY_PAGE_FAILED,
  NOTIFICATION_MARK_AS_READ,
  NOTIFICATION_MARK_AS_UNREAD,
} from './actionTypes';

const initialState = {
  notifications: {
    isLoading: false,
    isLoaded: false,
    data: [],
  },
  notificationsByPage: {
    isLoading: false,
    isLoaded: false,
    data: [],
    total: 0,
  },
  notificationsPage: {
    page: null,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case NOTIFICATION_MARK_AS_READ: {
      return {
        ...state,
        notifications: {
          ...state.notifications,
          data: {
            ...state.notifications.data,
            unreadNotificationCount:
              state.notifications.data.unreadNotificationCount - 1,
            data: state.notifications.data.data.map((notification) =>
              notification.id === payload.id
                ? { ...notification, read: true }
                : notification,
            ),
          },
        },
        notificationsByPage: {
          ...state.notificationsByPage,
          data: state.notificationsByPage.data.map((notification) =>
            notification.id === payload.id
              ? { ...notification, read: true }
              : notification,
          ),
        },
      };
    }
    case NOTIFICATION_MARK_AS_UNREAD: {
      return {
        ...state,
        notifications: {
          ...state.notifications,
          data: {
            ...state.notifications.data,
            unreadNotificationCount:
              state.notifications.data.unreadNotificationCount + 1,
            data: state.notifications.data.data.map((notification) =>
              notification.id === payload.id
                ? { ...notification, read: false }
                : notification,
            ),
          },
        },
        notificationsByPage: {
          ...state.notificationsByPage,
          data: state.notificationsByPage.data.map((notification) =>
            notification.id === payload.id
              ? { ...notification, read: false }
              : notification,
          ),
        },
      };
    }
    case NOTIFICATIONS_FETCH_SUCCEEDED: {
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isLoading: false,
          isLoaded: true,
          data: payload,
        },
      };
    }
    case NOTIFICATIONS_FETCH_FAILED: {
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isLoading: false,
          isLoaded: true,
          data: [],
        },
      };
    }

    case NOTIFICATIONS_FETCH_REQUESTED: {
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isLoading: true,
        },
      };
    }

    case NOTIFICATIONS_FETCH_BY_PAGE_REQUESTED: {
      return {
        ...state,
        notificationsByPage: {
          ...state.notificationsByPage,
          isLoading: true,
          isLoaded: false,
        },
        notificationsPage: {
          ...state.notificationsPage,
          ...payload,
        },
      };
    }

    case NOTIFICATIONS_FETCH_BY_PAGE_SUCCEEDED: {
      return {
        ...state,
        notificationsByPage: {
          ...state.notificationsByPage,
          isLoading: false,
          isLoaded: true,
          data: payload.data,
          totalPages: payload.totalPages,
          currentPage: payload.currentPage,
        },
      };
    }

    case NOTIFICATIONS_FETCH_BY_PAGE_FAILED: {
      return {
        ...state,
        notificationsByPage: {
          ...state.notificationsByPage,
          isLoading: false,
          isLoaded: true,
          data: [],
          totalPages: 0,
          currentPage: 0,
        },
      };
    }

    default:
      return state;
  }
};
