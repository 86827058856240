import { requestAuthorized } from './index';
import { contentApiEndpoint } from '../clientConfig';

export default async () => {
  const { data } = await requestAuthorized({
    url: `${contentApiEndpoint}/api/site/layout`,
    method: 'get',
  });

  if (!data.header.menu) {
    data.header = {
      ...data.header,
      menu: { items: [] },
    };
  }

  return data;
};
