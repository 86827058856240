import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Footer } from '@hvccc/component-library';
import Loader from '../../libs/Loader';
import Header from './Header';
import SideBar from './SideBar';
import MobileFooter from './MobileFooter';

const Root = styled.div`
  background-color: ${({ theme }) => theme.colors.shades[0]};

  ${breakpoint('xl')`
    display: flex;
    flex-direction: row;
  `};
`;

const PageWrapper = styled.div`
  flex-grow: 1;
  min-height: 100vh;

  ${breakpoint('xl')`
    max-width: calc(100% - 99px);
    width: 100%;
    display: flex;
    flex-direction: column;
  `};
`;

const Content = styled.div`
  flex-grow: 1;
  padding-bottom: 78px;

  ${breakpoint('xl')`
    padding: 0;
  `};
`;

const SmallFooter = styled(MobileFooter)`
  ${breakpoint('xl')`
    display: none;
  `};
`;

const LargeFooter = styled(Footer)`
  display: none;

  ${breakpoint('xl')`
    display: flex;
  `};
`;

class Layout extends React.Component {
  componentDidMount() {
    this.props.fetchSiteLayout();
    this.props.fetchUserProfile();
  }

  render() {
    const {
      isLoading,
      layout: { header, footer }, // eslint-disable-line
      userProfile: { hasAccessToMemberPortal },
      children,
    } = this.props;

    if (isLoading) return <Loader />;

    if (!hasAccessToMemberPortal) return <Content children={children} />;

    return (
      <Root>
        <SideBar />
        <PageWrapper>
          <Header {...header} />
          <Content children={children} />
          <SmallFooter />
          <LargeFooter {...footer} />
        </PageWrapper>
      </Root>
    );
  }
}

export default Layout;
