import { requestAuthorized } from './index';
import {
  contentApiEndpoint,
  pageSize as defaultPageSize,
} from '../clientConfig';

export default async (category, page = 1, pageSize = defaultPageSize) => {
  const query = `?${[`page=${page}`, `pageSize=${pageSize}`].join('&')}`;

  try {
    const { data } = await requestAuthorized({
      url: `${contentApiEndpoint}/api/${category}${query}`,
      method: 'get',
    });

    return { data };
  } catch (error) {
    const { response } = error;

    if (response) {
      const {
        data: { message },
        status,
      } = response;

      return {
        error: {
          message,
          status,
        },
      };
    } else {
      return {
        error: {
          message: 'An unexpected error occurred.',
          status: 500,
        },
      };
    }
  }
};
