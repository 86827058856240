import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'ramda';
import mapStateToProps from './mapStateToProps';
import mapDispatchToProps from './mapDispatchToProps';
import Container from './Container';

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(Container);
