import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as pbi from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import './Component.css';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const MobileContainer = styled.div`
  display: none;

  ${breakpoint('xs', 'md')`
    display: block;
  `};
`;

const DesktopContainer = styled.div`
  display: none;

  ${breakpoint('md')`
    display: block;
  `};
`;

class PowerBiEmbeddedContent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { config } = this.props;

    /* NOTE: The configuration of PowerBIEmbed is essentially repeated below. The idea is that there are two
        copies of the embedded content, but only one is displayed at any point in time. This visibility depend
        on the visibility of the "container", which is determined according to the theme breakpoints.
    */
    return (
      <>
        <MobileContainer>
          <PowerBIEmbed
            embedConfig={{
              type: config.type,
              id: config.id,
              embedUrl: config.embedUrl,
              accessToken: config.embedToken,
              tokenType: pbi.models.TokenType.Embed,
              settings: {
                panes: {
                  filters: {
                    expanded: false,
                    visible: false,
                  },
                  pageNavigation: {
                    visible: false,
                  },
                },
                layoutType: pbi.models.LayoutType.MobilePortrait,
                background: pbi.models.BackgroundType.Transparent,
              },
            }}
            eventHandlers={embedEventHandlers}
            cssClassName={'power-bi-report-container'}
            getEmbeddedComponent={(embeddedReport) => {
              this.report = embeddedReport;
            }}
          />
        </MobileContainer>

        <DesktopContainer>
          <PowerBIEmbed
            embedConfig={{
              type: config.type,
              id: config.id,
              embedUrl: config.embedUrl,
              accessToken: config.embedToken,
              tokenType: pbi.models.TokenType.Embed,
              settings: {
                panes: {
                  filters: {
                    expanded: false,
                    visible: false,
                  },
                  pageNavigation: {
                    visible: false,
                  },
                },
                background: pbi.models.BackgroundType.Transparent,
              },
            }}
            eventHandlers={embedEventHandlers}
            cssClassName={'power-bi-report-container'}
            getEmbeddedComponent={(embeddedReport) => {
              this.report = embeddedReport;
            }}
          />
        </DesktopContainer>
      </>
    );
  }
}

const embedEventHandlers = new Map([
  [
    'loaded',
    function () {
      // Do nothing
    },
  ],
  [
    'rendered',
    function () {
      // Do nothing
    },
  ],
  ['error', logPowerBIEmbedError],
]);

function logPowerBIEmbedError(event) {
  const message = event.detail && event.detail.message;
  const detailedMessage = event.detail && event.detail.detailedMessage;

  // eslint-disable-next-line no-console
  console.error(
    `PowerBiEmbeddedContent error - Message: ${message}, DetailedMessage: ${detailedMessage}`,
  );
}

const propTypes = {
  config: PropTypes.object.isRequired,
};

PowerBiEmbeddedContent.propTypes = propTypes;

export default PowerBiEmbeddedContent;
