import {
  REPORT_CATEGORIES_FETCH_REQUESTED,
  SET_REPORT_CRITERIA,
} from './actionTypes';

export const fetchReportCategories = () => ({
  type: REPORT_CATEGORIES_FETCH_REQUESTED,
});

export const setReportCriteria = (criteria) => ({
  type: SET_REPORT_CRITERIA,
  payload: criteria,
});
