import { Modal } from 'antd';
import { messageIdentifiers } from '../../../models/members';
import ConfirmDelete from './ConfirmDelete';
import ErrorMessage from './ErrorMessage';
import SuccessMessage from './SuccessMessage';
import './Messages.scss';

export default function MessageModal(props) {
  function getContent(identifier, contentProps) {
    if (identifier === null) {
      return null;
    }

    let content;

    const componentProps = {
      ...contentProps,
      closeModal: props.closeModal,
    };

    switch (identifier) {
      case messageIdentifiers.deleteConfirm:
        content = <ConfirmDelete {...componentProps}></ConfirmDelete>;
        break;
      case messageIdentifiers.deleteSuccess:
        content = (
          <SuccessMessage {...componentProps}>
            <p>The member was successfully deleted.</p>
          </SuccessMessage>
        );
        break;
      case messageIdentifiers.deleteError:
        content = (
          <ErrorMessage {...componentProps}>
            <p>
              We can’t delete the member at this time. Refresh your browser
              window and try again.
              <br />
              <br />
              Error message: {props.contentProps?.message}
            </p>
          </ErrorMessage>
        );
        break;
      case messageIdentifiers.updateError:
        content = (
          <ErrorMessage {...componentProps}>
            <p>
              We can’t update the memeber’s details at this time. Refresh your
              browser window and try again.
              <br />
              <br />
              Error message: {props.contentProps?.message}
            </p>
          </ErrorMessage>
        );
        break;
      default:
        content = null;
    }

    return content;
  }

  return (
    <Modal
      title={null}
      open={props.isOpen}
      destroyOnClose={true}
      wrapClassName="notification-modal"
      width="400px"
      footer={null}
      closable={false}
    >
      <div className="message-container">
        {getContent(props.contentIdentifier, props.contentProps)}
      </div>
    </Modal>
  );
}
