import { all } from 'redux-saga/effects';
import dashboardSagas from './dashboard/sagas';
import notificationSagas from './notification/sagas';
import reportSagas from './reports/sagas';
import addressBookSagas from './addressBook/sagas';
import userSagas from './user/sagas';
import appSagas from './app/sagas';
import categorySagas from './category/sagas';
import standardPageSagas from './standardPage/sagas';
import membersSaga from './members/sagas';

export default function* rootSaga() {
  yield all([
    dashboardSagas(),
    notificationSagas(),
    reportSagas(),
    addressBookSagas(),
    userSagas(),
    appSagas(),
    categorySagas(),
    standardPageSagas(),
    membersSaga(),
  ]);
}
