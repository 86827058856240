import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { p, py, px, pb, pr, mb } from 'styled-components-spacing';
import breakpoint from 'styled-components-breakpoint';
import { PageContainer } from '../../libs/BasicStyles';
import Loading from '../../libs/Loader';
import history from '../../utils/history';
import Heading from '../Heading';
import AddressItem from './AddressItem';

const PageTitle = styled(Heading)`
  ${mb(3)};
`;

const ContentWrapper = styled.div`
  ${breakpoint('lg')`
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.borderRadius};
    border-bottom: 7px solid ${({ theme }) => theme.colors.shades[2]};
    ${({ theme }) => theme.boxShadow};
  `};
`;

const AddressList = styled.div`
  ${pb(4)};
  margin: 0 -10px;

  ${breakpoint('lg')`
    display: flex;
    flex-wrap: wrap;
    ${p(4)}
  `};
`;

const ContentHeader = styled.section`
  ${breakpoint('lg')`
    background-color: ${({ theme }) => theme.colors.shades[5]};
    display: flex;
    align-items: flex-end;
    ${py(3)};
    ${px(4)};
  `};
`;

const HeaderInfo = styled.div`
  display: none;

  ${breakpoint('lg')`
    display: block;
    flex-basis: 585px;
    ${pr(3)};
  `};
`;

const HeaderTitle = styled(Heading)`
  color: ${({ theme }) => theme.colors.white};
  margin: 0;
`;

const HeaderDescription = styled.p`
  color: ${({ theme }) => theme.colors.white};
  margin: 0;
  font-size: 16px;
  line-height: 27px;
`;

class AddressBook extends Component {
  constructor(props) {
    super(props);

    const pathName = history.location.pathname;
    this.props.setCurrentPathName(pathName);

    this.props.fetchAddressBookEntries();
  }

  render() {
    const { addressBookEntries, isLoading } = this.props;

    return (
      <PageContainer>
        <PageTitle size={1} text="Address Book" variant="page-title" />

        <ContentWrapper>
          <ContentHeader>
            <HeaderInfo>
              <HeaderTitle size={4} text="Key HVCCC Contacts" />
              <HeaderDescription>
                Find HVCCC key roles and departments listed in the Address Book
                below.
              </HeaderDescription>
            </HeaderInfo>
          </ContentHeader>

          {isLoading && <Loading />}
          <AddressList>
            {addressBookEntries &&
              addressBookEntries.map((addressItem, idx) => {
                const { title, pictureUrl, phone, email } = addressItem;
                return (
                  <AddressItem
                    key={idx}
                    title={title}
                    pictureUrl={pictureUrl}
                    phone={phone}
                    email={email}
                  />
                );
              })}
          </AddressList>
        </ContentWrapper>
      </PageContainer>
    );
  }
}

const propTypes = {
  addressBookEntries: PropTypes.array.isRequired,
  setCurrentPathName: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

AddressBook.propTypes = propTypes;
AddressBook.defaultProps = defaultProps;

export default AddressBook;
