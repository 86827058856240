import { requestAuthorized } from './index';
import { objectToQueryString } from '../utils/queryString';
import { webApiEndpoint } from '../clientConfig';

export const fetchReportCategories = async () => {
  try {
    const { data } = await requestAuthorized({
      url: `${webApiEndpoint}/api/report/categories`,
      method: 'get',
    });

    return data;
  } catch (error) {
    throw new Error('An unexpected error occurred.');
  }
};

export const fetchReportList = async (parameters) => {
  const query = objectToQueryString(parameters);

  try {
    const { data } = await requestAuthorized({
      url: `${webApiEndpoint}/api/reports?${query}`,
      method: 'get',
    });

    return data;
  } catch (error) {
    throw new Error('An unexpected error occurred.');
  }
};
