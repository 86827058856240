import axios from 'axios';
import { acquireToken } from '../msalConfig';

export const requestAuthorized = async (request) => {
  const token = await acquireToken();

  return axios.request({
    ...request,
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
};
