import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import history from '../../utils/history';
import { PageContainer } from '../../libs/BasicStyles';
import Heading from '../Heading';
import Profile from './Profile';
import Subscription from './Subscription';

const HeaderSection = styled.div``;

class User extends Component {
  constructor(props) {
    super(props);

    const pathName = history.location.pathname;
    this.props.setCurrentPathName(pathName);
  }

  render() {
    const {
      givenName,
      surname,
      displayName,
      phoneNumber,
      mobileNumber,
      email,
      companyName,
      subscriptions,
    } = this.props.userProfile;

    return (
      <PageContainer>
        <HeaderSection>
          <Heading size={1} text="Your Profile" variant="page-title" />
        </HeaderSection>
        <Profile
          email={email}
          name={
            givenName && surname
              ? `${givenName} ${surname}`
              : displayName || 'no name set'
          }
          phoneNumber={phoneNumber}
          mobileNumber={mobileNumber}
          companyName={companyName}
        />
        <Subscription
          userProfile={this.props.userProfile}
          subscriptions={subscriptions}
        />
      </PageContainer>
    );
  }
}

const propTypes = {
  setCurrentPathName: PropTypes.func.isRequired,
  userProfile: PropTypes.object,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

User.propTypes = propTypes;
User.defaultProps = defaultProps;

export default User;
