import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { py, px, ml } from 'styled-components-spacing';
import moment from 'moment';
import axios from 'axios';
import { saveAs } from 'file-saver';
import SortingIcon from './SortingIcon';
import Loading from '../../../libs/Loader';
import Error from '../../Error';
import { webApiEndpoint, autoOpenReportTypes } from '../../../clientConfig';
import { acquireToken } from '../../../msalConfig';

const ReportsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  table-layout: fixed;
`;

const TableHead = styled.thead`
  display: block;
  user-select: none;
  background-color: rgba(162, 167, 184, 0.22);
`;

const TableBody = styled.tbody`
  display: block;
  max-height: 650px;
  overflow-y: auto;
`;

const Row = styled.tr`
  display: flex;
  padding: 0 30px;

  &:nth-child(even) td {
    background-color: rgba(13, 45, 108, 0.05);
  }
`;

const TitleBtn = styled.span`
  display: inline-block;
  cursor: pointer;
  padding: 20px 15px;
  font-size: ${({ theme }) => theme.typeScale[7]};
  font-weight: ${({ theme }) => theme.weights.semi};
  text-transform: uppercase;
`;

const HeaderCell = styled.th`
  display: block;
  width: calc((100% - 150px) / 2);
  text-align: left;
`;

const DateHeader = styled(HeaderCell)`
  width: 150px;
`;

const TitleHeader = styled(HeaderCell)``;

const DescriptionHeader = styled(HeaderCell)``;

const Description = styled.span`
  display: inline-block;
  ${py(3)};
  ${px(2)};
  font-size: ${({ theme }) => theme.typeScale[7]};
  font-weight: ${({ theme }) => theme.weights.semi};
  text-transform: uppercase;
`;

const Cell = styled.td`
  display: block;
  width: calc((100% - 150px) / 2);
  padding: 20px 15px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

const DateCell = styled(Cell)`
  width: 150px;
`;

const TitleCell = styled(Cell)``;

const DescriptionCell = styled(Cell)`
  border-right: 0;
`;

const OpenReportLink = styled.a`
  color: ${({ theme }) => theme.colors.shades[6]};
  text-decoration: none;
  ${ml(3)};
  ${({ theme }) => theme.transition};

  :hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const DownloadReportLink = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.weights.semi};
  font-size: ${({ theme }) => theme.typeScale[6]};
`;

const ReportDownloadOpener = styled.span`
  margin-left: 5px;
`;

class ReportList extends Component {
  handleSorting(sortingBy) {
    const { setReportCriteria, isReverseSortOrder } = this.props;

    setReportCriteria({
      sortByField: sortingBy,
      isReverseSortOrder: !isReverseSortOrder,
    });
  }

  reportDownloadUrl(id) {
    return `${webApiEndpoint}/api/reports/${id}/download`;
  }

  async downloadReport(event, id) {
    event.preventDefault();
    const token = await acquireToken();
    axios({
      url: this.reportDownloadUrl(id),
      method: 'GET',
      responseType: 'blob',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }).then((response) => {
      this.saveReport(response);
    });
  }

  saveReport(response) {
    const contentDisposition = response.headers['content-disposition'];
    let fileName = 'unknown';
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
      if (fileNameMatch.length === 2) {
        fileName = fileNameMatch[1];
      }
    }
    saveAs(response.data, fileName);
  }

  async openReport(event, id) {
    event.preventDefault();
    const token = await acquireToken();
    axios({
      url: this.reportDownloadUrl(id),
      method: 'GET',
      responseType: 'blob',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }).then((response) => {
      const contentType = response.headers['content-type'];
      //If the content type isn't configured to open in the browser, just download it
      if (!autoOpenReportTypes.includes(contentType)) {
        this.saveReport(response);
      } else {
        //Create a Blob
        const file = new Blob([response.data], { type: contentType });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        let newWindow = window.open('/');
        newWindow.onload = () => {
          newWindow.location = fileURL;
        };
      }
    });
  }

  render() {
    const { loading, error, reportList, sortByField, isReverseSortOrder } =
      this.props;

    if (loading) return <Loading />;

    if (error) {
      return <Error error={error} />;
    }

    return (
      <ReportsTable>
        <TableHead>
          <Row>
            <DateHeader>
              <TitleBtn onClick={() => this.handleSorting('reportDate')}>
                Date
                <SortingIcon
                  sortingByThisField={sortByField === 'reportDate'}
                  isReverseOrder={isReverseSortOrder}
                />
              </TitleBtn>
            </DateHeader>
            <TitleHeader>
              <TitleBtn onClick={() => this.handleSorting('title')}>
                Title
                <SortingIcon
                  sortingByThisField={sortByField === 'title'}
                  isReverseOrder={isReverseSortOrder}
                />
              </TitleBtn>
            </TitleHeader>
            <DescriptionHeader>
              <Description>Description</Description>
            </DescriptionHeader>
          </Row>
        </TableHead>

        <TableBody>
          {reportList &&
            reportList.map((report, id) => (
              <Row key={id}>
                <DateCell>
                  {moment(report.reportDate).format('DD MMM YYYY')}
                </DateCell>

                <TitleCell>
                  <span>
                    {report.docType && report.docType === 'Excel' ? (
                      <i className="fas fa-file-excel" />
                    ) : report.docType === 'Word' ? (
                      <i className="fas fa-file-word" />
                    ) : report.docType === 'PDF' ? (
                      <i className="fas fa-file-pdf" />
                    ) : (
                      <i className="fas fa-file" />
                    )}
                  </span>
                  <OpenReportLink
                    href={this.reportDownloadUrl(report.id)}
                    onClick={(event) => this.openReport(event, report.id)}
                    download="test.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Open Report"
                  >
                    {report.title}
                  </OpenReportLink>
                  <ReportDownloadOpener>(</ReportDownloadOpener>
                  <DownloadReportLink
                    href={this.reportDownloadUrl(report.id)}
                    onClick={(event) => this.downloadReport(event, report.id)}
                    download="test.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Download Report"
                  >
                    download
                  </DownloadReportLink>
                  )
                </TitleCell>
                <DescriptionCell>{report.description}</DescriptionCell>
              </Row>
            ))}
        </TableBody>
      </ReportsTable>
    );
  }
}

const propTypes = {
  className: PropTypes.string,
  reportList: PropTypes.array.isRequired,
  sortByField: PropTypes.string,
  isReverseSortOrder: PropTypes.bool,
  setReportCriteria: PropTypes.func,
};

const defaultProps = {
  className: '',
  reportList: [],
};

ReportList.propTypes = propTypes;
ReportList.defaultProps = defaultProps;

export default ReportList;
