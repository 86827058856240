export const WIDGETS_FETCH_REQUESTED = 'WIDGETS_FETCH_REQUESTED';
export const WIDGETS_FETCH_SUCCEEDED = 'WIDGETS_FETCH_SUCCEEDED';
export const WIDGETS_FETCH_FAILED = 'WIDGETS_FETCH_FAILED';
export const POWERBI_EMBEDDED_FETCH_REQUESTED =
  'POWERBI_EMBEDDED_FETCH_REQUESTED';
export const POWERBI_EMBEDDED_FETCH_SUCCEEDED =
  'POWERBI_EMBEDDED_FETCH_SUCCEEDED';
export const POWERBI_EMBEDDED_FETCH_FAILED = 'POWERBI_EMBEDDED_FETCH_FAILED';
export const NEWS_FETCH_REQUESTED = 'NEWS_FETCH_REQUESTED';
export const NEWS_FETCH_SUCCEEDED = 'NEWS_FETCH_SUCCEEDED';
export const NEWS_FETCH_FAILED = 'NEWS_FETCH_FAILED';
