import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { py, px, pb, mt, mr } from 'styled-components-spacing';
import DatePicker from 'react-datepicker';
import { Formik, Form, Field } from 'formik';
import { debounce } from 'lodash';
import { Heading, Button } from '@hvccc/component-library';
import FormControl from '../../FormControl';
import Input from '../../Input';
import Select from '../../Select';

import 'react-datepicker/dist/react-datepicker.css';

const FilterForm = styled(Form)`
  color: ${({ theme }) => theme.colors.white};
`;

const MainSection = styled.section`
  background-color: ${({ theme }) => theme.colors.shades[5]};
  ${({ theme }) => py(3, theme)};
  ${({ theme }) => px(4, theme)};
`;

const HeadingSection = styled.div`
  display: flex;
`;

const ClearFiltersButton = styled(Button)`
  margin-left: auto;
`;

const CategoryTitle = styled(Heading)`
  color: ${({ theme }) => theme.colors.white};
  margin: 0;
`;

const CategoryDescription = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 27px;
`;

const FiltersSection = styled.section`
  background-color: ${({ theme }) => theme.colors.shades[6]};
  ${({ theme }) => pb(3, theme)};
  ${({ theme }) => px(4, theme)};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const Fieldset = styled.div`
  background-color: ${({ theme }) => theme.colors.shades[5]};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 0 15px 15px 15px;
  ${mr(3)};
  ${mt(3)};
  display: flex;
  flex-wrap: wrap;
`;

const SearchInput = styled(Input)`
  width: 300px;
  margin-bottom: 0;
`;

const SelectField = styled(Select)`
  width: 150px;
  margin-bottom: 0;
`;

const DateInput = styled(Input)`
  width: 160px;
  margin-bottom: 0;
`;
class Filters extends Component {
  constructor(props) {
    super(props);

    // Create filter list values
    this.SELECT_REPORT_TYPE = [
      { value: '', label: 'Any' },
      { value: 'Daily', label: 'Daily' },
      { value: 'Monthly', label: 'Monthly' },
      { value: 'Annual', label: 'Annual' },
    ];
  }

  render() {
    const { setReportCriteria, initialValues } = this.props;
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          // Check if we have a previousSubmitDebounce & cancel it
          if (this.state && this.state.previousSubmitDebounce)
            this.state.previousSubmitDebounce.cancel();

          // Debouce the setReportCriteria call
          const debounceMs = 200;
          const debouncedSetReportCriteria = debounce(
            () =>
              setReportCriteria({
                type: values.type,
                dateStart: values.dateStart,
                dateEnd: values.dateEnd,
                title: values.title,
              }),
            debounceMs,
          );
          // Save this debounce call into state (so we can cancel it if necessary)
          this.setState({ previousSubmitDebounce: debouncedSetReportCriteria });
          // Trigger the debounce call
          debouncedSetReportCriteria();
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          setFieldValue,
          setValues,
          submitForm,
        }) => {
          // DatePicker is a custom control, and we need to proxy its change event.
          const handleDateChange = (field, date) => {
            const formattedDate = date
              ? date.getFullYear() +
                '-' +
                appendLeadingZeroes(date.getMonth() + 1) +
                '-' +
                appendLeadingZeroes(date.getDate())
              : null;
            setFieldValue(field, formattedDate);
            submitForm();
          };

          const appendLeadingZeroes = (n) => {
            if (n <= 9) {
              return '0' + n;
            }
            return n;
          };

          const handleReset = () => {
            setValues({
              title: '',
              type: '',
              dateStart: null,
              dateEnd: null,
            });
            submitForm();
          };

          return (
            <FilterForm onChange={handleSubmit}>
              <MainSection>
                <HeadingSection>
                  <CategoryTitle size={3} text={this.props.title} />
                  <ClearFiltersButton
                    text="Clear filters"
                    iconRight="far fa-times"
                    variant="reports-filter"
                    role="button"
                    onClick={() => handleReset()}
                  >
                    Reset
                  </ClearFiltersButton>
                </HeadingSection>
                <CategoryDescription>
                  {this.props.description}
                </CategoryDescription>
              </MainSection>
              <FiltersSection>
                <Fieldset>
                  <FormControl
                    htmlFor="titleSearch"
                    labelText="Search reports"
                    variant="report-filter"
                  >
                    <Field
                      id="titleSearch"
                      type="text"
                      name="title"
                      value={values.title}
                      component={SearchInput}
                      variant="search"
                      placeholder="Search Documents Title"
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl
                    htmlFor="type"
                    labelText="Reporting period"
                    variant="report-filter"
                  >
                    <Field
                      id="type"
                      name="type"
                      value={values.type}
                      component={SelectField}
                      onChange={handleChange}
                    >
                      {this.SELECT_REPORT_TYPE.map((item, id) => (
                        <option key={id} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </Field>
                  </FormControl>
                </Fieldset>

                <Fieldset>
                  <FormControl
                    htmlFor="dateStart"
                    labelText="Date from"
                    variant="report-filter"
                  >
                    <div>
                      <DatePicker
                        id="dateStart"
                        name="dateStart"
                        customInput={<DateInput variant="calendar" />}
                        placeholderText="dd/mm/yyyy"
                        dateFormat="dd/MM/yyyy"
                        selected={
                          values.dateStart ? new Date(values.dateStart) : null
                        }
                        maxDate={
                          values.dateEnd ? new Date(values.dateEnd) : null
                        }
                        isClearable={true}
                        onChange={(value, e) =>
                          handleDateChange('dateStart', value)
                        }
                      />
                    </div>
                  </FormControl>
                  <FormControl
                    htmlFor="dateEnd"
                    labelText="Date to"
                    variant="report-filter"
                  >
                    <div>
                      <DatePicker
                        id="dateEnd"
                        name="dateEnd"
                        customInput={<DateInput variant="calendar" />}
                        placeholderText="dd/mm/yyyy"
                        dateFormat="dd/MM/yyyy"
                        selected={
                          values.dateEnd ? new Date(values.dateEnd) : null
                        }
                        minDate={
                          values.dateStart ? new Date(values.dateStart) : null
                        }
                        isClearable={true}
                        onChange={(val) => handleDateChange('dateEnd', val)}
                      />
                    </div>
                  </FormControl>
                </Fieldset>
              </FiltersSection>
            </FilterForm>
          );
        }}
      </Formik>
    );
  }
}

const propTypes = {
  setReportCriteria: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

Filters.propTypes = propTypes;

export default Filters;
