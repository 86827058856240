import React, { useEffect } from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import store from './redux/store';
import history from './utils/history';
import Routes from './components/Routes';
import Layout from './components/Layout';
import { gtmId } from './clientConfig';
import theme from './theme';
import { useMsalAuthentication } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { AuthenticatedTemplate } from '@azure/msal-react';

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
  }

  *, *::before, *::after {
    box-sizing: inherit
  }

  body {
    margin: 0;
    font-family: ${({ theme }) => theme.fonts.copy};
    font-size: ${({ theme }) => theme.copy.baseFontSize};
    line-height: normal;
    font-weight: 400;
    color: #333;
  }

  .d-none {
    display: none;
  }
  .menu-link {}
  .has-submenu {}

  .grid-sizer,
  .col-1 {
    width: 33.3333333%;
  }
  .col-2 {
    width: 66.6666666%;
  }

  button {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
`;

const App = () => {
  useMsalAuthentication(InteractionType.Redirect);

  useEffect(() => {
    TagManager.initialize({ gtmId });
  }, []);

  return (
    <Provider store={store}>
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <AuthenticatedTemplate>
            <React.Fragment>
              <GlobalStyle />
              <Layout>
                <Routes />
              </Layout>
            </React.Fragment>
          </AuthenticatedTemplate>
        </ThemeProvider>
      </Router>
    </Provider>
  );
};

export default App;
