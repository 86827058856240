import { Button } from '@hvccc/component-library';
import { bindActionCreators } from 'redux';
import {
  deleteMember,
  cancelDeletingMember,
} from '../../../redux/members/actions';
import { isMemberDeletingSelector } from '../../../redux/members/selectors';
import { connect } from 'react-redux';
import loader from '../../../assets/images/loader.gif';

function ConfirmDelete(props) {
  function deleteMember() {
    props.deleteMember(props.memberId);
  }

  function cancelDelete() {
    props.cancelDeletingMember(props.memberId);
  }

  const isDeleting = props.isMemberDeleting(props.memberId);
  const confirmButtonProps = {
    disabled: isDeleting,
    text: isDeleting ? null : 'Confirm',
    image: isDeleting ? loader : null,
  };

  return (
    <div className="confirm-message-container">
      <h4>Delete member</h4>
      <p className="text">Do you really want to delete this member?</p>
      <div className="buttons-block">
        <Button
          variant="outline"
          text="Cancel"
          onClick={cancelDelete}
          disabled={isDeleting}
        />
        <Button {...confirmButtonProps} onClick={deleteMember} />
      </div>
    </div>
  );
}

const dispatchMap = (dispatch) => ({
  ...bindActionCreators(
    {
      deleteMember,
      cancelDeletingMember,
    },
    dispatch,
  ),
});

const selectorsMap = (state) => ({
  isMemberDeleting: isMemberDeletingSelector.bind(null, state),
});

export default connect(selectorsMap, dispatchMap)(ConfirmDelete);
