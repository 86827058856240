import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const IconWrapper = styled.span`
  font-size: 28px;
`;
const MenuLabel = styled.span`
  font-size: 8px;
  text-align: center;
  text-transform: uppercase;
`;

const StyledMenuItem = styled.div`
  .menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 90px;
    padding: 10px 5px;
    color: ${({ theme }) => theme.colors.darkGrey};
    background: transparent;
    transition: all 0.3s;
    text-decoration: none;

    &:before {
      content: '';
      opacity: 0;
      transition: all 0.3s;
    }

    :hover,
    :active,
    &.submenu-open {
      background-color: ${({ theme }) => theme.colors.disabled};

      &.sub-menu:before {
        content: '';
        width: 12px;
        height: 12px;
        position: absolute;
        display: block;
        background-color: ${({ theme }) => theme.colors.disabled};
        transform: rotate(45deg);
        right: -6px;
        opacity: 1;
      }
    }

    &.active,
    &.active:hover {
      color: ${({ theme }) => theme.colors.primary};
      background-color: ${({ theme }) => theme.colors.white};
      text-decoration: none;
      border-top: 1px solid #eaeaea;
      border-bottom: 1px solid #eaeaea;

      &:after {
        content: '';
        width: 6px;
        height: 100%;
        display: block;
        background-color: ${({ theme }) => theme.colors.primary};
        right: 0;
        position: absolute;
        top: 0;
        box-shadow: -6px 0px 0px 0px rgba(255, 255, 255, 1);
      }

      &:before {
        opacity: 0;
        transform: rotate(45deg);
        transition: all 0.3s;
      }

      &.submenu-open:before {
        background-color: ${({ theme }) => theme.colors.primary};
        opacity: 1;
      }
    }
  }
`;

class MenuItem extends React.Component {
  render() {
    const {
      to,
      href,
      label,
      icon,
      handleMouseEnter,
      handleMouseLeave,
      isActive,
      subMenu,
    } = this.props;

    if (to) {
      return (
        <StyledMenuItem>
          <Link
            to={to}
            className={`menu-item menu-link
              ${isActive ? 'active' : ''}
              ${subMenu ? 'sub-menu' : ''}
              ${subMenu === 'open' ? 'submenu-open' : ''}
            `}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <IconWrapper>{icon}</IconWrapper>
            <MenuLabel>{label}</MenuLabel>
          </Link>
        </StyledMenuItem>
      );
    } else {
      if (href) {
        return (
          <StyledMenuItem>
            <a
              className="menu-item menu-link"
              href={href}
              target="_blank"
              rel="noopener noreferrer"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <IconWrapper>{icon}</IconWrapper>
              <MenuLabel>{label}</MenuLabel>
            </a>
          </StyledMenuItem>
        );
      } else {
        return (
          <StyledMenuItem>
            <span
              className={`menu-item menu-link
                ${isActive ? 'active' : ''}
                ${subMenu ? 'sub-menu' : ''}
                ${subMenu === 'open' ? 'submenu-open' : ''}
              `}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <IconWrapper>{icon}</IconWrapper>
              <MenuLabel>{label}</MenuLabel>
            </span>
          </StyledMenuItem>
        );
      }
    }
  }
}

export default MenuItem;
