import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Heading } from '@hvccc/component-library';

export default styled(Heading)`
  font-size: 2.125rem;
  margin: 0;

  ${breakpoint('lg')`
    font-size: 3rem;
  `};
`;
