import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const PageContainer = styled.div`
  padding: 20px;

  ${breakpoint('xl')`
    padding: 40px;
  `};
`;

export const HeaderSection = styled.div`
  padding: 40px 40px 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #d2d2d2;
`;

export const Box = styled.div`
  padding: 25px;
  background: #fff;
  min-height: 250px;
  margin-bottom: 20px;
`;

export const Text = styled.span`
  display: inline-block;
  margin-bottom: 20px;
`;

export const Caption = styled.div`
  text-transform: uppercase;
  font-size: ${(props) => (props.md ? '12px' : '10px')};
  color: ${(props) => (props.gray ? '#777' : '#333')};
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    display: inline-block;
  }
`;
