import {
  TOGGLE_SIDE_BAR_OPEN,
  FETCH_CURRENT_PATH_NAME,
  SET_CURRENT_PATH_NAME,
  FETCH_SITE_LAYOUT_REQUESTED,
} from './actionTypes';

export const toggleSideBarOpen = () => ({
  type: TOGGLE_SIDE_BAR_OPEN,
});

export const fetchCurrentPathName = () => ({
  type: FETCH_CURRENT_PATH_NAME,
});

export const setCurrentPathName = (pathName) => ({
  type: SET_CURRENT_PATH_NAME,
  payload: pathName,
});

export const fetchSiteLayout = () => ({
  type: FETCH_SITE_LAYOUT_REQUESTED,
});
