import {
  notificationsByPageLoadingSelector,
  notificationsByPageDataSelector,
  notificationsPageSelector,
  notificationTotalSelector,
} from '../../redux/notification/selectors';

export default (state) => ({
  notifications: notificationsByPageDataSelector(state),
  isLoading: notificationsByPageLoadingSelector(state),
  paginationData: notificationsPageSelector(state),
  total: notificationTotalSelector(state),
});
