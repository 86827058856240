import { generateApiUrl } from '../clientConfig';
import { requestAuthorized } from './index';

const membersApiUrl = '/api/members';
const organisationsApiUrl = '/api/organisations/managed';
const membersAuditLogsUrl = '/api/audit';

export const createNewMemberApi = async (member) => {
  const response = await requestAuthorized({
    url: generateApiUrl(membersApiUrl),
    method: 'post',
    data: member,
  });

  return response.data;
};

export const validateEmailAddressApi = (email) => {
  return requestAuthorized({
    url: generateApiUrl(`${membersApiUrl}/email`),
    method: 'post',
    data: {
      email,
    },
  });
};

export const validateVesselPublishApi = async () => {
  return requestAuthorized({
    url: generateApiUrl(`${membersApiUrl}/can-vessel-publish`),
    method: 'get',
  });
};

export const getAllMembersApi = async () => {
  const url = generateApiUrl(organisationsApiUrl);

  const response = await requestAuthorized({
    url: url,
    method: 'get',
  });

  return response.data;
};

export const getAllAuditLogsApi = async () => {
  const url = generateApiUrl(membersAuditLogsUrl);

  const response = await requestAuthorized({
    url: url,
    method: 'get',
  });

  return response.data;
};

export const updateMemberApi = (id, model) => {
  const url = generateApiUrl(`${membersApiUrl}/${id}`);

  return requestAuthorized({
    url: url,
    method: 'patch',
    data: model,
  });
};

const pendingUpdate = [];
export const updateMemberMiddleware = async (id, model) => {
  const copy = [...pendingUpdate];

  let resFn;
  const p = new Promise((resolve) => {
    resFn = resolve;
  });
  pendingUpdate.push(p);

  let task = copy.shift();
  while (task) {
    await task;
    task = copy.shift();
  }

  try {
    const data = await updateMemberApi(id, model);
    resFn();
    return data;
  } catch (ex) {
    resFn();
    throw new Error(ex.response.data);
  }
};

export const deleteMemberApi = (id) => {
  const url = generateApiUrl(`${membersApiUrl}/${id}`);

  return requestAuthorized({
    url: url,
    method: 'delete',
  });
};
