import { withVariants } from '@hvccc/component-library';
import { Button } from '@hvccc/component-library';
import ReportPaginationButton from './ReportPaginationButton';

const variants = {
  standard: Button,
  'reports-pagination': ReportPaginationButton,
};

export default withVariants(variants)(Button);
