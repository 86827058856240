import { call, put, takeLatest, select, fork, all } from 'redux-saga/effects';
import { delay } from 'redux-saga/effects';
import {
  notificationsReadySelector,
  notificationsDataSelector,
  notificationsByPageReadySelector,
  notificationsByPageDataSelector,
  notificationsPageSelector,
} from './selectors';
import {
  NOTIFICATIONS_FETCH_REQUESTED,
  NOTIFICATIONS_FETCH_SUCCEEDED,
  NOTIFICATIONS_FETCH_FAILED,
  NOTIFICATION_MARK_AS_READ,
  NOTIFICATION_MARK_AS_UNREAD,
  NOTIFICATIONS_FETCH_BY_PAGE_REQUESTED,
  NOTIFICATIONS_FETCH_BY_PAGE_SUCCEEDED,
  NOTIFICATIONS_FETCH_BY_PAGE_FAILED,
} from './actionTypes';
import {
  fetchNotifications as apiFetchNotifications,
  fetchNotificationsByPage as apiFetchNotificationsByPage,
  markNotificationAsRead as apiMarkNotificationAsRead,
} from '../../api/notification';
import { objectToQueryString, setQueryString } from '../../utils/queryString';

// ----------------------------------
// WORKERS
// ----------------------------------
function* fetchNotifications() {
  const notificationsReady = yield select(notificationsReadySelector);

  try {
    const notifications = notificationsReady
      ? yield select(notificationsDataSelector)
      : yield call(apiFetchNotifications);

    yield delay(2000);
    yield put({
      type: NOTIFICATIONS_FETCH_SUCCEEDED,
      payload: notifications,
    });
  } catch (error) {
    yield put({
      type: NOTIFICATIONS_FETCH_FAILED,
      payload: error,
    });
  }
}

function* fetchNotificationsByPage() {
  const notificationsByPageReady = yield select(
    notificationsByPageReadySelector,
  );
  const notificationsPage = yield select(notificationsPageSelector);

  try {
    const notifications = notificationsByPageReady
      ? yield select(notificationsByPageDataSelector)
      : yield call(apiFetchNotificationsByPage, notificationsPage.page);

    yield delay(2000);
    yield put({
      type: NOTIFICATIONS_FETCH_BY_PAGE_SUCCEEDED,
      payload: notifications,
    });
  } catch (error) {
    yield put({
      type: NOTIFICATIONS_FETCH_BY_PAGE_FAILED,
      payload: error,
    });
  }
}

export function* setNotificationsPageNumber() {
  const notificationsPage = yield select(notificationsPageSelector);

  const criteriaQueryString = yield call(
    objectToQueryString,
    notificationsPage,
  );
  yield call(setQueryString, criteriaQueryString);
}

export function* markNotificationAsRead(action) {
  if (action.payload && action.payload.read) return;
  try {
    yield call(apiMarkNotificationAsRead, action.payload.id);
  } catch (err) {
    yield put({
      type: NOTIFICATION_MARK_AS_UNREAD,
      payload: action.payload,
    });
  }
}

// ----------------------------------
// WATCHERS
// ----------------------------------
export function* watchFetchNotifications() {
  yield takeLatest(NOTIFICATIONS_FETCH_REQUESTED, fetchNotifications);
}

export function* watchFetchNotificationsByPage() {
  yield takeLatest(
    NOTIFICATIONS_FETCH_BY_PAGE_REQUESTED,
    fetchNotificationsByPage,
  );
  yield takeLatest(
    NOTIFICATIONS_FETCH_BY_PAGE_REQUESTED,
    setNotificationsPageNumber,
  );
}

export function* watchChangeNoticeReadState() {
  // yield takeLatest(SET_NOTIFICATION_READ_STATE, fetchNotifications);
  yield takeLatest(NOTIFICATION_MARK_AS_READ, markNotificationAsRead);
}

export default function* () {
  yield all([
    fork(watchFetchNotifications),
    fork(watchFetchNotificationsByPage),
    fork(watchChangeNoticeReadState),
  ]);
}
