import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { m, mt, mb } from 'styled-components-spacing';
import Button from '../../Button';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  ${({ theme }) => mt(4, theme)};
  ${({ theme }) => mb(6, theme)};
`;

const ButtonPrev = styled(Button)`
  ${m(0)};
  border-radius: ${({ theme }) =>
    `${theme.borderRadius} 0 0 ${theme.borderRadius}`};
`;

const ButtonNext = styled(Button)`
  ${m(0)};
  border-radius: ${({ theme }) =>
    `0 ${theme.borderRadius} ${theme.borderRadius} 0`};
`;

const Input = styled.input`
  font-size: ${({ theme }) => theme.typeScale['3']};
  text-align: center;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  font-weight: bold;
  z-index: 2;
  width: 70px;
  ${({ theme }) => theme.fonts.copy};
  ${({ theme }) => theme.boxGlow};

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Pagination = ({ className, currentPage, totalPages, onNavigate }) => (
  <Wrapper className={className}>
    <ButtonPrev
      disabled={currentPage - 1 < 1}
      aria-label="Previous page"
      onClick={(e) => onNavigate(e, currentPage - 1)}
      icon="fas fa-caret-left"
    />
    <Input
      type="number"
      min={1}
      max={totalPages}
      value={currentPage}
      onChange={(e) => onNavigate(e, e.target.value)}
    />
    <ButtonNext
      disabled={currentPage + 1 > totalPages}
      aria-label="Next page"
      onClick={(e) => onNavigate(e, currentPage + 1)}
      icon="fas fa-caret-right"
    />
  </Wrapper>
);

const propTypes = {
  className: PropTypes.string,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
};

const defaultProps = {
  className: '',
};

Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;

export default Pagination;
