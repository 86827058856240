import {
  isSideBarOpenSelector,
  currentPathNameSelector,
} from '../../../redux/app/selectors';
import { userProfileDataSelector } from '../../../redux/user/selectors';

export default (state) => ({
  open: isSideBarOpenSelector(state),
  userProfile: userProfileDataSelector(state),
  currentPathName: currentPathNameSelector(state),
});
