import {
  membersSelector,
  managedOrganisationsSelector,
  isTableLoaderVisibleSelector,
  isMemberUpdatingSelector,
  isMemberEditingSelector,
  isMemberPendingDeleteSelector,
  managedOrganisationsDomainsSelector,
} from '../../../redux/members/selectors';

const map = (state) => ({
  members: membersSelector(state),
  organisations: managedOrganisationsSelector(state),
  organisationsDomains: managedOrganisationsDomainsSelector(state),
  isTableLoaderVisible: isTableLoaderVisibleSelector(state),
  isMemberUpdating: isMemberUpdatingSelector.bind(null, state),
  isMemberEditing: isMemberEditingSelector.bind(null, state),
  isMemberPendingDelete: isMemberPendingDeleteSelector.bind(null, state),
});

export default map;
