import styled from 'styled-components';
import { FormControl } from '@hvccc/component-library';

const ReportFilterControl = styled(FormControl)`
  text-transform: uppercase;
  padding: 0 15px;
  margin-top: 15px;
`;

export default ReportFilterControl;
