import { call, put, takeLatest, select, fork, all } from 'redux-saga/effects';
import {
  standardPageReadySelector,
  standardPageDataSelector,
} from './selectors';

import {
  STANDARD_PAGE_FETCH_REQUESTED,
  STANDARD_PAGE_FETCH_SUCCEEDED,
  STANDARD_PAGE_FETCH_FAILED,
} from './actionTypes';

import { default as apiFetchStandardPage } from '../../api/standardPage';

// ----------------------------------
// WORKERS
// ----------------------------------
function* fetchStandardPage({ payload: { category, slug } }) {
  const standardPageReady = yield select(standardPageReadySelector);

  const { error, data } = standardPageReady
    ? yield select(standardPageDataSelector)
    : yield call(apiFetchStandardPage, category, slug);

  if (error) {
    yield put({
      type: STANDARD_PAGE_FETCH_FAILED,
      payload: error,
    });
  } else {
    yield put({
      type: STANDARD_PAGE_FETCH_SUCCEEDED,
      payload: data,
    });
  }
}

// ----------------------------------
// WATCHERS
// ----------------------------------
export function* watchFetchStandardPage() {
  yield takeLatest(STANDARD_PAGE_FETCH_REQUESTED, fetchStandardPage);
}

export default function* () {
  yield all([fork(watchFetchStandardPage)]);
}
