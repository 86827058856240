import { Radio, Space } from 'antd';
import React from 'react';

export default class MemberRadioButtonList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedMemberId: this.props.selectedMemberId,
      memberList: this.props.memberList,
    };

    this.onMemberSelection = this.onMemberSelection.bind(this);
  }

  onMemberSelection(event) {
    const selectedMember = this.state.memberList.find(
      (member) => member.id.toString() === event.target.value,
    );
    this.setState({
      selectedMember: selectedMember.id,
    });
    this.props.onMemberSelection(selectedMember);
  }

  render() {
    return this.state.memberList.length > 0 ? (
      <div className="radioButtonBorderBox">
        <div className="radioButtonBox">
          <Radio.Group
            onChange={this.onMemberSelection}
            defaultValue={this.state.selectedMemberId}
          >
            <Space direction="vertical">
              {this.state.memberList?.map((member) => (
                <Radio key={member.id} value={member.id.toString()}>
                  {member.displayName}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </div>
      </div>
    ) : (
      <div className="errorMessage">No users to select from</div>
    );
  }
}
