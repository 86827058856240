import { bindActionCreators } from 'redux';
import { setCurrentPathName } from '../../redux/app/actions';
import { openMemberModal, fetchAllMembers } from '../../redux/members/actions';

const map = (dispatch) => ({
  ...bindActionCreators(
    {
      setCurrentPathName,
      openMemberModal,
      fetchAllMembers,
    },
    dispatch,
  ),
});

export default map;
