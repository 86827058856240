import React from 'react';
import styled from 'styled-components';
import { my, mx } from 'styled-components-spacing';
import breakpoint from 'styled-components-breakpoint';
import TextPanel from '../TextPanel';
import PicturePanel from '../PicturePanel';
import Table from '../Table';
import Video from '../Video';

const StyledVideoWrapper = styled.div`
  display: flex;
  justify-content: center;
  ${({ theme }) => my(4, theme)};

  ${breakpoint('xl')`
    ${({ theme }) => mx(6, theme)};
  `} & > div {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
    width: 100%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`;

export default ({ type, style, ...panel }) => {
  switch (type) {
    case 'textPanel':
      return <TextPanel {...panel} variant={style} />;

    case 'table':
      return <Table {...panel} />;

    case 'video':
      return (
        <StyledVideoWrapper>
          <Video {...panel} />
        </StyledVideoWrapper>
      );

    case 'picturePanel':
      return <PicturePanel variant="standard" {...panel} />;

    default:
      return null;
  }
};
