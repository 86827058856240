import React from 'react';
import styled from 'styled-components';
import ContentWrapper from '../../ContentWrapper';
import { px, pb, pt, mt, mb } from 'styled-components-spacing';
import Checkbox from '../../Members/Checkbox';
import FormControl from '../../FormControl';
import { Button } from '@hvccc/component-library';
import './Subscription.scss';
import { updateActions, updateSubjects } from '../../../models/members';
import loader from '../../../assets/images/loader.gif';

const StyledHeader = styled.p`
  font-weight: 700;
  font-size: 30px;
  line-height: 32px;
  color: #222222;
  font-family: 'bebas-neue-by-fontfabric', 'Helvetica Neue', 'Helvetica',
    sans-serif;
  text-transform: uppercase;
  margin: 0;
`;

const StyledContentWrapper = styled(ContentWrapper)`
  max-width: 600px;
  width: 100%;
  ${({ theme }) => px(3, theme)};
  ${({ theme }) => pt(1, theme)};
  ${({ theme }) => pb(3, theme)};
  ${({ theme }) => mb(3, theme)};
  ${({ theme }) => mt(3, theme)};
`;

class Subscription extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDailySMSRecipient: this.props.subscriptions.isDailySMSRecipient,
      isCapPublishSubscriber: this.props.subscriptions.isCapPublishSubscriber,
      isRailPublishSubscriber: this.props.subscriptions.isRailPublishSubscriber,
      isVesselPublishSubscriber:
        this.props.subscriptions.isVesselPublishSubscriber,
      isMaintenancePublishSubscriber:
        this.props.subscriptions.isMaintenancePublishSubscriber,
    };

    this.isDailySMSRecipient = this.props.subscriptions.isDailySMSRecipient;
    this.isCapPublishSubscriber =
      this.props.subscriptions.isCapPublishSubscriber;
    this.isRailPublishSubscriber =
      this.props.subscriptions.isRailPublishSubscriber;
    this.isVesselPublishSubscriber =
      this.props.subscriptions.isVesselPublishSubscriber;
    this.isMaintenancePublishSubscriber =
      this.props.subscriptions.isMaintenancePublishSubscriber;
  }

  onSMSsendingChange(event) {
    const value = this.state.isDailySMSRecipient;

    this.setState({
      isDailySMSRecipient: !value,
    });
  }

  onCapPublishChange(event) {
    const value = this.state.isCapPublishSubscriber;

    this.setState({
      isCapPublishSubscriber: !value,
    });
  }

  onRailPublishChange(event) {
    const value = this.state.isRailPublishSubscriber;

    this.setState({
      isRailPublishSubscriber: !value,
    });
  }

  onVesselPublishChange(event) {
    const value = this.state.isVesselPublishSubscriber;

    this.setState({
      isVesselPublishSubscriber: !value,
    });
  }

  onMaintenancePublishChange(event) {
    const value = this.state.isMaintenancePublishSubscriber;

    this.setState({
      isMaintenancePublishSubscriber: !value,
    });
  }

  save() {
    const actions = this.calculateActions();
    debugger; // eslint-disable-line no-debugger
    this.props.updateUserSubscriptions(this.props.userProfile.id, {
      actions,
    });

    this.isDailySMSRecipient = this.state.isDailySMSRecipient;
    this.isCapPublishSubscriber = this.state.isCapPublishSubscriber;
    this.isRailPublishSubscriber = this.state.isRailPublishSubscriber;
    this.isVesselPublishSubscriber = this.state.isVesselPublishSubscriber;
    this.isMaintenancePublishSubscriber =
      this.state.isMaintenancePublishSubscriber;
  }

  calculateActions() {
    const actions = {};

    if (this.isDailySMSRecipient !== this.state.isDailySMSRecipient) {
      actions[updateSubjects.dailySMSRecipient] = this.state.isDailySMSRecipient
        ? updateActions.add
        : updateActions.delete;
    }

    if (this.isCapPublishSubscriber !== this.state.isCapPublishSubscriber) {
      actions[updateSubjects.capPublishRecipient] = this.state
        .isCapPublishSubscriber
        ? updateActions.add
        : updateActions.delete;
    }

    if (this.isRailPublishSubscriber !== this.state.isRailPublishSubscriber) {
      actions[updateSubjects.railPublishRecipient] = this.state
        .isRailPublishSubscriber
        ? updateActions.add
        : updateActions.delete;
    }

    if (
      this.isVesselPublishSubscriber !== this.state.isVesselPublishSubscriber
    ) {
      actions[updateSubjects.vesselPublishRecipient] = this.state
        .isVesselPublishSubscriber
        ? updateActions.add
        : updateActions.delete;
    }

    if (
      this.isMaintenancePublishSubscriber !==
      this.state.isMaintenancePublishSubscriber
    ) {
      debugger; // eslint-disable-line no-debugger
      actions[updateSubjects.maintenancePublishRecipient] = this.state
        .isMaintenancePublishSubscriber
        ? updateActions.add
        : updateActions.delete;
    }

    return actions;
  }

  getSaveButtonProps(isUpdating) {
    return {
      image: isUpdating ? loader : null,
      text: isUpdating ? 'Updating...' : 'Update Subscriptions',
      disabled: isUpdating,
    };
  }

  render() {
    const isLoading = this.props.isLoading;
    const isValidVesselPublish =
      this.props.userProfile.email.endsWith('pwcs.com.au') ||
      this.props.userProfile.email.endsWith('hvccc.com.au') ||
      this.props.userProfile.email.endsWith('portauthoritynsw.com.au') ||
      this.props.userProfile.email.endsWith('portofnewcastle.com.au');
    const isUpdating = this.props.isMemberUpdating(this.props.userProfile.id);
    const saveButtonProps = this.getSaveButtonProps(isUpdating);

    return (
      <div className="subscription-form">
        <StyledHeader className="subscription-heading">
          Subscriptions
        </StyledHeader>
        {isLoading ? <div className="loading-overlay"></div> : null}
        <StyledContentWrapper>
          <FormControl
            className="member-form-control checkbox"
            htmlFor="subscribeToDailySMS"
          >
            <Checkbox
              size="large"
              checked={this.state.isDailySMSRecipient}
              onChange={this.onSMSsendingChange.bind(this)}
            >
              Daily SMS
            </Checkbox>
          </FormControl>
          <FormControl
            className="member-form-control checkbox"
            htmlFor="subscribeToCAPPublish"
          >
            <Checkbox
              size="large"
              checked={this.state.isCapPublishSubscriber}
              onChange={this.onCapPublishChange.bind(this)}
            >
              CAP Publish
            </Checkbox>
          </FormControl>
          <FormControl
            className="member-form-control checkbox"
            htmlFor="subscribeToRailPublish"
          >
            <Checkbox
              size="large"
              checked={this.state.isRailPublishSubscriber}
              onChange={this.onRailPublishChange.bind(this)}
            >
              Rail Publish
            </Checkbox>
          </FormControl>
          {isValidVesselPublish ? (
            <FormControl
              className="member-form-control checkbox"
              htmlFor="subscribeToVesselPublish"
            >
              <Checkbox
                size="large"
                checked={this.state.isVesselPublishSubscriber}
                disabled={!isValidVesselPublish}
                onChange={this.onVesselPublishChange.bind(this)}
              >
                Vessel Publish
              </Checkbox>
            </FormControl>
          ) : null}
          <FormControl
            className="member-form-control checkbox"
            htmlFor="subscribeToMaintenancePublish"
          >
            <Checkbox
              size="large"
              checked={this.state.isMaintenancePublishSubscriber}
              onChange={this.onMaintenancePublishChange.bind(this)}
            >
              Maintenance Publish
            </Checkbox>
          </FormControl>
          <Button
            className="btn-submit"
            onClick={this.save.bind(this)}
            {...saveButtonProps}
          />
        </StyledContentWrapper>
      </div>
    );
  }
}

export default Subscription;
