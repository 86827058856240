export const OPEN_MEMBER_MODAL = 'OPEN_MEMBER_MODAL';
export const CLOSE_MEMBER_MODAL = 'CLOSE_MEMBER_MODAL';
export const CREATE_NEW_MEMBER = 'CREATE_NEW_MEMBER';

export const START_CREATING_NEW_MEMBER = 'START_CREATING_NEW_MEMBER';
export const COMPLETE_CREATING_NEW_MEMBER = 'COMPLETE_CREATING_NEW_MEMBER';

export const FETCH_ALL_MEMBERS = 'FETCH_ALL_MEMBERS';
export const START_FETCHING_MEMBERS = 'START_FETCHING_MEMBERS';
export const COMPLETE_FETCHING_MEMBERS = 'COMPLETE_FETCHING_MEMBERS';

export const START_UPDATING_MEMBER = 'START_UPDATING_MEMBER';
export const UPDATE_MEMBER = 'UPDATE_MEMBER';
export const COMPLETE_UPDATING_MEMBER = 'COMPLETE_UPDATING_MEMBER';
export const COMPLETE_UPDATING_MEMBER_ERROR = 'COMPLETE_UPDATING_MEMBER_ERROR';

export const START_DELETING_MEMBER = 'START_DELETING_MEMBER';
export const DELETE_MEMBER = 'DELETE_MEMBER';
export const COMPLETE_DELETING_MEMBER = 'COMPLETE_DELETING_MEMBER';
export const COMPLETE_DELETING_MEMBER_ERROR = 'COMPLETE_DELETING_MEMBER_ERROR';
export const CANCEL_DELETING_MEMBER = 'CANCEL_DELETING_MEMBER';
export const PREPARE_DELETING_MEMBER = 'PREPARE_DELETING_MEMBER';

export const START_EDITING_MEMBER = 'START_EDITING_MEMBER';
export const COMPLETE_EDITING_MEMBER = 'COMPLETE_EDITING_MEMBER';

export const OPEN_MESSAGE_MODAL = 'OPEN_MESSAGE_MODAL';
export const CLOSE_MESSAGE_MODAL = 'CLOSE_MESSAGE_MODAL';

export const START_FETCHING_AUDIT_LOGS = 'START_FETCHING_AUDIT_LOGS';
export const COMPLETE_FETCHING_AUDIT_LOGS = 'COMPLETE_FETCHING_AUDIT_LOGS';
export const FETCH_ALL_AUDIT_LOGS = 'FETCH_ALL_AUDIT_LOGS';
