import { userProfileDataSelector } from '../../redux/user/selectors';
import {
  managedOrganisationsSelector,
  managedOrganisationsDomainsSelector,
} from '../../redux/members/selectors';

const map = (state) => ({
  userProfile: userProfileDataSelector(state),
  managedOrganisations: managedOrganisationsSelector(state),
  managedOrganisationsDomains: managedOrganisationsDomainsSelector(state),
});

export default map;
