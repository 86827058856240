import React from 'react';
import { Button } from '@hvccc/component-library';
import exclamationMark from '../../../assets/images/error-triangle.svg';

function ErrorMessage(props) {
  return (
    <div className="error-block-container">
      <div className="title-container">
        <span className="title">
          <img src={exclamationMark} alt="error mark" />
          Error
        </span>
      </div>
      <div>{props.children}</div>
      <Button variant="outline" text="Close" onClick={props.closeModal} />
    </div>
  );
}

export default ErrorMessage;
