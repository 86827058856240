import React, { useState, useEffect } from 'react';
import './MembersTable.scss';
import Loading from '../../../libs/Loader';
import Input from '../../Input';
import MemberListItem from './MemberListItem';

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

function MemberTable(props) {
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce(searchQuery, 300);
  const isMultipleOrganisations = props.organisations.length > 1;
  const showOperationalReports = props.organisations.some(
    (o) => o == 'Pacific National',
  );

  useEffect(() => {
    const filteredMembers = props.members.filter((member) => {
      const { displayName, email, phoneNumber } = member;
      const lowerCaseQuery = searchQuery?.toLowerCase();

      return (
        displayName?.toLowerCase().includes(lowerCaseQuery) ||
        email?.toLowerCase().includes(lowerCaseQuery) ||
        phoneNumber?.toLowerCase()?.includes(lowerCaseQuery)
      );
    });

    setFilteredMembers(filteredMembers);
  }, [debouncedSearchQuery, props.members]);

  const [filteredMembers, setFilteredMembers] = useState([]);

  return (
    <div className="members-table-container">
      <div>
        <Input
          variant="search"
          className="searchInput"
          placeholder="Search by Name, Email, or Phone"
          onChange={(e) => setSearchQuery(e.target.value)}
          value={searchQuery}
        />
      </div>
      <div className="members-table">
        <div
          className={
            showOperationalReports
              ? 'row head display-operational-reports'
              : 'row head'
          }
        >
          <div>Name</div>
          <div>Email</div>
          <div className="phone">Phone</div>

          <div className="access">
            <div className="access-header">Access</div>

            <div
              className={
                showOperationalReports
                  ? 'access-subitems display-operational-reports'
                  : 'access-subitems'
              }
            >
              <div className="centered">Authoriser</div>
              {<div className="centered">IPS</div>}
              <div className="centered">CTS</div>
              <div className="centered">
                Member<br></br>Portal
              </div>
              {showOperationalReports && (
                <div className="centered">
                  Operational<br></br>Plan & Reports
                </div>
              )}
            </div>
          </div>

          <div className="subscription">
            <div className="subscription-header">Subscriptions</div>

            <div className="subscription-subitems">
              <div className="centered">
                SMS<br></br>Recipient
              </div>
              <div className="centered">
                CAP<br></br>Publish
              </div>
              <div className="centered">
                Rail<br></br>Publish
              </div>
              <div className="centered">
                Vessel<br></br>Publish
              </div>
              <div className="centered">
                Maintenance<br></br>Publish
              </div>
            </div>
          </div>

          <div></div>
        </div>
        {props.isTableLoaderVisible ? (
          <Loading />
        ) : (
          filteredMembers.map((member) => (
            <MemberListItem
              key={member.id + '-' + member.organisationName}
              {...member}
              authoriser={props.authoriser}
              organisations={props.organisations}
              managedOrganisationsDomains={props.managedOrganisationsDomains}
              memberList={props.members}
              onDelete={props.prepareDeletingMember}
              deleteWithoutConfirmation={props.deleteMember}
              onSave={props.updateMember}
              showOperationalReports={showOperationalReports}
              showOrganisationName={isMultipleOrganisations}
              isMemberUpdating={props.isMemberUpdating}
              isMemberEditing={props.isMemberEditing}
              isMemberPendingDelete={props.isMemberPendingDelete}
              onEdit={props.startEditingMember}
              onCancel={props.completeEditingMember}
            />
          ))
        )}
      </div>
    </div>
  );
}

export default MemberTable;
