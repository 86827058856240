import {
  isModalLoaderVisibleSelector,
  membersSelector,
  managedOrganisationsSelector,
} from '../../../redux/members/selectors';

const map = (state) => ({
  members: membersSelector(state),
  organisations: managedOrganisationsSelector(state),
  isLoading: isModalLoaderVisibleSelector(state),
});

export default map;
