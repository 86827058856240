import { bindActionCreators } from 'redux';
import { navigateCategory } from '../../../redux/category/actions';

export default (dispatch) => ({
  ...bindActionCreators(
    {
      navigate: navigateCategory,
    },
    dispatch,
  ),
});
