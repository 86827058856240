import history from './history';
import queryString from 'query-string';

export const setQueryString = (queryString) => {
  const currentLocation = history.location;
  let queryStringToAdd = queryString;
  if (queryStringToAdd != null && !queryStringToAdd.startsWith('?')) {
    queryStringToAdd = '?' + queryStringToAdd;
  }

  history.push({
    ...currentLocation,
    search: queryStringToAdd,
  });
};

export const objectToQueryString = (obj) => {
  return queryString.stringify(obj);
};

export const getObjectFromQueryString = () => {
  const location = history.location;
  return queryString.parse(location.search);
};
