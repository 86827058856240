import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loader from '../../libs/Loader';
import Error from '../Error';
import CategoryComponent from './Component';

class CategoryPage extends Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: { category },
      },
      history,
      currentPage,
      fetchCategory,
      setCurrentPathName,
    } = this.props;

    setCurrentPathName(history.location.pathname);
    fetchCategory(category, currentPage);
  }

  render() {
    const { error, isLoaded, category } = this.props;

    if (!isLoaded) {
      return <Loader />;
    }

    if (error) {
      return <Error {...error} />;
    }

    return <CategoryComponent {...category} />;
  }
}

const propTypes = {
  match: PropTypes.object.isRequired,
  currentPage: PropTypes.number,
  fetchCategory: PropTypes.func.isRequired,
  setCurrentPathName: PropTypes.func.isRequired,
};

CategoryPage.propTypes = propTypes;

export default CategoryPage;
