import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { px } from 'styled-components-spacing';
import Loading from '../../../libs/Loader';
import Error from '../../Error';

const CategoriesWrapper = styled.div`
  flex: 0 0 auto;
  max-width: 290px;
  border-right: 2px solid ${({ theme }) => theme.colors.shades[1]};
`;

const CategoryItem = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  position: relative;
  cursor: pointer;
  ${({ theme }) => px(3, theme)};
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.shades[1]};
  color: ${(props) =>
    props.selected
      ? ({ theme }) => theme.colors.primary
      : ({ theme }) => theme.colors.darkGrey};
  height: 75px;
  transition: all 0.2s;

  &:last-of-type {
    border-bottom: 0px;
  }

  &:before {
    content: '';
    display: block;
    width: 4px;
    height: 100%;
    position: absolute;
    background-color: ${({ theme }) => theme.colors.primary};
    right: -2px;
    top: 0;
    opacity: ${(props) => (props.selected ? 1 : 0)};
    transition: opacity 0.3s;
  }

  &:hover {
    background-color: ${(props) =>
      props.selected
        ? ({ theme }) => theme.colors.white
        : ({ theme }) => theme.colors.shades[1]};
  }
`;

class Categories extends Component {
  componentDidMount() {
    this.props.fetchReportCategories();
  }
  render() {
    const {
      reportCategories,
      selectedReportCategoryId,
      setReportCriteria,
      isLoading,
      error,
    } = this.props;

    return (
      <CategoriesWrapper>
        <CategoryItem
          selected={!selectedReportCategoryId}
          onClick={() =>
            setReportCriteria({ categoryId: undefined, page: undefined })
          }
        >
          All categories
        </CategoryItem>

        {isLoading && <Loading />}

        {error && <Error error={error} />}

        {reportCategories &&
          reportCategories.map((category, idx) => (
            <CategoryItem
              selected={selectedReportCategoryId === category.id}
              key={idx}
              onClick={() =>
                setReportCriteria({ categoryId: category.id, page: undefined })
              }
            >
              {category.title}
            </CategoryItem>
          ))}
      </CategoriesWrapper>
    );
  }
}

const propTypes = {
  className: PropTypes.string,
  fetchReportCategories: PropTypes.func.isRequired,
  reportCategories: PropTypes.array.isRequired,
  selectedReportCategory: PropTypes.object,
  isLoading: PropTypes.bool,
  setReportCriteria: PropTypes.func.isRequired,
};

const defaultProps = {
  className: '',
  reportCategories: [],
};

Categories.propTypes = propTypes;
Categories.defaultProps = defaultProps;

export default Categories;
