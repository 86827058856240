export const USER_PROFILE_FETCH_REQUESTED = 'USER_PROFILE_FETCH_REQUESTED';
export const USER_PROFILE_FETCH_SUCCEEDED = 'USER_PROFILE_FETCH_SUCCEEDED';
export const USER_PROFILE_FETCH_FAILED = 'USER_PROFILE_FETCH_FAILED';

export const USER_SUBSCRITION_UPDATE = 'USER_SUBSCRITION_UPDATE';
export const USER_SUBSCRITION_START_UPDATING =
  'USER_SUBSCRITION_START_UPDATING';
export const USER_SUBSCRITION_UPDATE_COMPLETE =
  'USER_SUBSCRITION_UPDATE_COMPLETE';
export const USER_SUBSCRITION_UPDATE_ERROR = 'USER_SUBSCRITION_UPDATE_ERROR';

export const USER_PROFILE_UPDATE = 'USER_PROFILE_UPDATE';
export const USER_PROFILE_START_UPDATING = 'USER_PROFILE_START_UPDATING';
export const USER_PROFILE_UPDATE_COMPLETE = 'USER_PROFILE_UPDATE_COMPLETE';
export const USER_PROFILE_UPDATE_ERROR = 'USER_PROFILE_UPDATE_ERROR';
export const START_EDITING_PROFILE = 'START_EDITING_PROFILE';
export const COMPLETE_EDITING_PROFILE = 'COMPLETE_EDITING_PROFILE';
