import {
  layoutHeaderFooterSelector,
  layoutLoadingSelector,
} from '../../redux/app/selectors';
import { userProfileDataSelector } from '../../redux/user/selectors';

export default (state) => ({
  layout: layoutHeaderFooterSelector(state),
  isLoading: layoutLoadingSelector(state),
  userProfile: userProfileDataSelector(state),
});
