import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Dashboard from '../Dashboard';
import Reports from '../Reports';
import User from '../User';
import AddressBook from '../AddressBook';
import NotificationsPage from '../NotificationsPage';
import CategoryPage from '../CategoryPage';
import StandardPage from '../StandardPage';
import Error from '../Error';
import Members from '../Members';
import { sideBar } from '../Layout/MenuItemDefinition';

export default class Routes extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route path="/reports" component={Reports} />
        <Route path="/user" component={User} />
        <Route path="/address-book" component={AddressBook} />
        <Route path="/notifications" component={NotificationsPage} />
        <Route path={sideBar.myMembers.path} component={Members} />
        <Route path="/:category/:slug" component={StandardPage} />
        <Route path="/:category" component={CategoryPage} />
        <Route
          path="/error"
          render={() => (
            <Error errorType={this.props.errorType} error={this.props.error} />
          )}
        />
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    );
  }
}
