import {
  //widgetsDataSelector,
  // widgetsLoadingSelector,
  powerBiEmbeddedDataSelector,
  powerBiEmbeddedLoadingSelector,
  powerBiEmbeddedLoadedSelector,
  powerBiEmbeddedIsLoadingError,
} from '../../redux/dashboard/selectors';
import { userProfileDataSelector } from '../../redux/user/selectors';

export default (state) => ({
  //widgets: widgetsDataSelector(state),
  powerBiEmbeddedConfig: powerBiEmbeddedDataSelector(state),
  //isLoading: widgetsLoadingSelector(state),
  isLoading: powerBiEmbeddedLoadingSelector(state),
  isLoaded: powerBiEmbeddedLoadedSelector(state),
  isLoadingError: powerBiEmbeddedIsLoadingError(state),
  userProfile: userProfileDataSelector(state),
});
