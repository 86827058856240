import {
  reportCriteriaTypeSelector,
  reportCriteriaDateStartSelector,
  reportCriteriaDateEndSelector,
  reportCriteriaTitle,
} from '../../../redux/reports/selectors';

export default (state) => ({
  initialValues: {
    type: reportCriteriaTypeSelector(state),
    dateStart: reportCriteriaDateStartSelector(state),
    dateEnd: reportCriteriaDateEndSelector(state),
    title: reportCriteriaTitle(state) || '',
  },
});
