import {
  WIDGETS_FETCH_REQUESTED,
  WIDGETS_FETCH_SUCCEEDED,
  WIDGETS_FETCH_FAILED,
  POWERBI_EMBEDDED_FETCH_REQUESTED,
  POWERBI_EMBEDDED_FETCH_SUCCEEDED,
  POWERBI_EMBEDDED_FETCH_FAILED,
  NEWS_FETCH_REQUESTED,
  NEWS_FETCH_SUCCEEDED,
  NEWS_FETCH_FAILED,
} from './actionTypes';

const initialState = {
  widgets: {
    isLoading: false,
    isLoaded: false,
    data: [],
  },
  powerBiEmbedded: {
    isLoading: false,
    isLoaded: false,
    data: {},
    error: null,
  },
  news: {
    isLoading: false,
    isLoaded: false,
    data: [],
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case WIDGETS_FETCH_REQUESTED: {
      return {
        ...state,
        widgets: {
          ...state.widgets,
          isLoading: true,
        },
      };
    }
    case WIDGETS_FETCH_SUCCEEDED: {
      return {
        ...state,
        widgets: {
          ...state.widgets,
          isLoading: false,
          isLoaded: true,
          data: payload,
        },
      };
    }
    case WIDGETS_FETCH_FAILED: {
      return {
        ...state,
        widgets: {
          ...state.widgets,
          isLoading: false,
          isLoaded: true,
          data: [],
        },
      };
    }

    case POWERBI_EMBEDDED_FETCH_REQUESTED: {
      return {
        ...state,
        powerBiEmbedded: {
          ...state.powerBiEmbedded,
          isLoading: true,
          error: null,
        },
      };
    }
    case POWERBI_EMBEDDED_FETCH_SUCCEEDED: {
      return {
        ...state,
        powerBiEmbedded: {
          ...state.powerBiEmbedded,
          isLoading: false,
          isLoaded: true,
          data: payload,
          error: null,
        },
      };
    }
    case POWERBI_EMBEDDED_FETCH_FAILED: {
      return {
        ...state,
        powerBiEmbedded: {
          ...state.powerBiEmbedded,
          isLoading: false,
          isLoaded: false,
          data: {},
          error: payload,
        },
      };
    }

    case NEWS_FETCH_REQUESTED: {
      return {
        ...state,
        news: {
          ...state.news,
          isLoading: true,
          isLoaded: false,
          data: [],
          error: null,
        },
      };
    }
    case NEWS_FETCH_SUCCEEDED: {
      return {
        ...state,
        news: {
          ...state.news,
          isLoading: false,
          isLoaded: true,
          data: payload,
          error: null,
        },
      };
    }
    case NEWS_FETCH_FAILED: {
      return {
        ...state,
        news: {
          ...state.news,
          isLoading: false,
          isLoaded: true,
          data: [],
          error: payload,
        },
      };
    }

    default:
      return state;
  }
};
