import { CATEGORY_FETCH_REQUESTED, CATEGORY_NAVIGATE } from './actionTypes';

export const fetchCategory = (category, page, pageSize) => ({
  type: CATEGORY_FETCH_REQUESTED,
  payload: { category, page, pageSize },
});

export const navigateCategory = (page) => ({
  type: CATEGORY_NAVIGATE,
  payload: page,
});
