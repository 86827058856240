import { requestAuthorized } from './index';
import { webApiEndpoint } from '../clientConfig';

export const fetchNotifications = () =>
  requestAuthorized({
    url: `${webApiEndpoint}/api/notifications?timestamp=${new Date().getTime()}`,
    method: 'get',
  }).then((response) => response.data);

export const fetchNotificationsByPage = (pageNumber) => {
  if (!pageNumber) return fetchNotifications();

  return requestAuthorized({
    url: `${webApiEndpoint}/api/notifications?page=${pageNumber}&timestamp=${new Date().getTime()}`,
    method: 'get',
  }).then((response) => response.data);
};

export const markNotificationAsRead = (notificationId) => {
  if (!notificationId) return;

  return requestAuthorized({
    url: `${webApiEndpoint}/api/notification/read`,
    method: 'post',
    data: {
      id: notificationId,
    },
  });
};
