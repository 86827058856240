export const REPORT_CATEGORIES_FETCH_REQUESTED =
  'REPORT_CATEGORIES_FETCH_REQUESTED';
export const REPORT_CATEGORIES_FETCH_SUCCEEDED =
  'REPORT_CATEGORIES_FETCH_SUCCEEDED';
export const REPORT_CATEGORIES_FETCH_FAILED = 'REPORT_CATEGORIES_FETCH_FAILED';

export const SET_REPORT_CRITERIA = 'SET_REPORT_CRITERIA';

export const REPORT_LIST_FETCH_REQUESTED = 'REPORT_LIST_FETCH_REQUESTED';
export const REPORT_LIST_FETCH_SUCCEEDED = 'REPORT_LIST_FETCH_SUCCEEDED';
export const REPORT_LIST_FETCH_FAILED = 'REPORT_LIST_FETCH_FAILED';
