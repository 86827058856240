import React from 'react';
import styled from 'styled-components';
import { p, my } from 'styled-components-spacing';

const StyledInput = styled.input`
  border-radius: ${({ theme: { borderRadius } }) =>
    `${borderRadius} ${borderRadius} 0 0`};
  border: 1px solid;
  border-bottom-width: 2px;
  border-color: ${({ theme, hasError }) =>
    hasError ? theme.colors.error : theme.colors.disabled};
  color: ${({ theme }) => theme.colors.copy};
  background-color: ${({ theme }) => theme.colors.white};
  display: block;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  ${({ theme }) => p(2, theme)};
  ${({ theme }) => my(1, theme)};
  ${({ theme, hasError }) => hasError && theme.boxGlowError};
  font-size: 1rem;
  opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};

  :focus {
    border-color: ${({ theme }) => theme.colors.primary};
    outline: none;
    ${({ theme }) => theme.boxGlow};
  }

  ::placeholder {
    color: rgba(73, 76, 86, 0.4);
    font-family: ${({ theme }) => theme.fonts.copy};
  }
`;

const Input = ({ field, hasError, ...props }) => (
  <StyledInput {...field} {...props} hasError={hasError} />
);

export default Input;
