import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import breakpoint from 'styled-components-breakpoint';
import Grid from 'styled-components-grid';
import { Padding, my, mb, mt, mx } from 'styled-components-spacing';
import Seo from '../Seo';
import Container from '../Container';
import Heading from '../Heading';
import StandardContentCard from '../StandardContentCard';
import Pagination from './Pagination';

const CardGrid = styled(Grid)`
  margin: 0 -15px;
`;

const GridUnit = styled(Grid.Unit)`
  margin: 15px;
`;

const StyledHeading = styled(Heading)`
  ${({ theme }) => my(2, theme)};

  ${breakpoint('xl')`
    font-size: ${({ theme }) => theme.typeScale[0]};
    ${({ theme }) => mb(3, theme)};
    ${({ theme }) => mt(4, theme)};
    ${mx(0)};
  `};
`;

const CategoryPage = ({ seo, title, subTitle, pageData: { items } }) => {
  return (
    <div>
      <Seo {...seo} />
      <Padding horizontal={{ xs: 3 }}>
        <Container>
          <StyledHeading size={1} text={title} />
          {subTitle && <Heading size={2} text={subTitle} />}
          <CardGrid>
            {items &&
              items.map((item) => (
                <GridUnit
                  key={item.id}
                  size={{ lg: 1 / 2, xl: 1 / 3, xxl: 1 / 4 }}
                >
                  <StandardContentCard {...item} />
                </GridUnit>
              ))}
          </CardGrid>

          <Pagination />
        </Container>
      </Padding>
    </div>
  );
};

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

CategoryPage.propTypes = propTypes;
CategoryPage.defaultProps = defaultProps;

export default CategoryPage;
