import styled from 'styled-components';
import { mb } from 'styled-components-spacing';
import breakpoint from 'styled-components-breakpoint';

export const WelcomeMessage = styled.p`
  font-size: ${({ theme }) => theme.typeScale[4]};
  font-weight: ${({ theme }) => theme.weights.semi};
  line-height: 1.93;
  max-width: 600px;
  margin-top: 0;
  ${mb(3)};
  display: none;

  ${breakpoint('xl')`
    display: block;
  `};
`;

export const WelcomeMessageSmall = styled(WelcomeMessage)`
  display: block;
  font-size: ${({ theme }) => theme.typeScale[6]};

  ${breakpoint('xl')`
    display: none;
  `};
`;
