import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './AuditModal.scss';
import { Modal } from 'antd';
import { FaTimes, FaCaretDown, FaCaretUp } from 'react-icons/fa';
import mapDispatchToProps from './mapDispatchToProps';
import mapStateToProps from './mapStateToProps';
import Loading from '../../../libs/Loader';
import Checkbox from '../Checkbox';

function AuditModal(props) {
  const [unappliedSelectedAuthorisers, setUnappliedSelectedAuthorisers] =
    useState([]);
  const [unappliedSelectedMemberUsers, setUnappliedSelectedMemberUsers] =
    useState([]);
  const [unappliedSelectedActions, setUnappliedSelectedActions] = useState([]);

  const [selectedAuthorisers, setSelectedAuthorisers] = useState([]);
  const [selectedMemberUsers, setSelectedMemberUsers] = useState([]);
  const [selectedActions, setSelectedActions] = useState([]);

  const [isAuthoriserDropdownOpen, setIsAuthoriserDropdownOpen] =
    useState(false);
  const [isMemberUserDropdownOpen, setIsMemberUserDropdownOpen] =
    useState(false);
  const [isActionDropdownOpen, setIsActionDropdownOpen] = useState(false);

  const getUniqueValues = (array, key) => {
    const uniqueValues = [...new Set(array.map((item) => item[key]))];
    return uniqueValues.sort();
  };

  const uniqueAuthorisers = getUniqueValues(props.auditLogs, 'authoriser');
  const uniqueMemberUsers = getUniqueValues(props.auditLogs, 'memberUser');
  const uniqueActions = getUniqueValues(props.auditLogs, 'action');

  const applyFilters = (log) => {
    return (
      (selectedAuthorisers.length === 0 ||
        selectedAuthorisers.includes(log.authoriser)) &&
      (selectedMemberUsers.length === 0 ||
        selectedMemberUsers.includes(log.memberUser)) &&
      (selectedActions.length === 0 || selectedActions.includes(log.action))
    );
  };

  useEffect(() => {
    if (props.isOpenModal) {
      props.fetchAllAuditLogs();
    }
  }, [props.isOpenModal]);

  const convertToLocalTime = (utcDate) => {
    // Append 'Z' to the date string to ensure UTC interpretation
    const date = new Date(utcDate + 'Z');

    // Check for an invalid date
    if (isNaN(date.getTime())) {
      return 'Invalid date format';
    }

    return date.toLocaleString();
  };

  const handleAuthoriserChange = (value) => {
    setUnappliedSelectedAuthorisers(value);
  };

  const handleMemberUserChange = (value) => {
    setUnappliedSelectedMemberUsers(value);
  };

  const handleActionChange = (value) => {
    setUnappliedSelectedActions(value);
  };

  const toggleAuthoriserDropdown = () => {
    setIsAuthoriserDropdownOpen(!isAuthoriserDropdownOpen);
  };

  const toggleMemberUserDropdown = () => {
    setIsMemberUserDropdownOpen(!isMemberUserDropdownOpen);
  };

  const toggleActionDropdown = () => {
    setIsActionDropdownOpen(!isActionDropdownOpen);
  };

  const applyAuthoriserDropdown = () => {
    setIsAuthoriserDropdownOpen(false);
    setSelectedAuthorisers(unappliedSelectedAuthorisers);
  };

  const applyMemberUserDropdown = () => {
    setIsMemberUserDropdownOpen(false);
    setSelectedMemberUsers(unappliedSelectedMemberUsers);
  };

  const applyActionDropdown = () => {
    setIsActionDropdownOpen(false);
    setSelectedActions(unappliedSelectedActions);
  };

  const clearAuthoriserDropdown = () => {
    setSelectedAuthorisers([]);
    setUnappliedSelectedAuthorisers([]);
    setIsAuthoriserDropdownOpen(false);
  };

  const clearMemberUserDropdown = () => {
    setSelectedMemberUsers([]);
    setUnappliedSelectedMemberUsers([]);
    setIsMemberUserDropdownOpen(false);
  };

  const clearActionDropdown = () => {
    setSelectedActions([]);
    setUnappliedSelectedActions([]);
    setIsActionDropdownOpen(false);
  };

  const renderCheckboxDropdown = (
    label,
    options,
    selectedOptions,
    onChange,
    isDropdownOpen,
    toggleDropdown,
    onClear,
    onApply,
  ) => {
    return (
      <div className="filter-dropdown">
        <div className="dropdown-wrapper">
          <button className="dropdown-button" onClick={toggleDropdown}>
            {label}

            {isDropdownOpen ? (
              <FaCaretUp className="dropdown-icon" />
            ) : (
              <FaCaretDown className="dropdown-icon" />
            )}
          </button>
          {isDropdownOpen && (
            <div className="checkbox-list">
              <div className="list">
                {options.map((option, index) => (
                  <label key={index} className="label">
                    <Checkbox
                      size="medium"
                      className="checkbox"
                      checked={selectedOptions.includes(option)}
                      onChange={() => {
                        const newSelectedOptions = selectedOptions.includes(
                          option,
                        )
                          ? selectedOptions.filter((item) => item !== option)
                          : [...selectedOptions, option];
                        onChange(newSelectedOptions);
                      }}
                    />
                    <span className="checkbox-text">{option}</span>
                  </label>
                ))}
              </div>
              <div className="filter-dropdown-actions">
                <button className="filter-dropdown-button" onClick={onClear}>
                  Clear
                </button>
                <button className="filter-dropdown-button" onClick={onApply}>
                  Apply
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return props.isLoading ? (
    <Modal
      title={'Audit Log'}
      open={props.isOpenModal}
      onCancel={props.closeAuditModal}
      destroyOnClose={true}
      closeIcon={<FaTimes />}
      wrapClassName="audit-modal"
      width="1400px"
      footer={null}
      closable={true}
    >
      <Loading />
    </Modal>
  ) : (
    <Modal
      title={'Audit Log'}
      open={props.isOpenModal}
      onCancel={props.closeAuditModal}
      destroyOnClose={true}
      closeIcon={<FaTimes />}
      wrapClassName="audit-modal"
      width="1400px"
      footer={null}
      closable={true}
    >
      <div className="filters">
        {renderCheckboxDropdown(
          'Authoriser',
          uniqueAuthorisers,
          unappliedSelectedAuthorisers,
          handleAuthoriserChange,
          isAuthoriserDropdownOpen,
          toggleAuthoriserDropdown,
          clearAuthoriserDropdown,
          applyAuthoriserDropdown,
        )}
        {renderCheckboxDropdown(
          'Member User',
          uniqueMemberUsers,
          unappliedSelectedMemberUsers,
          handleMemberUserChange,
          isMemberUserDropdownOpen,
          toggleMemberUserDropdown,
          clearMemberUserDropdown,
          applyMemberUserDropdown,
        )}
        {renderCheckboxDropdown(
          'Action',
          uniqueActions,
          unappliedSelectedActions,
          handleActionChange,
          isActionDropdownOpen,
          toggleActionDropdown,
          clearActionDropdown,
          applyActionDropdown,
        )}
      </div>

      <div className="audit-table">
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Authoriser</th>
              <th>Member User</th>
              <th>Action</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {props.auditLogs &&
              props.auditLogs.filter(applyFilters).map((log, index) => (
                <tr key={index}>
                  <td>{convertToLocalTime(log.date)}</td>
                  <td>{log.authoriser}</td>
                  <td>{log.memberUser}</td>
                  <td>{log.action}</td>
                  <td>{log.description}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </Modal>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AuditModal);
