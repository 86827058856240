import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mb } from 'styled-components-spacing';
import { Pagination } from 'antd';
import Categories from './Categories';
import ReportList from './ReportList';
import Filters from './Filters';
import breakpoint from 'styled-components-breakpoint';
import { getObjectFromQueryString } from '../../utils/queryString';
import history from '../../utils/history';
import { PageContainer, PaginationWrapper } from '../../libs/BasicStyles';
import Heading from '../Heading';
import Button from '../Button';

const PageTitle = styled(Heading)`
  ${mb(3)};
`;

const FilterReportContent = styled.div`
  flex: 1 1 auto;
  overflow-x: auto;
`;

const ContentWrapper = styled.div`
  display: none;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 7px solid ${({ theme }) => theme.colors.shades[2]};
  border-radius: ${({ theme }) => theme.borderRadius};
  ${({ theme }) => theme.boxShadow};
  ${breakpoint('xl')`
    display: flex;
  `};
`;

const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;

  h4 {
    margin: 0;
  }
  ${breakpoint('xl')`
    display: none;
  `};
`;

const WrapperLink = styled(RouterLink)`
  text-decoration: none;
  font-family: 'Open Sans', sans-serif;
  color: ${({ theme }) => theme.colors.primary};
  position: relative;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.weights.semi};
`;

class Reports extends Component {
  constructor(props) {
    super(props);

    const pathName = history.location.pathname;
    this.props.setCurrentPathName(pathName);

    // Grab the query string values and set them as the initial values for filters
    this.initialReportParameters = getObjectFromQueryString();
    const reportCriteria = {
      categoryId: this.initialReportParameters.categoryId,
      type: this.initialReportParameters.type,
      dateStart: this.initialReportParameters.dateStart,
      dateEnd: this.initialReportParameters.dateEnd,
      page:
        this.initialReportParameters.page &&
        parseInt(this.initialReportParameters.page),
    };
    if (this.initialReportParameters.sortByField) {
      reportCriteria.sortByField = this.initialReportParameters.sortByField;
      reportCriteria.isReverseSortOrder =
        this.initialReportParameters.isReverseSortOrder === 'true' && true;
    }
    this.props.setReportCriteria(reportCriteria);
  }

  render() {
    const {
      setReportCriteria,
      page = 1,
      totalPages,
      selectedCategory,
    } = this.props;

    return (
      <PageContainer>
        <PageTitle size={1} text="Reports" variant="page-title" />
        <ContentWrapper>
          <Categories />
          <FilterReportContent>
            <Filters
              title={
                selectedCategory ? selectedCategory.title : 'All Categories'
              }
              description={selectedCategory && selectedCategory.description}
            />
            <ReportList />
            <PaginationWrapper>
              {totalPages > 1 && (
                <Pagination
                  defaultCurrent={page}
                  defaultPageSize={10}
                  total={totalPages * 10}
                  onChange={(page) => setReportCriteria({ page: page || 1 })}
                  itemRender={(page, type, originalElement) => (
                    <Button
                      page={page}
                      type={type}
                      active={page === this.props.page}
                      variant="reports-pagination"
                    />
                  )}
                />
              )}
            </PaginationWrapper>
          </FilterReportContent>
        </ContentWrapper>
        <MobileContainer>
          <Heading
            size={4}
            text="The reports area is unavailable on Mobile or Tablet Devices"
          />
          <p>Please open the member's portal on a desktop device</p>
          <WrapperLink to="/">Go to Dashboard</WrapperLink>
        </MobileContainer>
      </PageContainer>
    );
  }
}

const propTypes = {
  setReportCriteria: PropTypes.func.isRequired,
  setCurrentPathName: PropTypes.func.isRequired,
  totalPages: PropTypes.number,
  page: PropTypes.number,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

Reports.propTypes = propTypes;
Reports.defaultProps = defaultProps;

export default Reports;
