import { createSelector } from 'reselect';

export const select = (state) => state.reports || {};

// Report Categories
export const reportCategoriesSelector = (state) =>
  select(state).reportCategories || {};

export const reportCategoriesDataSelector = (state) =>
  reportCategoriesSelector(state).data || [];

export const reportCategoriesLoadingSelector = (state) =>
  reportCategoriesSelector(state).isLoading;

export const reportCategoriesErrorSelector = (state) =>
  reportCategoriesSelector(state).error;

export const reportCategoriesLoadedSelector = (state) =>
  reportCategoriesSelector(state).isLoaded;

export const reportCategoriesReadySelector = createSelector(
  reportCategoriesLoadingSelector,
  reportCategoriesLoadedSelector,
  (loading, loaded) => loading && loaded,
);

// Report Criteria
export const reportCriteriaSelector = (state) => select(state).reportCriteria;

export const reportCriteriaCategoryIdSelector = (state) =>
  reportCriteriaSelector(state).categoryId;

export const reportCriteriaPageSelector = (state) =>
  reportCriteriaSelector(state).page;

export const reportCriteriaSortByFieldSelector = (state) =>
  reportCriteriaSelector(state).sortByField;

export const reportCriteriaIsReverseSortOrderSelector = (state) =>
  reportCriteriaSelector(state).isReverseSortOrder;

export const reportCriteriaTypeSelector = (state) =>
  reportCriteriaSelector(state).type;

export const reportCriteriaDateStartSelector = (state) =>
  reportCriteriaSelector(state).dateStart;

export const reportCriteriaDateEndSelector = (state) =>
  reportCriteriaSelector(state).dateEnd;

export const reportCriteriaTitle = (state) =>
  reportCriteriaSelector(state).title;

export const selectedReportCategorySelector = createSelector(
  reportCategoriesDataSelector,
  reportCriteriaCategoryIdSelector,
  (categories, categoryId) => {
    if (categories && categories.length) {
      return categories.find((c) => c.id === categoryId);
    }

    return null;
  },
);

// Report List
export const reportListSelector = (state) => select(state).reportList || {};

export const reportListDataSelector = (state) =>
  reportListSelector(state).data || [];

export const reportListTotalPagesSelector = (state) =>
  reportListSelector(state).totalPages;

export const reportListLoadingSelector = (state) =>
  reportListSelector(state).isLoading;

export const reportListLoadedSelector = (state) =>
  reportListSelector(state).isLoaded;

export const reportListErrorSelector = (state) =>
  reportListSelector(state).error;

export const reportListReadySelector = createSelector(
  reportListLoadingSelector,
  reportListLoadedSelector,
  (loading, loaded) => loading && loaded,
);
