import { bindActionCreators } from 'redux';
import { fetchNews } from '../../../redux/dashboard/actions';

export default (dispatch) => ({
  ...bindActionCreators(
    {
      fetchNews,
    },
    dispatch,
  ),
});
