import {
  REPORT_CATEGORIES_FETCH_REQUESTED,
  REPORT_CATEGORIES_FETCH_SUCCEEDED,
  REPORT_CATEGORIES_FETCH_FAILED,
  SET_REPORT_CRITERIA,
  REPORT_LIST_FETCH_REQUESTED,
  REPORT_LIST_FETCH_SUCCEEDED,
  REPORT_LIST_FETCH_FAILED,
} from './actionTypes';

const initialState = {
  reportCategories: {
    isLoading: false,
    isLoaded: false,
    error: null,
    data: [],
  },
  reportList: {
    isLoading: false,
    isLoaded: false,
    data: [],
    error: null,
    totalPages: 0,
    currentPage: 0,
  },
  reportCriteria: {
    categoryId: undefined,
    type: undefined,
    dateStart: undefined,
    dateEnd: undefined,
    page: undefined,
    title: undefined,
    sortByField: undefined,
    isReverseSortOrder: true,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case REPORT_CATEGORIES_FETCH_SUCCEEDED: {
      return {
        ...state,
        reportCategories: {
          ...state.reportCategories,
          isLoading: false,
          isLoaded: true,
          error: null,
          data: payload,
        },
      };
    }

    case REPORT_CATEGORIES_FETCH_FAILED: {
      return {
        ...state,
        reportCategories: {
          ...state.reportCategories,
          isLoading: false,
          isLoaded: true,
          error: payload.message,
          data: [],
        },
      };
    }

    case REPORT_CATEGORIES_FETCH_REQUESTED: {
      return {
        ...state,
        reportCategories: {
          ...state.reportCategories,
          error: null,
          isLoading: true,
        },
      };
    }

    case SET_REPORT_CRITERIA: {
      return {
        ...state,
        reportCriteria: {
          ...state.reportCriteria,
          ...payload,
        },
      };
    }

    case REPORT_LIST_FETCH_REQUESTED: {
      return {
        ...state,
        reportList: {
          ...state.reportList,
          error: null,
          isLoading: true,
          isLoaded: false,
        },
      };
    }

    case REPORT_LIST_FETCH_SUCCEEDED: {
      return {
        ...state,
        reportList: {
          ...state.reportList,
          isLoading: false,
          isLoaded: true,
          data: payload.data,
          error: null,
          totalPages: payload.totalPages,
          currentPage: payload.currentPage,
        },
      };
    }

    case REPORT_LIST_FETCH_FAILED: {
      return {
        ...state,
        reportList: {
          ...state.reportList,
          isLoading: false,
          isLoaded: true,
          data: [],
          error: payload.message,
          totalPages: 0,
          currentPage: 0,
        },
      };
    }

    default:
      return state;
  }
};
