import { createSelector } from 'reselect';

export const select = (state) => state.dashboard || {};
//Widgets
export const widgetsSelector = (state) => select(state).widgets || {};
export const widgetsDataSelector = (state) => widgetsSelector(state).data || [];
export const widgetsLoadingSelector = (state) =>
  widgetsSelector(state).isLoading;
export const widgetsLoadedSelector = (state) => widgetsSelector(state).isLoaded;
export const widgetsReadySelector = createSelector(
  widgetsLoadingSelector,
  widgetsLoadedSelector,
  (loading, loaded) => loading && loaded,
);
//Power BI Embedded
export const powerBiEmbeddedSelector = (state) =>
  select(state).powerBiEmbedded || {};
export const powerBiEmbeddedDataSelector = (state) =>
  powerBiEmbeddedSelector(state).data || {};
export const powerBiEmbeddedLoadingSelector = (state) =>
  powerBiEmbeddedSelector(state).isLoading;
export const powerBiEmbeddedLoadedSelector = (state) =>
  powerBiEmbeddedSelector(state).isLoaded;
export const powerBiEmbeddedIsLoadingError = (state) =>
  !powerBiEmbeddedLoadingSelector(state) &&
  !powerBiEmbeddedLoadedSelector(state) &&
  powerBiEmbeddedSelector(state).error;
export const powerBiEmbeddedReadySelector = createSelector(
  powerBiEmbeddedLoadingSelector,
  powerBiEmbeddedLoadedSelector,
  (loading, loaded) => loading && loaded,
);
//News
export const newsSelector = (state) => select(state).news || {};
export const newsDataSelector = (state) => newsSelector(state).data || [];
export const newsLoadingSelector = (state) => newsSelector(state).isLoading;
export const newsLoadedSelector = (state) => newsSelector(state).isLoaded;
export const newsReadySelector = createSelector(
  newsLoadingSelector,
  newsLoadedSelector,
  (loading, loaded) => !loading && loaded,
);
