import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { mx, mt, ml, mr, my } from 'styled-components-spacing';
import { PicturePanel } from '@hvccc/component-library';

export default styled(PicturePanel)`
  display: flex;
  flex-wrap: wrap;

  ${PicturePanel.TitleComponent} {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.lightGrey};
    font-weight: 600;
    font-family: ${({ theme }) => theme.fonts.copy};
    ${({ theme }) => mr(3, theme)};
    ${({ theme }) => mt(3, theme)};
    ${({ theme }) => ml(0, theme)};
    order: 1;

    div {
      display: inline;
    }

    :before {
      content: '';
      font-family: ${({ theme }) => theme.fonts.icon};
      ${({ theme }) => mr(1, theme)};
    }

    ${breakpoint('xl')`
      ${({ theme }) => my(2, theme)};
      ${mx(0)};
      width: 50%;
    `};
  }

  ${PicturePanel.CaptionComponent} {
    width: 100%;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.lightGrey};
    font-weight: 600;
    ${({ theme }) => my(2, theme)};
    ${({ theme }) => mx(3, theme)};
    order: 999;

    ${breakpoint('xl')`
      width: 50%;
      text-align: right;
      order: 2;
      ${mx(0)};
    `};
  }
`;
