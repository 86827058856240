import React from 'react';
import Helmet from 'react-helmet';
import propTypes from './propTypes';

const SeoHelmet = ({ title, meta, links }) => (
  <Helmet title={title} meta={meta}>
    {links && links.length && links.map((a, idx) => <link key={idx} {...a} />)}
  </Helmet>
);

SeoHelmet.displayName = 'SeoHelmet';
SeoHelmet.propTypes = propTypes;

export default SeoHelmet;
