import {
  isAuditTableLoaderVisibleSelector,
  auditLogsSelector,
} from '../../../redux/members/selectors';

const map = (state) => ({
  auditLogs: auditLogsSelector(state),
  isLoading: isAuditTableLoaderVisibleSelector(state),
});

export default map;
