import { withVariants } from '@hvccc/component-library';
import StandardContentTextPanel from './StandardContentTextPanel';
import QuoteTextPanel from './QuoteTextPanel';

const styles = {
  standard: StandardContentTextPanel,
  quote: QuoteTextPanel,
};

export default withVariants(styles)(StandardContentTextPanel);
