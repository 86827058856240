import {
  WIDGETS_FETCH_REQUESTED,
  POWERBI_EMBEDDED_FETCH_REQUESTED,
  NEWS_FETCH_REQUESTED,
} from './actionTypes';

export const fetchWidgets = () => ({
  type: WIDGETS_FETCH_REQUESTED,
});

export const fetchPowerBiEmbedded = () => ({
  type: POWERBI_EMBEDDED_FETCH_REQUESTED,
});

export const fetchNews = () => ({
  type: NEWS_FETCH_REQUESTED,
});
