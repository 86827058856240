import { bindActionCreators } from 'redux';
import {
  closeMemberModal,
  fetchAllMembers,
} from '../../../redux/members/actions';

const map = (dispatch) => ({
  ...bindActionCreators(
    {
      closeMemberModal,
      fetchAllMembers,
    },
    dispatch,
  ),
});

export default map;
