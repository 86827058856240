import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication, LogLevel } from '@azure/msal-browser';
import {
  tenantId,
  clientId,
  redirectUri,
  postLogoutRedirectUri,
} from './clientConfig';

const msalConfig = {
  auth: {
    clientId: clientId,
    authority: `https://login.microsoftonline.com/${tenantId}`,
    redirectUri: redirectUri,
    postLogoutRedirectUri: postLogoutRedirectUri,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      logLevel: LogLevel.Verbose,
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.log(message);
            return;
          case LogLevel.Info:
            console.log(message);
            return;
          case LogLevel.Verbose:
            console.log(message);
            return;
          case LogLevel.Warning:
            console.log(message);
            return;
        }
      },
      piiLoggingEnabled: false,
    },
  },
};

const pca = new PublicClientApplication(msalConfig);

const AppWithAuthentication = () => (
  <MsalProvider instance={pca}>
    <App />
  </MsalProvider>
);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<AppWithAuthentication />);
serviceWorker.unregister();
