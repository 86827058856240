import { createSelector } from 'reselect';

export const select = (state) => state.user || {};

export const userProfileSelector = (state) => select(state).userProfile || {};
export const userProfileDataSelector = (state) =>
  userProfileSelector(state).data || {};
export const userProfileLoadingSelector = (state) =>
  userProfileSelector(state).isLoading;
export const userProfileLoadedSelector = (state) =>
  userProfileSelector(state).isLoaded;
export const userProfileReadySelector = createSelector(
  userProfileLoadingSelector,
  userProfileLoadedSelector,
  (loading, loaded) => loading && loaded,
);
export const isProfileUpdatingSelector = (state) =>
  userProfileSelector(state).isUpdating;

export const isProfileEditingSelector = (state) =>
  userProfileSelector(state).isEditing;

export const memberUpdatingIdsSelector = (state) =>
  select(state).updatingMemberIds;

export const isMemberUpdatingSelector = createSelector(
  [
    // Usual first input - extract value from `state`
    memberUpdatingIdsSelector,
    // Take the second arg, `category`, and forward to the output selector
    (state, memberId) => memberId,
  ],
  (array, memberId) => array.indexOf(memberId) !== -1,
);
