import { call, put, takeLatest, select, fork, all } from 'redux-saga/effects';
import { pickBy, identity } from 'lodash';
import {
  reportCategoriesReadySelector,
  reportCategoriesDataSelector,
  reportListReadySelector,
  reportListDataSelector,
  reportCriteriaSelector,
} from './selectors';

import {
  REPORT_CATEGORIES_FETCH_REQUESTED,
  REPORT_CATEGORIES_FETCH_SUCCEEDED,
  REPORT_CATEGORIES_FETCH_FAILED,
  SET_REPORT_CRITERIA,
  REPORT_LIST_FETCH_REQUESTED,
  REPORT_LIST_FETCH_SUCCEEDED,
  REPORT_LIST_FETCH_FAILED,
} from './actionTypes';

import {
  fetchReportCategories as apiFetchReportCategories,
  fetchReportList as apiFetchReportList,
} from '../../api/report';
import { objectToQueryString, setQueryString } from '../../utils/queryString';

// ----------------------------------
// WORKERS
// ----------------------------------
function* fetchReportCategories() {
  const reportCategoriesReady = yield select(reportCategoriesReadySelector);

  try {
    let reportCategories;
    if (reportCategoriesReady) {
      reportCategories = yield select(reportCategoriesDataSelector);
    } else {
      reportCategories = yield call(apiFetchReportCategories);
    }

    yield put({
      type: REPORT_CATEGORIES_FETCH_SUCCEEDED,
      payload: reportCategories,
    });
  } catch (error) {
    yield put({ type: REPORT_CATEGORIES_FETCH_FAILED, payload: error });
  }
}

function* fetchReportList() {
  const reportListReady = yield select(reportListReadySelector);
  const reportCriteria = yield select(reportCriteriaSelector);

  yield put({ type: REPORT_LIST_FETCH_REQUESTED });

  try {
    const result = reportListReady
      ? yield select(reportListDataSelector)
      : yield call(apiFetchReportList, reportCriteria);
    yield put({
      type: REPORT_LIST_FETCH_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    yield put({ type: REPORT_LIST_FETCH_FAILED, payload: error });
  }
}

function* setReportCriteriaQueryParameters() {
  const reportCriteria = yield select(reportCriteriaSelector);
  // Omit null & undefined object properties
  const coalescedReportCriteria = pickBy(reportCriteria, identity);
  const criteriaQueryString = yield call(
    objectToQueryString,
    coalescedReportCriteria,
  );
  yield call(setQueryString, criteriaQueryString);
}

// ----------------------------------
// WATCHERS
// ----------------------------------
export function* watchFetchReportCategories() {
  yield takeLatest(REPORT_CATEGORIES_FETCH_REQUESTED, fetchReportCategories);
}

export function* watchSetReportCriteria() {
  yield takeLatest(SET_REPORT_CRITERIA, fetchReportList);
  yield takeLatest(SET_REPORT_CRITERIA, setReportCriteriaQueryParameters);
}

export default function* () {
  yield all([fork(watchSetReportCriteria), fork(watchFetchReportCategories)]);
}
