import styled from 'styled-components';
import { mx, my, mb } from 'styled-components-spacing';
import breakpoint from 'styled-components-breakpoint';
import { TextPanel } from '@hvccc/component-library';

export default styled(TextPanel)`
  background: unset;

  ${TextPanel.TitleComponent} {
    font-weight: 700;
    font-family: ${({ theme }) => theme.fonts.copy};
    margin-bottom: 30px;
    font-size: 1.75rem;
  }

  ${TextPanel.TextComponent} {
    font-size: ${({ theme }) => theme.typeScale[5]};
    font-weight: 600;
    line-height: 1.56;

    ${breakpoint('xl')`
      font-size: ${({ theme }) => theme.typeScale[4]};
    `};

    p {
      margin: 30px 0;
    }

    img {
      max-width: calc(100% - (${({ theme }) => theme.spacing[6]}*2));
      height: auto;
      text-align: center;
      display: block;
      ${({ theme }) => my(5, theme)};
      ${({ theme }) => mx(6, theme)};
    }

    hr {
      border: 0;
      height: 2px;
      background-color: #eaeaea;
      ${({ theme }) => my(4, theme)};
    }

    ul {
      padding-left: 23px;
      font-size: ${({ theme }) => theme.typeScale[4]};
      color: ${({ theme }) => theme.colors.copyLight};

      ${breakpoint('xl')`
        font-size: 1.375rem;
      `} li {
        ${({ theme }) => mb(3, theme)};
      }
    }

    a {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;
