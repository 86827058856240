import { createSelector } from 'reselect';

export const select = (state) => state.category || {};
export const categoryErrorSelector = (state) => select(state).error || null;
export const categoryDataSelector = (state) => select(state).data || {};
export const categoryLoadingSelector = (state) => select(state).isLoading;
export const categoryLoadedSelector = (state) => select(state).isLoaded;

export const categoryPageDataSelector = (state) =>
  categoryDataSelector(state).pageData || {};

export const categoryCurrentPageSelector = (state) =>
  categoryPageDataSelector(state).currentPage;

export const categoryTotalPagesSelector = (state) =>
  categoryPageDataSelector(state).totalPages;

export const categoryReadySelector = createSelector(
  categoryLoadingSelector,
  categoryLoadedSelector,
  (loading, loaded) => !loading && loaded,
);
