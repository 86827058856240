import React from 'react';
import PhoneInput, {
  validateAustralianPhoneNumber,
  AUS_PHONE_NUMBER_VALIDATION_ERROR,
} from '../../PhoneInput';
import Checkbox from '../Checkbox';
import { Tooltip, Modal } from 'antd';
import { Button } from '@hvccc/component-library';
import loader from '../../../assets/images/loader.gif';
import { updateActions, updateSubjects } from '../../../models/members';
import MemberRadioButtonList from './MemberRadioButtonList';
import { FaTimes } from 'react-icons/fa';
export default class MemberListItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      memberList: this.props.memberList?.filter((member) => {
        return this.props.managedOrganisationsDomains?.some(
          (orgEmail) =>
            member.email?.toLowerCase()?.includes(orgEmail?.toLowerCase()) &&
            member.isAuthorisedToIPS,
        );
      }),
      memberToCopyPermissionsFrom: undefined,
      isOpenModal: false,
      isMemberAuthoriser: this.props.isMemberAuthoriser,
      isDailySMSRecipient: this.props.isDailySMSRecipient,
      isCapPublishSubscriber: this.props.isCapPublishSubscriber,
      isRailPublishSubscriber: this.props.isRailPublishSubscriber,
      isVesselPublishSubscriber: this.props.isVesselPublishSubscriber,
      isMaintenancePublishSubscriber: this.props.isMaintenancePublishSubscriber,
      isAuthorisedToMemberPortal: this.props.isAuthorisedToMemberPortal,
      isAuthorisedToIPS: this.props.isAuthorisedToIPS,
      isAuthorisedToCTS: this.props.isAuthorisedToCTS,
      showOperationalReports: this.props.showOperationalReports,
      isAuthorisedToOperationalReports:
        this.props.isAuthorisedToOperationalReports,
      phoneNumber: this.props.phoneNumber || '',
      phoneError: undefined,
      showNoAccessDeleteModal: false,
    };
  }

  componentDidUpdate(prevProps) {
    let stateUpdates = {};

    if (prevProps.isMemberAuthoriser !== this.props.isMemberAuthoriser) {
      stateUpdates.isMemberAuthoriser = this.props.isMemberAuthoriser;
    }
    if (prevProps.isDailySMSRecipient !== this.props.isDailySMSRecipient) {
      stateUpdates.isDailySMSRecipient = this.props.isDailySMSRecipient;
    }
    if (
      prevProps.isCapPublishSubscriber !== this.props.isCapPublishSubscriber
    ) {
      stateUpdates.isCapPublishSubscriber = this.props.isCapPublishSubscriber;
    }
    if (
      prevProps.isRailPublishSubscriber !== this.props.isRailPublishSubscriber
    ) {
      stateUpdates.isRailPublishSubscriber = this.props.isRailPublishSubscriber;
    }
    if (
      prevProps.isVesselPublishSubscriber !==
      this.props.isVesselPublishSubscriber
    ) {
      stateUpdates.isVesselPublishSubscriber =
        this.props.isVesselPublishSubscriber;
    }
    if (
      prevProps.isMaintenancePublishSubscriber !==
      this.props.isMaintenancePublishSubscriber
    ) {
      stateUpdates.isMaintenancePublishSubscriber =
        this.props.isMaintenancePublishSubscriber;
    }
    if (
      prevProps.isAuthorisedToMemberPortal !==
      this.props.isAuthorisedToMemberPortal
    ) {
      stateUpdates.isAuthorisedToMemberPortal =
        this.props.isAuthorisedToMemberPortal;
    }
    if (prevProps.isAuthorisedToIPS !== this.props.isAuthorisedToIPS) {
      stateUpdates.isAuthorisedToIPS = this.props.isAuthorisedToIPS;
    }
    if (prevProps.isAuthorisedToCTS !== this.props.isAuthorisedToCTS) {
      stateUpdates.isAuthorisedToCTS = this.props.isAuthorisedToCTS;
    }
    if (
      prevProps.showOperationalReports !== this.props.showOperationalReports
    ) {
      stateUpdates.showOperationalReports = this.props.showOperationalReports;
    }
    if (
      prevProps.isAuthorisedToOperationalReports !==
      this.props.isAuthorisedToOperationalReports
    ) {
      stateUpdates.isAuthorisedToOperationalReports =
        this.props.isAuthorisedToOperationalReports;
    }

    if (prevProps.phoneNumber !== this.props.phoneNumber) {
      stateUpdates.phoneNumber = this.props.phoneNumber;
    }

    // Only call setState if there are updates
    if (Object.keys(stateUpdates).length > 0) {
      this.setState(stateUpdates);
    }
  }

  onIPSChange() {
    if (!this.state.isAuthorisedToIPS) {
      this.setState({
        isOpenModal: true,
      });
    } else {
      this.setState({
        memberToCopyPermissionsFrom: null,
        isAuthorisedToIPS: false,
      });
    }
  }

  onCTSChange() {
    const value = this.state.isAuthorisedToCTS;

    if (!value) {
      this.setState({
        isAuthorisedToCTS: true,
        isAuthorisedToMemberPortal: true,
      });
    } else {
      this.setState({
        isAuthorisedToCTS: !value,
      });
    }
  }

  onMemberPortalChange() {
    const value = this.state.isAuthorisedToMemberPortal;

    if (!value) {
      this.setState({
        isAuthorisedToMemberPortal: true,
      });
    } else {
      this.setState({
        isAuthorisedToCTS: false,
        isAuthorisedToMemberPortal: false,
      });
    }
  }

  onOperationalReportsChange() {
    const value = this.state.isAuthorisedToOperationalReports;
    this.setState({
      isAuthorisedToOperationalReports: !value,
    });
  }

  onMemberAuthoriserChange() {
    const value = this.state.isMemberAuthoriser;
    this.setState({
      isMemberAuthoriser: !value,
    });
  }

  onSMSsendingChange(event) {
    const value = this.state.isDailySMSRecipient;
    const newValue = !value;

    const phoneNumber = this.state.phoneNumber;
    const error = this._validatePhoneNumberSms(phoneNumber, newValue);

    this.setState({
      isDailySMSRecipient: newValue,
      phoneError: error,
    });
  }

  onCapPublishChange(event) {
    const value = this.state.isCapPublishSubscriber;

    this.setState({
      isCapPublishSubscriber: !value,
    });
  }

  onRailPublishChange(event) {
    const value = this.state.isRailPublishSubscriber;

    this.setState({
      isRailPublishSubscriber: !value,
    });
  }

  onVesselPublishChange(event) {
    const value = this.state.isVesselPublishSubscriber;

    this.setState({
      isVesselPublishSubscriber: !value,
    });
  }

  onMaintenancePublishChange(event) {
    const value = this.state.isMaintenancePublishSubscriber;

    this.setState({
      isMaintenancePublishSubscriber: !value,
    });
  }

  onPhoneChange(phoneNumber) {
    const smsRecipient = this.state.isDailySMSRecipient;
    if (!phoneNumber && smsRecipient) {
      this.setState({
        isDailySMSRecipient: false,
      });
    }

    this.setState({
      phoneNumber: phoneNumber,
    });
  }

  canEditVesselPublish() {
    const email = this.props.email;
    let canEdit = false;

    if (email) {
      canEdit =
        email.endsWith('pwcs.com.au') ||
        email.endsWith('hvccc.com.au') ||
        email.endsWith('portauthoritynsw.com.au') ||
        email.endsWith('portofnewcastle.com.au');
    }

    return canEdit;
  }

  validatePhoneNumber() {
    const phoneNumber = this.state.phoneNumber;

    let error;
    if (phoneNumber) {
      error = validateAustralianPhoneNumber(phoneNumber);
    } /* else {
        error = AUS_PHONE_NUMBER_VALIDATION_ERROR;
      }*/

    this.setState({
      phoneError: error,
    });
  }

  _validatePhoneNumberSms(phoneNumber, isDailySMSRecipient) {
    let error;
    if (!phoneNumber && isDailySMSRecipient) {
      error = AUS_PHONE_NUMBER_VALIDATION_ERROR;
    }

    return error;
  }

  edit() {
    this.props.onEdit(this.props.id);
  }

  onCloseModal() {
    this.setState({
      isOpenModal: false,
    });
  }

  deselectMember() {
    this.setState({
      memberToCopyPermissionsFrom: null,
      isOpenModal: false,
    });
  }

  cancel() {
    this.setState({
      memberToCopyPermissionsFrom: undefined,
      isOpenModal: false,
      isMemberAuthoriser: this.props.isMemberAuthoriser,
      isDailySMSRecipient: this.props.isDailySMSRecipient,
      isCapPublishSubscriber: this.props.isCapPublishSubscriber,
      isRailPublishSubscriber: this.props.isRailPublishSubscriber,
      isVesselPublishSubscriber: this.props.isVesselPublishSubscriber,
      isMaintenancePublishSubscriber: this.props.isMaintenancePublishSubscriber,
      isAuthorisedToMemberPortal: this.props.isAuthorisedToMemberPortal,
      isAuthorisedToIPS: this.props.isAuthorisedToIPS,
      isAuthorisedToCTS: this.props.isAuthorisedToCTS,
      showOperationalReports: this.props.showOperationalReports,
      isAuthorisedToOperationalReports:
        this.props.isAuthorisedToOperationalReports,
      phoneNumber: this.props.phoneNumber,
      phoneError: null,
      showNoAccessDeleteModal: false,
    });

    this.props.onCancel(this.props.id);
  }

  delete() {
    this.props.onDelete(this.props.id, {
      authoriserName: this.props.authoriser,
      isAuthorisedToCTS: this.state.isAuthorisedToCTS,
      isAuthorisedToIPS: this.state.isAuthorisedToIPS,
    });
  }

  deleteWithoutConfirmation() {
    this.cancel();
    this.props.deleteWithoutConfirmation(this.props.id);
  }

  save() {
    if (
      !this.state.isMemberAuthoriser &&
      !this.state.isAuthorisedToMemberPortal &&
      !this.state.isAuthorisedToCTS &&
      !this.state.isAuthorisedToIPS &&
      !this.state.isAuthorisedToOperationalReports
    ) {
      this.setState({
        showNoAccessDeleteModal: true,
      });

      return;
    }

    const actions = this.calculateActions();

    this.props.onSave(this.props.id, {
      actions,
      phoneNumber: this.state.phoneNumber,
      memberToCopyPermissionsFrom: this.state.memberToCopyPermissionsFrom,
      authoriserName: this.props.authoriser,
    });

    this.setState({
      isAuthorisedToCTS: this.props.isAuthorisedToCTS,
    });
  }

  onMemberSelectionChange(selectedMember) {
    this.setState({
      memberToCopyPermissionsFrom: selectedMember,
    });

    if (selectedMember) {
      this.setState({
        isAuthorisedToIPS: true,
      });
    } else {
      this.setState({
        isAuthorisedToIPS: false,
      });
    }
  }

  calculateActions() {
    const actions = {};
    if (this.props.isMemberAuthoriser !== this.state.isMemberAuthoriser) {
      actions[updateSubjects.memberAuthoriser] = this.state.isMemberAuthoriser
        ? updateActions.add
        : updateActions.delete;
    }

    if (this.props.isDailySMSRecipient !== this.state.isDailySMSRecipient) {
      actions[updateSubjects.dailySMSRecipient] = this.state.isDailySMSRecipient
        ? updateActions.add
        : updateActions.delete;
    }

    if (
      this.props.isCapPublishSubscriber !== this.state.isCapPublishSubscriber
    ) {
      actions[updateSubjects.capPublishRecipient] = this.state
        .isCapPublishSubscriber
        ? updateActions.add
        : updateActions.delete;
    }

    if (
      this.props.isRailPublishSubscriber !== this.state.isRailPublishSubscriber
    ) {
      actions[updateSubjects.railPublishRecipient] = this.state
        .isRailPublishSubscriber
        ? updateActions.add
        : updateActions.delete;
    }

    if (
      this.props.isVesselPublishSubscriber !==
      this.state.isVesselPublishSubscriber
    ) {
      actions[updateSubjects.vesselPublishRecipient] = this.state
        .isVesselPublishSubscriber
        ? updateActions.add
        : updateActions.delete;
    }

    if (
      this.props.isMaintenancePublishSubscriber !==
      this.state.isMaintenancePublishSubscriber
    ) {
      actions[updateSubjects.maintenancePublishRecipient] = this.state
        .isMaintenancePublishSubscriber
        ? updateActions.add
        : updateActions.delete;
    }

    if (this.props.isAuthorisedToIPS !== this.state.isAuthorisedToIPS) {
      actions[updateSubjects.ips] = this.state.isAuthorisedToIPS
        ? updateActions.add
        : updateActions.delete;
    }

    if (this.props.isAuthorisedToCTS !== this.state.isAuthorisedToCTS) {
      actions[updateSubjects.cts] = this.state.isAuthorisedToCTS
        ? updateActions.add
        : updateActions.delete;
    }

    if (
      this.props.isAuthorisedToMemberPortal !==
      this.state.isAuthorisedToMemberPortal
    ) {
      actions[updateSubjects.memberPortal] = this.state
        .isAuthorisedToMemberPortal
        ? updateActions.add
        : updateActions.delete;
    }

    if (
      this.props.isAuthorisedToOperationalReports !==
      this.state.isAuthorisedToOperationalReports
    ) {
      actions[updateSubjects.operationalReports] = this.state
        .isAuthorisedToOperationalReports
        ? updateActions.add
        : updateActions.delete;
    }

    return actions;
  }

  getPhoneInputClass(isEditing) {
    const phoneInputDisabled = isEditing ? '' : 'disabled';
    const phoneInputError = this.state.phoneError
      ? 'input-validation-error'
      : '';
    const phoneInputClass = `member-phone-input ${phoneInputDisabled} ${phoneInputError}`;

    return phoneInputClass;
  }

  getSaveButtonProps(isUpdating) {
    const phoneError = this.state.phoneError;

    return {
      image: isUpdating ? loader : null,
      text: isUpdating ? null : 'Save',
      icon: isUpdating ? null : 'fas fa-check',
      disabled: !!phoneError || isUpdating,
    };
  }

  render() {
    const isUpdating = this.props.isMemberUpdating(this.props.id);
    const isEditing = this.props.isMemberEditing(this.props.id);
    const isPendingDelete = this.props.isMemberPendingDelete(this.props.id);
    const canEditVesselPublish = this.canEditVesselPublish();

    const phoneInputClass = this.getPhoneInputClass(isEditing);
    const phoneError = this.state.phoneError;
    const isPhoneError = !!phoneError;
    const saveButtonProps = this.getSaveButtonProps(isUpdating);

    return (
      <div
        className={
          this.props.showOperationalReports
            ? 'row display-operational-reports'
            : 'row'
        }
      >
        <div
          className={
            this.props.showOrganisationName
              ? 'organisation-name-container'
              : null
          }
        >
          <span>{this.props.displayName}</span>
          {this.props.showOrganisationName ? (
            <strong className="organisation-name">
              {this.props.organisationName}
            </strong>
          ) : null}
        </div>
        <div>{this.props.email}</div>
        <div>
          <Tooltip
            overlayClassName="error-phone-tooltip"
            title={phoneError}
            open={isPhoneError}
            placement="bottom"
            color="white"
            arrow={{ pointAtCenter: true }}
          >
            <PhoneInput
              value={this.state.phoneNumber}
              containerClass={phoneInputClass}
              disabled={!isEditing || isUpdating}
              onChange={this.onPhoneChange.bind(this)}
              onBlur={this.validatePhoneNumber.bind(this)}
            ></PhoneInput>
          </Tooltip>
        </div>

        <div
          className={
            this.props.showOperationalReports
              ? 'access-subitems display-operational-reports'
              : 'access-subitems'
          }
        >
          <div className="centered">
            <Checkbox
              size="medium"
              checked={this.state.isMemberAuthoriser}
              onChange={this.onMemberAuthoriserChange.bind(this)}
              disabled={!isEditing || isUpdating}
            />
          </div>
          <div className="centered">
            <Checkbox
              size="medium"
              checked={this.state.isAuthorisedToIPS}
              onChange={this.onIPSChange.bind(this)}
              disabled={!isEditing || isUpdating}
            />
          </div>
          <div className="centered">
            <Checkbox
              size="medium"
              checked={this.state.isAuthorisedToCTS}
              onChange={this.onCTSChange.bind(this)}
              disabled={!isEditing || isUpdating}
            />
          </div>
          <div className="centered">
            <Checkbox
              size="medium"
              checked={this.state.isAuthorisedToMemberPortal}
              onChange={this.onMemberPortalChange.bind(this)}
              disabled={!isEditing || isUpdating}
            />
          </div>
          {this.props.showOperationalReports &&
            this.props.organisationName == 'Pacific National' && (
              <div className="centered">
                <Checkbox
                  size="medium"
                  checked={this.state.isAuthorisedToOperationalReports}
                  onChange={this.onOperationalReportsChange.bind(this)}
                  disabled={!isEditing || isUpdating}
                />
              </div>
            )}
        </div>

        <div className="subscription-subitems">
          <div className="centered">
            <Checkbox
              size="medium"
              checked={this.state.isDailySMSRecipient}
              onChange={this.onSMSsendingChange.bind(this)}
              disabled={!isEditing || isUpdating}
            />
          </div>
          <div className="centered">
            <Checkbox
              size="medium"
              checked={this.state.isCapPublishSubscriber}
              onChange={this.onCapPublishChange.bind(this)}
              disabled={!isEditing || isUpdating}
            />
          </div>
          <div className="centered">
            <Checkbox
              size="medium"
              checked={this.state.isRailPublishSubscriber}
              onChange={this.onRailPublishChange.bind(this)}
              disabled={!isEditing || isUpdating}
            />
          </div>
          <div className="centered">
            <Checkbox
              size="medium"
              checked={this.state.isVesselPublishSubscriber}
              onChange={this.onVesselPublishChange.bind(this)}
              disabled={!canEditVesselPublish || isUpdating || !isEditing}
            />
          </div>
          <div className="centered">
            <Checkbox
              size="medium"
              checked={this.state.isMaintenancePublishSubscriber}
              onChange={this.onMaintenancePublishChange.bind(this)}
              disabled={!isEditing || isUpdating}
            />
          </div>
        </div>

        <div className="buttons-container">
          {isEditing ? (
            <Button
              className="grid-button green"
              onClick={this.save.bind(this)}
              {...saveButtonProps}
            ></Button>
          ) : (
            <Button
              className="grid-button grey"
              onClick={this.edit.bind(this)}
              icon="fas fa-pencil"
              text="Edit"
            ></Button>
          )}

          {isEditing ? (
            <Button
              className="grid-button grey-outlined"
              onClick={this.cancel.bind(this)}
              text="Cancel"
              icon="fas fa-times"
              disabled={isUpdating}
            ></Button>
          ) : (
            <Button
              className={`grid-button grey-outlined delete ${
                isPendingDelete ? 'pending' : ''
              }`}
              onClick={this.delete.bind(this)}
              text="Delete"
              icon="fas fa-trash"
            ></Button>
          )}
        </div>
        <Modal
          title={
            'Please select a member user to copy the IPS permissions from for the new user'
          }
          open={this.state.isOpenModal}
          onCancel={this.onCloseModal.bind(this)}
          closeIcon={<FaTimes />}
          destroyOnClose={true}
          wrapClassName="create-member-modal"
          width="400px"
          footer={null}
          closable={false}
        >
          <MemberRadioButtonList
            memberList={this.state.memberList}
            onMemberSelection={this.onMemberSelectionChange.bind(this)}
            selectedMemberId={this.state.memberToCopyPermissionsFrom?.id}
          />

          <Button
            variant="outline"
            text="Done"
            type="button"
            className="done-button xl"
            onClick={this.onCloseModal.bind(this)}
          />
        </Modal>

        <Modal
          title="Delete member"
          open={this.state.showNoAccessDeleteModal}
          onCancel={this.cancel.bind(this)}
          closeIcon={<FaTimes />}
          destroyOnClose={true}
          width="400px"
          footer={null}
          closable={true}
        >
          <div className="confirm-message-container">
            <p className="text">
              The user has no access options checked and will be deleted. Do you
              wish to continue?
            </p>

            <div className="buttons-block">
              <Button
                variant="outline"
                text="No"
                className="no"
                onClick={this.cancel.bind(this)}
              />
              <Button
                variant="outline"
                text="Yes"
                className="yes"
                onClick={this.deleteWithoutConfirmation.bind(this)}
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
