import React from 'react';
import styled from 'styled-components';
import StandardInput from './StandardInput';

const Wrapper = styled.div`
  position: relative;

  &:after {
    content: '\f073';
    font-family: ${({ theme }) => theme.fonts.icon};
    font-size: 16px;
    position: absolute;
    right: 1px;
    top: 1px;
    bottom: 2px;
    width: 45px;
    color: ${({ theme }) => theme.colors.shades[5]};
    background: ${({ theme }) => theme.colors.shades[2]};
    border-top-right-radius: ${({ theme }) => theme.borderRadius};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
  }
`;

const StyledInput = styled(StandardInput)`
  padding-right: 50px;
`;

const CalendarInput = (props) => (
  <Wrapper disabled={props.disabled}>
    <StyledInput {...props} />
  </Wrapper>
);

export default CalendarInput;
