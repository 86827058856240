import { Button } from '@hvccc/component-library';
import successTick from '../../../assets/images/success-tick.svg';

function SuccessMessage(props) {
  return (
    <div className="success-block-container">
      <div className="title-container">
        <span className="title">
          <img src={successTick} alt="success tick" />
          Success
        </span>
      </div>
      <div>{props.children}</div>
      <Button variant="outline" text="Close" onClick={props.closeModal} />
    </div>
  );
}

export default SuccessMessage;
