import {
  reportListDataSelector,
  reportCriteriaSortByFieldSelector,
  reportCriteriaIsReverseSortOrderSelector,
  reportListLoadingSelector,
  reportListErrorSelector,
} from '../../../redux/reports/selectors';

export default (state) => ({
  loading: reportListLoadingSelector(state),
  reportList: reportListDataSelector(state),
  sortByField: reportCriteriaSortByFieldSelector(state),
  isReverseSortOrder: reportCriteriaIsReverseSortOrderSelector(state),
  error: reportListErrorSelector(state),
});
