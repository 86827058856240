import React from 'react';
import MemberModal from './MemberModal';
import { Button } from '@hvccc/component-library';
import history from '../../utils/history';
import Heading from '../Heading';
import { PageContainer } from '../../libs/BasicStyles';
import MembersTable from './MembersTable';
import MessageModal from './Messages';
import './Members.scss';
import AuditModal from './AuditModal/AuditModal';

class Members extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      members: [],
      openModal: false,
      openAuditModal: false,
      confirmLoading: false,
    };

    const pathName = history.location.pathname;
    this.props.setCurrentPathName(pathName);
  }

  componentDidMount() {
    this.props.fetchAllMembers();
  }

  showModal() {
    this.props.openMemberModal();
  }

  openAuditModalFn() {
    this.setState({
      openAuditModal: true,
    });
  }

  onAuditModalClose() {
    this.setState({
      openAuditModal: false,
    });
  }

  render() {
    const userProfile = this.props.userProfile;
    const displayName = userProfile.givenName || userProfile.displayName;
    const organisations = this.props.managedOrganisations.reduce(
      (acc, cur) => `${acc}${acc ? ',' : ''} ${cur}`,
      '',
    );
    const managedOrganisationsDomains = this.props.managedOrganisationsDomains;

    return (
      <PageContainer className="members-page-container">
        <Heading size={1} text="My Member Users" variant="page-title" />
        <p className="members-page-message">
          Hi <strong>{displayName}</strong>, you’re currently in the{' '}
          <strong>{organisations}</strong> environment.
        </p>
        <div className="refresh-block">
          <p className="refresh-block-heading">Member Users</p>

          <div className="button-box">
            <Button
              text="Create new member user"
              type="button"
              iconRight="far fa-plus"
              onClick={this.showModal.bind(this)}
              className="create-button"
            />
            <Button
              variant="outline"
              text="Refresh"
              type="button"
              className="refresh-button sm"
              onClick={this.props.fetchAllMembers}
            />
            <Button
              variant="outline"
              text="Refresh data"
              type="button"
              className="refresh-button xl"
              onClick={this.props.fetchAllMembers}
            />
            <Button
              variant="outline"
              text="Audit"
              type="button"
              className="audit-button sm"
              onClick={this.openAuditModalFn.bind(this)}
            />
            <Button
              variant="outline"
              text="Audit"
              type="button"
              className="audit-button xl"
              onClick={this.openAuditModalFn.bind(this)}
            />
          </div>
        </div>
        <MembersTable
          authoriser={displayName}
          managedOrganisationsDomains={managedOrganisationsDomains}
        />
        <MemberModal
          authoriser={displayName}
          managedOrganisationsDomains={managedOrganisationsDomains}
        />
        <MessageModal />
        <AuditModal
          isOpenModal={this.state.openAuditModal}
          closeAuditModal={this.onAuditModalClose.bind(this)}
        />
      </PageContainer>
    );
  }
}

export default Members;
