import React from 'react';
import styled from 'styled-components';
import { p, my } from 'styled-components-spacing';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import { BackgroundImage } from '@hvccc/component-library';
import Heading from '../Heading';

const Card = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.12);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Image = styled(BackgroundImage)`
  height: 210px;
`;

const Content = styled.div`
  ${({ theme }) => p(3, theme)};
  font-size: ${({ theme }) => theme.copy.smallFontSize};
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  :after {
    content: '';
    position: absolute;
    bottom: 15px;
    right: 15px;
    display: block;
    width: 0;
    height: 0;
    border-bottom: 7px solid #00b480;
    border-left: 7px solid transparent;
  }
`;

const Date = styled.div``;

const Title = styled(Heading)`
  ${({ theme }) => my(2, theme)};
  font-size: 1.875rem;
  flex-grow: 1;
`;

const ArticleLink = styled(RouterLink)`
  display: block;
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.typeScale[6]};
  font-weight: ${({ theme }) => theme.weights.semi};
  line-height: 1.5;
  text-decoration: underline;

  &:hover {
    color: #00986c;
  }
`;

const StackedCard = ({
  title,
  createdDate,
  featuredPicturePanel,
  slug,
  className,
}) => (
  <Card className={className}>
    <Image {...featuredPicturePanel.picture} />
    <Content>
      {createdDate && <Date>{moment(createdDate).format('Do MMMM YYYY')}</Date>}
      <Title text={title} size={3} />
      <ArticleLink to={slug}>Read Article</ArticleLink>
    </Content>
  </Card>
);

StackedCard.propTypes = {};
StackedCard.displayName = 'StackedCard';

export default StackedCard;
