import { createSelector } from 'reselect';

export const select = (state) => state.addressBook || {};

export const addressBookEntriesSelector = (state) =>
  select(state).addressBookEntries || {};
export const addressBookEntriesDataSelector = (state) =>
  addressBookEntriesSelector(state).data || [];
export const addressBookEntriesLoadingSelector = (state) =>
  addressBookEntriesSelector(state).isLoading;
export const addressBookEntriesLoadedSelector = (state) =>
  addressBookEntriesSelector(state).isLoaded;
export const addressBookEntriesReadySelector = createSelector(
  addressBookEntriesLoadingSelector,
  addressBookEntriesLoadedSelector,
  (loading, loaded) => loading && loaded,
);
