import { withVariants, Heading } from '@hvccc/component-library';
import PageTitle from './PageTitle';
import StandardPageHeading from './StandardPageHeading';

const variants = {
  'page-title': PageTitle,
  'standard-page': StandardPageHeading,
};

export default withVariants(variants)(Heading);
