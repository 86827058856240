import { REMOVE_ERROR, SET_ERROR } from './actionTypes';

const initialState = {
  error: '',
  errorType: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ERROR:
      return {
        ...payload,
      };
    case REMOVE_ERROR:
      return initialState;

    default:
      return state;
  }
};
