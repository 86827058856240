import {
  isProfileUpdatingSelector,
  isProfileEditingSelector,
} from '../../../redux/user/selectors';

const map = (state) => ({
  isProfileUpdating: isProfileUpdatingSelector.bind(null, state),
  isProfileEditing: isProfileEditingSelector.bind(null, state),
});

export default map;
