import { bindActionCreators } from 'redux';
import {
  fetchNotifications,
  setNotificationReadState,
} from '../../../redux/notification/actions';
export default (dispatch) => ({
  ...bindActionCreators(
    {
      fetchNotifications,
      setNotificationReadState,
    },
    dispatch,
  ),
});
