import { Checkbox } from 'antd';
import './Checkbox.scss';

export default function StyledCheckbox(props) {
  const size = props.size || 'large';
  const className = `styled-checkbox ${size}`;

  return (
    <Checkbox
      className={className}
      onChange={props.onChange}
      checked={props.checked}
      disabled={props.disabled}
    >
      {props.children}
    </Checkbox>
  );
}
