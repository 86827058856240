import React from 'react';
import breakpoint from 'styled-components-breakpoint';
import styled from 'styled-components';

const Container = ({ className, children }) => (
  <div className={className}>{children}</div>
);

export default styled(Container)`
  max-width: ${({ theme }) => theme.container.md};
  margin: 0 auto;

  ${breakpoint('lg')`
    max-width: ${({ theme }) => theme.container.lg}
  `}

  ${breakpoint('xl')`
    margin: 0 30px;
  `}

  ${breakpoint('xxl')`
    margin: 0 auto;
  `}
`;
