import React from 'react';
import styled from 'styled-components';
import { mb, my, ml, pb, py, pt, px, mx, mt } from 'styled-components-spacing';
import breakpoint from 'styled-components-breakpoint';
import PropTypes from 'prop-types';
import moment from 'moment';
import Seo from '../Seo';
import Heading from '../Heading';
import Panel from '../Panel';
import PicturePanel from '../PicturePanel';
import Container from '../Container';
import { BackgroundImage } from '@hvccc/component-library';

// -------------------------
//    Page header styles
// -------------------------

const StyledHeading = styled(Heading)`
  ${({ theme }) => my(2, theme)};

  ${breakpoint('xl')`
    font-size: ${({ theme }) => theme.typeScale[0]};
    ${({ theme }) => mb(3, theme)};
    ${({ theme }) => mt(4, theme)};
    ${mx(0)};
    max-width: 75%;
  `};
`;

const PageContainer = styled(Container)`
  ${px(3)};
`;

const CreatedDate = styled.div`
  font-size: ${({ theme }) => theme.typeScale[6]};
  color: ${({ theme }) => theme.colors.darkGrey};
  font-weight: 600;

  ${breakpoint('xl')`
    font-size: ${({ theme }) => theme.typeScale[4]};
    ${({ theme }) => mb(3, theme)};
    ${mx(0)};
  `};
`;

const StyledBackgroundImage = styled(BackgroundImage)`
  width: 100%;
  height: 244px;
  order: 2;

  ${breakpoint('xl')`
    order: 3
    height: 540px;
  `};
`;

// ---------------------------------
//    Page Panels/Content styles
// ---------------------------------

const PageWrapper = styled.div`
  position: relative;
  ${breakpoint('lg')`
  ${({ theme }) => px(3, theme)};
  `};
`;

const PanelSection = styled.section`
  ${breakpoint('xl')`
    background-color: #fff;
    position: relative;
    border-top-right-radius: ${({ theme }) => theme.borderRadius};
    border-top-left-radius: ${({ theme }) => theme.borderRadius};
    overflow: hidden;
    max-width: 760px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -80px;
    min-height:460px;

    &:after{
      content: "";
      width: calc(100% - (${({ theme }) => theme.spacing[5]} * 2));
      height: 2px;
      background-color: #eaeaea;
      display: block;
      ${({ theme }) => ml(5, theme)};
    }
  `};
  ${breakpoint('xxl')`
    max-width: 880px
  `};
`;

const PanelsWrapper = styled.div`
  ${({ theme }) => pb(3, theme)};
  ${({ theme }) => pt(0, theme)};
  ${breakpoint('lg')`
  ${({ theme }) => px(3, theme)};
  `};
  ${breakpoint('xl')`
    ${({ theme }) => px(5, theme)};
    ${({ theme }) => py(4, theme)};
  `};
`;

const AuthorPanel = styled.div`
  h5 {
    font-size: ${({ theme }) => theme.typeScale[6]};
    font-weight: ${({ theme }) => theme.weight.semi};
    color: ${({ theme }) => theme.colors.darkGrey};
    ${({ theme }) => mb(1, theme)};
  }

  span {
    font-size: 1.875rem;
    text-transform: uppercase;
    ${({ theme }) => theme.fonts.heading};
    font-weight: ${({ theme }) => theme.weight.bold};
  }
`;

const StandardPage = ({
  seo,
  title,
  featuredPicturePanel,
  createdDate,
  panels,
  author,
}) => (
  <div>
    <Seo {...seo} />
    <PageContainer>
      <StyledHeading size={1} text={title} />
      {createdDate && (
        <CreatedDate>{moment(createdDate).format('Do MMMM YYYY')}</CreatedDate>
      )}
      <PicturePanel
        variant="feature"
        pictureComponent={StyledBackgroundImage}
        {...featuredPicturePanel}
      />

      <PageWrapper>
        <PanelSection>
          <PanelsWrapper>
            {panels &&
              panels.map((panel) => <Panel key={panel.id} {...panel} />)}

            {author && (
              <AuthorPanel>
                <Heading size={5}>Author:</Heading>
                <span>{author}</span>
              </AuthorPanel>
            )}
          </PanelsWrapper>
        </PanelSection>
      </PageWrapper>
    </PageContainer>
  </div>
);

const propTypes = {
  seo: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  featuredPicturePanel: PropTypes.object.isRequired,
  createdDate: PropTypes.string,
  panels: PropTypes.array.isRequired,
  author: PropTypes.string,
};

StandardPage.propTypes = propTypes;

export default StandardPage;
