import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import history from '../../utils/history';
import { PageContainer } from '../../libs/BasicStyles';
import Loading from '../../libs/Loader';
import Heading from '../Heading';
import ContentWrapper from '../ContentWrapper';
// import Widget from './Widget';
import PowerBiEmbeddedContent from './PowerBiEmbeddedContent/Component';
import Newsfeed from './Newsfeed';
import { WelcomeMessage, WelcomeMessageSmall } from '../Common/WelcomeMessage';

const MainContent = styled.div``;

const Row = styled.div`
  display: flex;
`;

const PrimaryColumn = styled.div`
  flex-grow: 1;
`;

const SecondaryColumn = styled.div`
  display: none;

  ${breakpoint('xl')`
    display: block;
    flex: 0 0 320px;

    padding-left: 20px;
    ${breakpoint('xl')`
      padding-left: 40px;
    `};
  `};

  ${breakpoint('xxl')`
    flex: 0 0 33.333%;
  `};
`;

// const WidgetGrid = styled.div`
//   margin: 0 -10px;

//   ${breakpoint('xxl')`
//     display: flex;
//     flex-wrap: wrap;
//   `};
// `;

const VideoRow = styled.div`
  display: flex;
`;

const VideoColumn1 = styled.div`
  flex-grow: 1;
  padding-right: 5px;
`;

class Dashboard extends Component {
  constructor(props) {
    super(props);

    const pathName = history.location.pathname;
    this.props.setCurrentPathName(pathName);

    //this.props.fetchWidgets();
    if (
      !this.props.powerBiEmbeddedConfig ||
      !this.props.powerBiEmbeddedConfig.embedToken
    ) {
      // TODO Steve, there must be a cleaner, more React-like way to avoid unnecessarily refetching embed config
      this.props.fetchPowerBiEmbedded();
    }
  }

  render() {
    const {
      isLoading,
      isLoaded,
      isLoadingError,
      // widgets,
      powerBiEmbeddedConfig,
      userProfile,
    } = this.props;

    return (
      <PageContainer>
        <Heading size={1} text="Members Dashboard" variant="page-title" />
        <WelcomeMessageSmall>
          To access full functionality please login via a desktop device.
        </WelcomeMessageSmall>
        <WelcomeMessage>
          Welcome{' '}
          <strong>{userProfile.givenName || userProfile.displayName}</strong>
        </WelcomeMessage>
        <MainContent>
          <Row className="row">
            <PrimaryColumn>
              <Row>
                <PrimaryColumn>
                  {isLoading && <Loading />}
                  {isLoadingError && (
                    <div>
                      Error while trying to load dashboard report. Please
                      contact support
                    </div>
                  )}
                  {isLoaded && (
                    <ContentWrapper>
                      <PowerBiEmbeddedContent config={powerBiEmbeddedConfig} />
                    </ContentWrapper>
                  )}
                </PrimaryColumn>
              </Row>
              <VideoRow>
                <VideoColumn1>
                  <div>
                    <iframe
                      title="HVCCC - Who we are"
                      src="https://player.vimeo.com/video/584781359?h=d9529906b7"
                      width="100%"
                      height="350"
                      align="right"
                      frameBorder="0"
                      allow="autoplay; fullscreen"
                      allowFullScreen
                    ></iframe>
                  </div>
                </VideoColumn1>
              </VideoRow>
            </PrimaryColumn>
            <SecondaryColumn>
              <ContentWrapper>
                <Newsfeed />
              </ContentWrapper>
            </SecondaryColumn>
          </Row>
        </MainContent>
      </PageContainer>
    );
  }
}

const propTypes = {
  //fetchWidgets: PropTypes.func.isRequired,
  fetchPowerBiEmbedded: PropTypes.func.isRequired,
  setCurrentPathName: PropTypes.func.isRequired,
  //widgetsData: PropTypes.array,
  isLoading: PropTypes.bool,
  isLoaded: PropTypes.bool,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

Dashboard.propTypes = propTypes;
Dashboard.defaultProps = defaultProps;

export default Dashboard;
