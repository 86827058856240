import { bindActionCreators } from 'redux';
import {
  updateMember,
  startEditingMember,
  completeEditingMember,
  prepareDeletingMember,
  deleteMember,
} from '../../../redux/members/actions';

const map = (dispatch) => ({
  ...bindActionCreators(
    {
      updateMember,
      startEditingMember,
      completeEditingMember,
      prepareDeletingMember,
      deleteMember,
    },
    dispatch,
  ),
});

export default map;
