import { bindActionCreators } from 'redux';
import {
  fetchReportCategories,
  setReportCriteria,
} from '../../../redux/reports/actions';

export default (dispatch) => ({
  ...bindActionCreators(
    {
      setReportCriteria,
      fetchReportCategories,
    },
    dispatch,
  ),
});
