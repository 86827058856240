import {
  standardPageDataSelector,
  standardPageReadySelector,
  standardPageErrorSelector,
} from '../../redux/standardPage/selectors';

export default (state) => ({
  standardPage: standardPageDataSelector(state),
  error: standardPageErrorSelector(state),
  isLoaded: standardPageReadySelector(state),
});
