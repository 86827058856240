import { createSelector } from 'reselect';
export const select = (state) => state.app || {};
export const isSideBarOpenSelector = (state) => select(state).isSideBarOpen;
export const currentPathNameSelector = (state) => select(state).currentPathName;

export const layoutSelector = (state) => select(state).layout || {};
export const layoutLoadingSelector = (state) => layoutSelector(state).loading;
export const layoutLoadedSelector = (state) => layoutSelector(state).loaded;
export const layoutErrorSelector = (state) => layoutSelector(state).error;
export const layoutHeaderSelector = (state) => layoutSelector(state).header;
export const layoutFooterSelector = (state) => layoutSelector(state).footer;
export const layoutHeaderFooterSelector = createSelector(
  layoutHeaderSelector,
  layoutFooterSelector,
  (header, footer) => ({
    header,
    footer,
  }),
);
export const layoutReadySelector = createSelector(
  layoutLoadingSelector,
  layoutLoadedSelector,
  (loading, loaded) => loading && loaded,
);
