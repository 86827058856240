import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './PhoneInput.scss';

export default function Input(props) {
  const containerClass = `member-phone-input-container ${
    props.containerClass ?? ''
  }`;
  return (
    <PhoneInput
      country={'au'}
      onlyCountries={['au']}
      disableDropdown={true}
      disabled={props.disabled}
      value={props.value}
      masks={{ au: '.... ... ...' }}
      prefix={''}
      disableCountryCode={true}
      placeholder=""
      onChange={props.onChange}
      inputProps={props.inputProps}
      containerClass={containerClass}
      onBlur={props.onBlur}
    />
  );
}

export const AUS_PHONE_NUMBER_VALIDATION_ERROR =
  'Provide an Australian phone number';

export const PHONE_NUMBER_REQUIRED_ERROR = 'Phone Number is Required';

export function validateAustralianPhoneNumber(value) {
  let error;

  const length = 10;
  if (value && (!value.startsWith('04') || value.length < length)) {
    error = AUS_PHONE_NUMBER_VALIDATION_ERROR;
  }

  return error;
}

const regex = /\s/gi;
export function sanitisePhoneNumber(value) {
  if (value) {
    return value.replace(regex, '');
  }
  return value;
}
