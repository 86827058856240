export const updateSubjects = {
  memberAuthoriser: 'MemberAuthoriser',
  dailySMSRecipient: 'DailySMSRecipient',
  capPublishRecipient: 'CapPublishRecipient',
  railPublishRecipient: 'RailPublishRecipient',
  vesselPublishRecipient: 'VesselPublishRecipient',
  maintenancePublishRecipient: 'MaintenancePublishRecipient',
  ips: 'IPS',
  cts: 'CTS',
  memberPortal: 'MemberPortal',
  operationalReports: 'OperationalReports',
  accountActive: 'Active',
};

export const updateActions = {
  add: 'Add',
  delete: 'Delete',
};

export const messageIdentifiers = {
  deleteConfirm: 'deleteConfirm',
  deleteSuccess: 'deleteSuccess',
  deleteError: 'deleteError',
  updateError: 'updateError',
};
