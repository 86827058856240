import {
  reportCriteriaPageSelector,
  reportListTotalPagesSelector,
  selectedReportCategorySelector,
} from '../../redux/reports/selectors';

export default (state) => ({
  selectedCategory: selectedReportCategorySelector(state),
  totalPages: reportListTotalPagesSelector(state),
  page: reportCriteriaPageSelector(state), // TODO: Should maybe be currentPage from reportList reducer, not from criteria
});
