import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loader from '../../libs/Loader';
import Error from '../Error';
import StandardPageComponent from './Component';

class StandardPage extends Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: { category, slug },
      },
      history,
      fetchStandardPage,
      setCurrentPathName,
    } = this.props;

    setCurrentPathName(history.location.pathname);

    if (fetchStandardPage) {
      fetchStandardPage(category, slug);
    }
  }

  render() {
    const { error, isLoaded, standardPage } = this.props;

    if (!isLoaded) {
      return <Loader />;
    }

    if (error) {
      return <Error {...error} />;
    }

    return <StandardPageComponent {...standardPage} />;
  }
}

const propTypes = {
  match: PropTypes.object.isRequired,
  fetchStandardPage: PropTypes.func.isRequired,
  error: PropTypes.string,
  isLoaded: PropTypes.bool,
  standardPage: PropTypes.object,
};

StandardPage.propTypes = propTypes;

export default StandardPage;
