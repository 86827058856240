import { call, put, takeLatest, select, fork, all } from 'redux-saga/effects';
import {
  addressBookEntriesReadySelector,
  addressBookEntriesDataSelector,
} from './selectors';
import {
  ADDRESS_BOOK_ENTRIES_FETCH_REQUESTED,
  ADDRESS_BOOK_ENTRIES_FETCH_SUCCEEDED,
  ADDRESS_BOOK_ENTRIES_FETCH_FAILED,
} from './actionTypes';

import { fetchAddressBookEntries as apiFetchAddressBookEntries } from '../../api/addressBook';

// ----------------------------------
// WORKERS
// ----------------------------------
function* fetchAddressBookEntries() {
  const addressBookEntriesReady = yield select(addressBookEntriesReadySelector);

  try {
    let addressBookEntries;
    if (addressBookEntriesReady) {
      addressBookEntries = yield select(addressBookEntriesDataSelector);
    } else {
      addressBookEntries = yield call(apiFetchAddressBookEntries);
    }

    yield put({
      type: ADDRESS_BOOK_ENTRIES_FETCH_SUCCEEDED,
      payload: addressBookEntries,
    });
  } catch (error) {
    yield put({
      type: ADDRESS_BOOK_ENTRIES_FETCH_FAILED,
      payload: error,
    });
  }
}

// ----------------------------------
// WATCHERS
// ----------------------------------
export function* watchFetchAddressBookEntries() {
  yield takeLatest(
    ADDRESS_BOOK_ENTRIES_FETCH_REQUESTED,
    fetchAddressBookEntries,
  );
}

export default function* () {
  yield all([fork(watchFetchAddressBookEntries)]);
}
