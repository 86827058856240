import {
  call,
  put,
  takeLatest,
  select,
  fork,
  all,
  takeEvery,
} from 'redux-saga/effects';
import { userProfileReadySelector, userProfileDataSelector } from './selectors';

import {
  USER_PROFILE_FETCH_REQUESTED,
  USER_PROFILE_FETCH_SUCCEEDED,
  USER_PROFILE_FETCH_FAILED,
  USER_PROFILE_START_UPDATING,
  USER_PROFILE_UPDATE_COMPLETE,
  USER_PROFILE_UPDATE_ERROR,
  USER_PROFILE_UPDATE,
  USER_SUBSCRITION_START_UPDATING,
  USER_SUBSCRITION_UPDATE,
  USER_SUBSCRITION_UPDATE_COMPLETE,
  USER_SUBSCRITION_UPDATE_ERROR,
} from './actionTypes';

import {
  fetchUserProfile as apiFetchUserProfile,
  updateUserSubscriptionMiddleware,
  updateUserProfileMiddleware,
} from '../../api/user';

// ----------------------------------
// WORKERS
// ----------------------------------
function* fetchUserProfile() {
  const userProfileReady = yield select(userProfileReadySelector);

  try {
    const result = userProfileReady
      ? yield select(userProfileDataSelector)
      : yield call(apiFetchUserProfile);

    yield put({
      type: USER_PROFILE_FETCH_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    yield put({ type: USER_PROFILE_FETCH_FAILED, payload: error });
  }
}

export function* updateUserProfile(action) {
  yield put({
    type: USER_PROFILE_START_UPDATING,
    payload: { id: action.id },
  });

  try {
    yield call(updateUserProfileMiddleware, action.id, action.model);
    yield put({
      type: USER_PROFILE_UPDATE_COMPLETE,
      payload: { id: action.id, model: action.model },
    });
  } catch (error) {
    yield put({
      type: USER_PROFILE_UPDATE_ERROR,
      payload: { id: action.id, message: error.message },
    });
  }
}

export function* updateUserSubscriptions(action) {
  yield put({
    type: USER_SUBSCRITION_START_UPDATING,
    payload: { id: action.id },
  });

  try {
    yield call(updateUserSubscriptionMiddleware, action.id, action.model);
    yield put({
      type: USER_SUBSCRITION_UPDATE_COMPLETE,
      payload: { id: action.id, model: action.model },
    });
  } catch (error) {
    yield put({
      type: USER_SUBSCRITION_UPDATE_ERROR,
      payload: { id: action.id, message: error.message },
    });
  }
}

// ----------------------------------
// WATCHERS
// ----------------------------------
export function* watchAuthenticationSuccess() {
  yield takeLatest(USER_PROFILE_FETCH_REQUESTED, fetchUserProfile);
}

export function* watchUserSubscriptions() {
  yield takeEvery(USER_SUBSCRITION_UPDATE, updateUserSubscriptions);
}

export function* watchUserProfile() {
  yield takeEvery(USER_PROFILE_UPDATE, updateUserProfile);
}

export default function* () {
  yield all([
    fork(watchAuthenticationSuccess),
    fork(watchUserSubscriptions),
    fork(watchUserProfile),
  ]);
}
