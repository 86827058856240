import {
  CATEGORY_FETCH_SUCCEEDED,
  CATEGORY_FETCH_REQUESTED,
  CATEGORY_FETCH_FAILED,
} from './actionTypes';

const initialState = {
  isLoading: false,
  isLoaded: false,
  data: {},
  error: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CATEGORY_FETCH_REQUESTED: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        data: {},
        error: null,
      };
    }

    case CATEGORY_FETCH_SUCCEEDED: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: payload,
        error: null,
      };
    }

    case CATEGORY_FETCH_FAILED: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: {},
        error: payload,
      };
    }

    default:
      return state;
  }
};
