import { createSelector } from 'reselect';

export const select = (state) => state.standardPage || {};
export const standardPageErrorSelector = (state) => select(state).error || null;
export const standardPageDataSelector = (state) => select(state).data || null;
export const standardPageLoadingSelector = (state) => select(state).isLoading;
export const standardPageLoadedSelector = (state) => select(state).isLoaded;
export const standardPageReadySelector = createSelector(
  standardPageLoadingSelector,
  standardPageLoadedSelector,
  (loading, loaded) => !loading && loaded,
);
