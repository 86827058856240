import { call, put, takeLatest, select, fork, all } from 'redux-saga/effects';
import { newsPageSize } from '../../clientConfig';
import {
  widgetsReadySelector,
  widgetsDataSelector,
  powerBiEmbeddedReadySelector,
  powerBiEmbeddedDataSelector,
  newsReadySelector,
  newsDataSelector,
} from './selectors';

import {
  WIDGETS_FETCH_REQUESTED,
  WIDGETS_FETCH_SUCCEEDED,
  WIDGETS_FETCH_FAILED,
  POWERBI_EMBEDDED_FETCH_REQUESTED,
  POWERBI_EMBEDDED_FETCH_SUCCEEDED,
  POWERBI_EMBEDDED_FETCH_FAILED,
  NEWS_FETCH_REQUESTED,
  NEWS_FETCH_SUCCEEDED,
  NEWS_FETCH_FAILED,
} from './actionTypes';

import { fetchWidgets as apiFetchWidgets } from '../../api/widget';
import { fetchPowerBiEmbeddedConfig as apiFetchPowerBiEmbedded } from '../../api/powerBiEmbed';
import { default as apiFetchNews } from '../../api/category';

// ----------------------------------
// WORKERS
// ----------------------------------
function* fetchWidgets() {
  const widgetsReady = yield select(widgetsReadySelector);

  try {
    const widgets = widgetsReady
      ? yield select(widgetsDataSelector)
      : yield call(apiFetchWidgets);
    yield put({
      type: WIDGETS_FETCH_SUCCEEDED,
      payload: widgets,
    });
  } catch (error) {
    yield put({
      type: WIDGETS_FETCH_FAILED,
      payload: error,
    });
  }
}

function* fetchPowerBiEmbedded() {
  const powerBiEmbeddedReady = yield select(powerBiEmbeddedReadySelector);

  try {
    const powerBiEmbedded = powerBiEmbeddedReady
      ? yield select(powerBiEmbeddedDataSelector)
      : yield call(apiFetchPowerBiEmbedded);
    yield put({
      type: POWERBI_EMBEDDED_FETCH_SUCCEEDED,
      payload: powerBiEmbedded,
    });
  } catch (error) {
    yield put({
      type: POWERBI_EMBEDDED_FETCH_FAILED,
      payload: error,
    });
  }
}

function* fetchNews() {
  const newsReady = yield select(newsReadySelector);

  const { error, data } = newsReady
    ? yield select(newsDataSelector)
    : yield call(apiFetchNews, 'news', 1, newsPageSize);

  if (error) {
    yield put({
      type: NEWS_FETCH_FAILED,
      payload: error,
    });
  } else {
    const news = data.pageData.items;
    yield put({
      type: NEWS_FETCH_SUCCEEDED,
      payload: news,
    });
  }
}

// ----------------------------------
// WATCHERS
// ----------------------------------
export function* watchFetchWidgets() {
  yield takeLatest(WIDGETS_FETCH_REQUESTED, fetchWidgets);
}

export function* watchFetchPowerBiEmbedded() {
  yield takeLatest(POWERBI_EMBEDDED_FETCH_REQUESTED, fetchPowerBiEmbedded);
}

export function* watchFetchNews() {
  yield takeLatest(NEWS_FETCH_REQUESTED, fetchNews);
}

export default function* () {
  yield all([
    fork(watchFetchWidgets),
    fork(watchFetchPowerBiEmbedded),
    fork(watchFetchNews),
  ]);
}
