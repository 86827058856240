import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledMenuItem = styled.div`
  flex: 1 1 0%;

  .menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 78px;
    padding: 10px 5px;
    color: ${({ theme }) => theme.colors.darkGrey};
    background: transparent;
    transition: all 0.3s;
    text-decoration: none;

    &:before {
      content: '';
      opacity: 0;
      transition: all 0.3s;
    }

    :hover,
    :active {
      background-color: ${({ theme }) => theme.colors.disabled};
    }

    &.active,
    &.active:hover {
      color: ${({ theme }) => theme.colors.primary};
      background-color: ${({ theme }) => theme.colors.white};
      text-decoration: none;
      border-left: 1px solid #eaeaea;
      border-right: 1px solid #eaeaea;

      &:after {
        content: '';
        height: 4px;
        width: 100%;
        display: block;
        background-color: ${({ theme }) => theme.colors.primary};
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
`;

const IconWrapper = styled.span`
  font-size: 22px;
`;

const MenuLabel = styled.span`
  font-size: 0.5rem;
  text-align: center;
  text-transform: uppercase;
`;

const MenuItem = ({ to, label, icon, isActive }) => (
  <StyledMenuItem>
    <Link
      to={to}
      className={`menu-item menu-link
          ${isActive ? 'active' : ''}
      `}
    >
      <IconWrapper>{icon}</IconWrapper>
      <MenuLabel>{label}</MenuLabel>
    </Link>
  </StyledMenuItem>
);

export default MenuItem;
