import { combineReducers } from 'redux';
import app from './app/reducer';
import notification from './notification/reducer';
import dashboard from './dashboard/reducer';
import reports from './reports/reducer';
import addressBook from './addressBook/reducer';
import user from './user/reducer';
import error from './error/reducer';
import category from './category/reducer';
import standardPage from './standardPage/reducer';
import members from './members/reducer';

export default combineReducers({
  app,
  notification,
  dashboard,
  reports,
  addressBook,
  user,
  error,
  category,
  standardPage,
  members,
});
