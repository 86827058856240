import {
  USER_PROFILE_FETCH_REQUESTED,
  USER_PROFILE_FETCH_SUCCEEDED,
  USER_PROFILE_FETCH_FAILED,
  USER_SUBSCRITION_START_UPDATING,
  USER_SUBSCRITION_UPDATE_COMPLETE,
  USER_SUBSCRITION_UPDATE_ERROR,
  USER_PROFILE_START_UPDATING,
  USER_PROFILE_UPDATE_COMPLETE,
  USER_PROFILE_UPDATE_ERROR,
  START_EDITING_PROFILE,
  COMPLETE_EDITING_PROFILE,
} from './actionTypes';

import {
  messageIdentifiers,
  //updateActions,
  //updateSubjects,
} from '../../models/members';

const initialState = {
  userProfile: {
    isLoading: false,
    isLoaded: false,
    isEditing: false,
    data: null,
  },
  messageModal: {
    isOpen: false,
    contentIdentifier: null,
    contentProps: null,
  },
  updatingMemberIds: [],
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case USER_PROFILE_FETCH_SUCCEEDED: {
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          isLoading: false,
          isLoaded: true,
          data: payload,
        },
      };
    }
    case USER_PROFILE_FETCH_FAILED: {
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          isLoading: false,
          isLoaded: true,
          data: {},
        },
      };
    }

    case USER_PROFILE_FETCH_REQUESTED: {
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          isLoading: true,
        },
      };
    }

    case USER_PROFILE_START_UPDATING: {
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          isLoading: true,
          isLoaded: false,
          isUpdating: true,
        },
      };
    }

    case USER_PROFILE_UPDATE_COMPLETE: {
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          isLoading: false,
          isLoaded: true,
          isEditing: false,
          isUpdating: false,
          data: {
            ...state.userProfile.data,
            phoneNumber: payload.model.PhoneNumber,
            mobileNumber: payload.model.MobileNumber,
          },
        },
      };
    }

    case USER_PROFILE_UPDATE_ERROR: {
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          isEditing: false,
          isUpdating: false,
        },
        messageModal: {
          isOpen: true,
          contentIdentifier: messageIdentifiers.updateError,
          contentProps: { message: payload.message },
        },
      };
    }

    case START_EDITING_PROFILE: {
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          isEditing: true,
        },
      };
    }

    case COMPLETE_EDITING_PROFILE: {
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          isEditing: false,
        },
      };
    }

    case USER_SUBSCRITION_START_UPDATING: {
      const updatingMemberIds = [...state.updatingMemberIds, payload.id];
      return {
        ...state,
        modal: {
          ...state.modal,
        },
        updatingMemberIds: updatingMemberIds,
      };
    }

    case USER_SUBSCRITION_UPDATE_COMPLETE: {
      //processActions(newMember, payload.model.actions);

      return {
        ...state,
        //: newMembers,
        modal: {
          ...state.modal,
        },
        updatingMemberIds: [],
      };
    }

    case USER_SUBSCRITION_UPDATE_ERROR: {
      return {
        ...state,
        updatingMemberIds: state.updatingMemberIds.filter(
          (id) => id !== payload.id,
        ),
        messageModal: {
          isOpen: true,
          contentIdentifier: messageIdentifiers.updateError,
          contentProps: { message: payload.message },
        },
      };
    }

    default:
      return state;
  }
};

/*
function processActions(member, actions) {
  for (const subj in actions) {
    switch (subj) {
      case updateSubjects.dailySMSRecipient:
        member.isDailySMSRecipient = actions[subj] === updateActions.add;
        break;
      case updateSubjects.capPublishRecipient:
        member.isCapPublishSubscriber = actions[subj] === updateActions.add;
        break;
      case updateSubjects.railPublishRecipient:
        member.isRailPublishSubscriber = actions[subj] === updateActions.add;
        break;
      case updateSubjects.vesselPublishRecipient:
        member.isVesselPublishSubscriber = actions[subj] === updateActions.add;
        break;
      default:
        break;
    }
  }
}
*/

export default reducer;
