import { PublicClientApplication, LogLevel } from '@azure/msal-browser';
import {
  tenantId,
  clientId,
  redirectUri,
  postLogoutRedirectUri,
  scope,
} from './clientConfig';

export const msalConfig = {
  auth: {
    clientId: clientId,
    authority: `https://login.microsoftonline.com/${tenantId}`,
    redirectUri: redirectUri,
    postLogoutRedirectUri: postLogoutRedirectUri,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
  scopes: [scope],
  system: {
    loggerOptions: {
      logLevel: LogLevel.Verbose,
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.log(message);
            return;
          case LogLevel.Info:
            console.log(message);
            return;
          case LogLevel.Verbose:
            console.log(message);
            return;
          case LogLevel.Warning:
            console.log(message);
            return;
        }
      },
      piiLoggingEnabled: false,
    },
  },
};

const msalInstance = new PublicClientApplication(msalConfig);
await msalInstance.initialize();

await msalInstance.handleRedirectPromise();

export const acquireToken = async () => {
  try {
    await msalInstance.handleRedirectPromise();
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length === 0) {
      return;
    }
    const response = await msalInstance.acquireTokenSilent({
      scopes: msalConfig.scopes,
      account: accounts[0], //msalInstance.getActiveAccount(), //accounts[0],
    });
    return response.accessToken;
  } catch (error) {
    const response = await msalInstance.acquireTokenRedirect({
      scopes: msalConfig.scopes,
    });
    return response.accessToken;
  }
};

export const logout = async () => {
  await msalInstance.logoutRedirect();
};

export default msalInstance;
