import React from 'react';
import styled from 'styled-components';
import { p, m } from 'styled-components-spacing';

const Container = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 400px;
  max-height: 300px;
  ${m(4)};
  ${p(4)};
`;

export default () => (
  <Container>
    <h1>Error</h1>
    <p>An unexpected error has occurred.</p>
  </Container>
);
